import {Action, AlertSeverity, ButtonLink, ButtonLinkCancel, ButtonValidate, ContentBlock, ElementList, ElementListSize, FieldBlock, FlexContentDirection, Form, FormValidationType, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, TranslationLibFile} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Category} from "../../../api/model/audience/category/Category";
import {CategoryField} from "../../../api/model/audience/category/CategoryField";
import {CategoryType} from "../../../api/model/audience/category/CategoryType";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {FormLayoutMessage} from "../../../common/component/snippet";
import {sortItems} from "../../../common/utils/helper";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import useFormValidator from "../../../utils/hooks/useFormValidator";
import {SearchItems, TagCategoryRow} from "../../snippet";

type ModalPopulateCategoriesProps = {
    active: boolean;
    onClose: () => void;
};

const ModalPopulateCategories: FunctionComponent<ModalPopulateCategoriesProps> = ({active, onClose}) => {
    const alert = useAlert();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCategories} = useTranslation(TranslationPortalFile.CATEGORIES);
    const [isLoading, setLoading] = useState(true);
    const [isPopulating, setPopulating] = useState(false);
    const [categories, setCategories] = useState<Category[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
    const [highlightedCategories, setHighlightedCategories] = useState<Category[]>([]);
    const FORM_ID = "form-populate-categories";
    const {setErrors, setShowErrors, ...formValidator} = useFormValidator<string>();

    useEffect(() => {
        (async () => {
            try {
                let categories = await session.getCategoriesByType(CategoryType.MODELING);
                categories = sortItems(categories, CategoryField.NAME);
                setCategories(categories);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("modeling categories", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert]);

    useEffect(() => {
        if (active) {
            setSelectedCategories([]);
            setShowErrors(false);
        }
    }, [active, setShowErrors]);

    useEffect(() => {
        setErrors((prevState) => ({
            ...prevState,
            categories: !selectedCategories.length
        }));
    }, [setErrors, selectedCategories]);

    const handleAddCategories = (categories: Category[]) => {
        setSelectedCategories((prevState) => [...prevState, ...categories]);
        setHighlightedCategories(categories);
        setTimeout(() => setHighlightedCategories([]), 1000);
    };

    const handleRemoveCategory = (category: Category) => {
        setSelectedCategories((prevState) => prevState.filter((it) => it.id !== category.id));
    };

    const handleSubmitCapture = () => {
        setShowErrors(true);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (formValidator.hasErrors()) {
            return;
        }
        try {
            setPopulating(true);
            const categoryIds: number[] = selectedCategories.map((it) => it.id);
            await session.restModeling.populateCategories(...categoryIds);
            alert.actionWithSuccess(`${categoryIds.length} categories populated`);
            onClose();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("populate categories", e.message);
            }
        } finally {
            setPopulating(false);
        }
    };

    return (
        <ModalNew onClose={onClose} active={active} loading={isLoading}>
            <ModalHeader>
                <ModalHeaderTitle title={textCategories("modal.populate_categories.title")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmitCapture={handleSubmitCapture} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <ContentBlock>
                        <FieldBlock content={{direction: FlexContentDirection.COLUMN}}>
                            <SearchItems
                                items={categories}
                                selectedItems={selectedCategories}
                                searchField="fullName"
                                onSubmit={handleAddCategories}
                                loading={isLoading}
                            />
                            {formValidator.isError("categories") &&
                                <FormLayoutMessage message={t("message.error.element_required", {element: "category"})} severity={AlertSeverity.DANGER} small/>
                            }
                        </FieldBlock>
                        <FieldBlock
                            label={textCategories("modal.select.selected", {count: selectedCategories.length})}
                            actions={
                                <ButtonLink onClick={() => setSelectedCategories([])}>{textCommon(Action.REMOVE_ALL.labelKey)}</ButtonLink>
                            }
                        >
                            <ElementList placeholder={textCategories("modal.links.search_result")} size={ElementListSize.BIG}>
                                {selectedCategories.map((category) =>
                                    <TagCategoryRow
                                        key={category.id}
                                        category={category}
                                        isHighlighted={highlightedCategories.some(({id}) => category.id === id)}
                                        onRemove={() => handleRemoveCategory(category)}
                                    />
                                )}
                            </ElementList>
                        </FieldBlock>
                    </ContentBlock>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID} loading={isPopulating}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalPopulateCategories;
