import {Action, ContentBlock, ContentBlockAction, ElementList, ElementListSize, IconTooltip, Loadable, SearchBarClearable, TableColumn, TableRow, TagStyle} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../../api/ApiSession";
import {CategoryGroupLinkKeyword} from "../../../../api/model/audience/category/CategoryGroupLinkKeyword";
import {CategoryGroupLinkKeywordField} from "../../../../api/model/audience/category/CategoryGroupLinkKeywordField";
import {CategoryTaxonomy} from "../../../../api/model/audience/category/CategoryTaxonomy";
import {CategoryGroupKeywords} from "../../../../api/model/audience/category/keywords/CategoryGroupKeywords";
import {User} from "../../../../api/model/user/User";
import {ErrorResponse} from "../../../../common/api/http/ErrorResponse";
import {sortItems} from "../../../../common/utils/helper";
import {detectChanges} from "../../../../common/utils/portal";
import {TranslationPortalFile} from "../../../../utils/constants";
import useAlert from "../../../../utils/hooks/useAlert";
import ModalAddCategoryGroupLinksKeywords from "../../../modal/categories/ModalAddCategoryGroupLinksKeywords";
import {Tag, TagWithAction} from "../../index";

type CategoryGroupKeywordsRowExpandedProps = {
    categoryGroupKeywords: CategoryGroupKeywords;
    currentQuery: string;
    onChangeKeywordCount: (count: number) => void;
};

const CategoryGroupKeywordsRowExpanded: FunctionComponent<CategoryGroupKeywordsRowExpandedProps> = ({categoryGroupKeywords, currentQuery, onChangeKeywordCount}) => {
    const {t: textKeywords} = useTranslation(TranslationPortalFile.CATEGORY_KEYWORDS);
    const alert = useAlert();

    const [isLoading, setLoading] = useState(true);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);
    const [users, setUsers] = useState<User[]>([]);
    const [query, setQuery] = useState("");

    const [currentLinksKeywords, setCurrentLinksKeywords] = useState<CategoryGroupLinkKeyword[]>([]);
    const [keywordLinks, setKeywordLinks] = useState<CategoryGroupLinkKeyword[]>([]);
    const [initKeywordLinks, setInitKeywordLinks] = useState<CategoryGroupLinkKeyword[]>([]);
    const [isShowModalAddCategoryGroupLinksKeywords, setShowModalAddCategoryGroupLinksKeywords] = useState(false);

    const isEditable = categoryGroupKeywords.taxonomy === CategoryTaxonomy.SIRDATA.name;

    const LIMIT_KEYWORDS_SMALL = 20;
    const LIMIT_KEYWORDS_MEDIUM = 50;

    useEffect(() => {
        (async () => {
            try {
                setUsers(await session.getUsers());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("users", e.message);
                }
            }
        })();
    }, [alert]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const sortedKeywords = sortItems(categoryGroupKeywords.keywords, CategoryGroupLinkKeywordField.LAST_UPDATE, true);
            setKeywordLinks(sortedKeywords);
            setCurrentLinksKeywords([...sortedKeywords]);
            setInitKeywordLinks([...sortedKeywords]);
            setLoading(false);
        })();
    }, [categoryGroupKeywords.keywords]);

    useEffect(() => {
        setCurrentLinksKeywords(keywordLinks.filter((it) => it.keyword.match(query)));
    }, [keywordLinks, query]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(keywordLinks, initKeywordLinks));
    }, [keywordLinks, initKeywordLinks]);

    const getElementListSize = (): ElementListSize => {
        return keywordLinks.length < LIMIT_KEYWORDS_SMALL ? ElementListSize.SMALL : keywordLinks.length > LIMIT_KEYWORDS_MEDIUM ? ElementListSize.BIG : ElementListSize.MEDIUM;
    };

    const handleDeleteKeyword = (keyword: string) => {
        setKeywordLinks((prevState) => [...prevState.filter((k) => k.keyword !== keyword)]);
    };

    const handleSave = async () => {
        try {
            const deletedKeywords = initKeywordLinks.filter((k) => keywordLinks.findIndex((it) => it.keyword === k.keyword) === -1);
            if (deletedKeywords.length) {
                await session.restCategoryGroupLinkKeyword.deleteKeywords(deletedKeywords);
                alert.actionWithSuccess(textKeywords("message.keyword_deleted", {count: deletedKeywords.length}));
            }
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("keywords", e.message);
            }
        } finally {
            setInitKeywordLinks([...keywordLinks]);
            onChangeKeywordCount(keywordLinks.length);
        }
    };

    const getOwner = (userId?: number) => {
        const user = users.find((it) => it.id === userId);
        return user ? ` (${user.initials.toUpperCase()})` : "";
    };

    return (
        <TableRow>
            <TableColumn colSpan={7}>
                <ContentBlock
                    cssClass="keywords"
                    header={{
                        actions: [
                            <SearchBarClearable key="search-bar" value={query} onSubmit={(q) => setQuery(q)}/>,
                            (isEditable ?
                                <ContentBlockAction
                                    key="action-add"
                                    action={Action.ADD}
                                    onClick={() => setShowModalAddCategoryGroupLinksKeywords(true)}
                                /> :
                                <IconTooltip icon={{name: "info"}} text={textKeywords("message.cannot_add")}/>
                            ),
                            (isEditable &&
                                <ContentBlockAction
                                    key="action-save"
                                    action={Action.SAVE}
                                    isDisabled={!isUnsavedChanges}
                                    onClick={handleSave}
                                />
                            )
                        ]
                    }}
                >
                    <Loadable loading={isLoading}>
                        <ElementList inline size={getElementListSize()}>
                            {currentLinksKeywords.map((link) =>
                                isEditable ?
                                    <TagWithAction
                                        key={link.id}
                                        value={`${link.keyword}${getOwner(link.id_owner)}`}
                                        active={!!currentQuery && link.keyword.includes(currentQuery.toLowerCase())}
                                        onDelete={() => handleDeleteKeyword(link.keyword)}
                                    />
                                    : (
                                        <Tag
                                            key={link.id}
                                            label={link.keyword}
                                            style={(!!currentQuery && link.keyword.includes(currentQuery.toLowerCase())) ? TagStyle.PRIMARY_MIDNIGHT_LIGHT : TagStyle.DEFAULT_MIDNIGHT_LIGHT}
                                        />
                                    )
                            )}
                        </ElementList>
                    </Loadable>
                </ContentBlock>
            </TableColumn>
            <ModalAddCategoryGroupLinksKeywords
                active={isShowModalAddCategoryGroupLinksKeywords}
                categoryGroupId={categoryGroupKeywords.group_id}
                existingKeywords={currentLinksKeywords.map((it) => it.keyword)}
                onClose={(keywords) => {
                    setShowModalAddCategoryGroupLinksKeywords(false);
                    if (keywords) {
                        const newKeywordLinks = [...keywords, ...keywordLinks];
                        setKeywordLinks(newKeywordLinks);
                        setInitKeywordLinks([...newKeywordLinks]);
                        onChangeKeywordCount(newKeywordLinks.length);
                    }
                }}
            />
        </TableRow>
    );
};

export default CategoryGroupKeywordsRowExpanded;
