import {FormEvent, FunctionComponent, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Box,
    BoxRadius,
    ButtonLinkCancel,
    ButtonSave,
    ContentBlock,
    FieldBlock,
    FlexContentLayout,
    FlexContentSpacing,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutTitle,
    FormValidationType,
    InputDate,
    InputDateType,
    InputTextNumber,
    InputWithLabel,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    Paragraph,
    Select,
    ToggleSwitch
} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {Pricing} from "../../../api/model/partner/pricing/Pricing";
import {PartnerPricing} from "../../../api/model/partner/pricing/PartnerPricing";
import {session} from "../../../api/ApiSession";
import {PartnerPricingSearchQuery} from "../../../api/model/partner/pricing/PartnerPricingSearchQuery";
import {Formatter} from "../../../common/utils/Formatter";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {PartnerPricingField} from "../../../api/model/partner/pricing/PartnerPricingField";
import {detectChanges} from "../../../common/utils/portal";
import moment from "moment";
import {RestrictedContent} from "../../../common/component/widget";
import {Authorization} from "../../../api/model/account/Authorization";
import useAlert from "../../../utils/hooks/useAlert";
import {ApiService} from "../../../api/model/ApiService";

export type ModalPartnerPricingLinksProps = {
    partnerId?: number;
    onSubmit: () => void;
    onClose: () => void;
};

const ModalEditPartnerPricingLinks: FunctionComponent<ModalPartnerPricingLinksProps> = ({partnerId, onSubmit, onClose}) => {
    const alert = useAlert();
    const {t: textPartners} = useTranslation(TranslationPortalFile.PARTNERS);
    const {t: textPricings} = useTranslation(TranslationPortalFile.PRICINGS);
    const [isLoading, setLoading] = useState(true);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);

    const FORM_ID = "form-edit-partner-pricing-links";
    const [mapServices, setMapServices] = useState<Map<string, Pricing[]>>(new Map<string, Pricing[]>());
    const [mapPartnerPricings, setMapPartnerPricings] = useState<Map<string, PartnerPricing>>(new Map<string, PartnerPricing>());
    const [initMapPartnerPricings, setInitMapPartnerPricings] = useState<Map<string, PartnerPricing>>(new Map<string, PartnerPricing>());
    const [mapActivatedServices, setMapActivatedServices] = useState<Map<string, boolean>>(new Map<string, boolean>());

    const updateMapActivatedServices = useCallback((newMapActivatedServices: Map<string, boolean>) => {
        setMapActivatedServices(newMapActivatedServices);
    }, []);

    useEffect(() => {
        if (!partnerId) {
            setMapPartnerPricings(new Map<string, PartnerPricing>());
            setInitMapPartnerPricings(new Map<string, PartnerPricing>());
            updateMapActivatedServices(new Map<string, boolean>());
            return;
        }
        (async () => {
            try {
                setLoading(true);
                const pricings = await session.restPricing.list();
                const mapServices: Map<string, Pricing[]> = new Map<string, Pricing[]>();
                Pricing.apiServices().forEach((service) => {
                    mapServices.set(service.name, pricings.filter((it) => it.api_service === service.name));
                });
                setMapServices(mapServices);

                const searchQuery = new PartnerPricingSearchQuery();
                searchQuery.partner_id = partnerId;
                let partnerPricings = await session.restPartnerPricing.list(searchQuery);
                partnerPricings = partnerPricings.map((it) => {
                    const item = new PartnerPricing(it);
                    item.start_date = item.start_date ? Formatter.convertUTCToDate(item.start_date).format(Formatter.API_DATE_FORMAT) : "";
                    item.end_date = item.end_date ? Formatter.convertUTCToDate(item.end_date).format(Formatter.API_DATE_FORMAT) : "";
                    return item;
                });

                const mapPartnerPricings: Map<string, PartnerPricing> = new Map<string, PartnerPricing>();
                const newMapActivatedServices = new Map<string, boolean>();
                Pricing.apiServices().forEach((service) => {
                    const partnerPricing = partnerPricings.find((it) => it.pricing.api_service === service.name);
                    if (partnerPricing) {
                        mapPartnerPricings.set(service.name, partnerPricing);
                        newMapActivatedServices.set(service.name, true);
                    }
                });
                setMapPartnerPricings(mapPartnerPricings);
                setInitMapPartnerPricings(new Map(mapPartnerPricings));
                updateMapActivatedServices(newMapActivatedServices);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("pricings", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [partnerId, textPricings, alert, updateMapActivatedServices]);

    useEffect(() => {
        const activatedServiceNames = Pricing.apiServices()
            .filter((service) => Array.from(mapActivatedServices.keys()).some((activatedService) => activatedService === service.name))
            .map((service) => service.name);
        const initActivatedServiceNames = Array.from(initMapPartnerPricings.keys());

        setUnsavedChanges(detectChanges({o: mapPartnerPricings}, {o: initMapPartnerPricings}) ||
            detectChanges(activatedServiceNames, initActivatedServiceNames));
    }, [mapPartnerPricings, initMapPartnerPricings, mapActivatedServices]);

    const handleChangeActivation = (service: ApiService, active: boolean) => {
        if (active) {
            mapActivatedServices.set(service.name, true);
        } else {
            mapActivatedServices.delete(service.name);
        }
        updateMapActivatedServices(new Map(mapActivatedServices));
    };

    const handleChange = (service: ApiService, field: PartnerPricingField, value: any) => {
        const partnerPricing = new PartnerPricing({...mapPartnerPricings.get(service.name), [field]: value});
        mapPartnerPricings.set(service.name, partnerPricing);
        setMapPartnerPricings(new Map(mapPartnerPricings));
    };

    const isServiceActivated = (service: string) => {
        return !!mapActivatedServices.get(service) || false;
    };

    const doSavePartnerPricing = async (service: string, partnerPricing: PartnerPricing) => {
        if (!partnerId) return;
        if (!partnerPricing.id && !isServiceActivated(service)) return;

        partnerPricing.id_partner = partnerId;
        partnerPricing.start_date = partnerPricing.start_date ? Formatter.formatDate(partnerPricing.start_date, Formatter.API_DATE_FORMAT) : "";
        if (partnerPricing.end_date) {
            partnerPricing.end_date = Formatter.formatDate(partnerPricing.end_date, Formatter.API_DATE_FORMAT);
            if (!moment(partnerPricing.end_date).isAfter(moment(partnerPricing.start_date))) {
                alert.failToUpdate(`rate card settings for ${Pricing.getApiServiceByName(service)?.label}`, textPartners("message.pricing_settings.end_date_error"));
                return;
            }
        }

        try {
            if (partnerPricing.id) {
                if (!isServiceActivated(service) || !partnerPricing.id_pricing || !partnerPricing.start_date) {
                    await session.restPartnerPricing.delete(partnerPricing.id);
                } else {
                    const initPartnerPricing = initMapPartnerPricings.get(service) || new PartnerPricing(partnerPricing);
                    if (!detectChanges(partnerPricing, initPartnerPricing)) {
                        return;
                    }
                    await session.restPartnerPricing.create(partnerPricing);
                }
            } else {
                if (partnerPricing.id_pricing && partnerPricing.start_date) {
                    await session.restPartnerPricing.create(partnerPricing);
                }

            }
            alert.updateWithSuccess(`rate card setting for ${Pricing.getApiServiceByName(service)?.label}`);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate(`rate card setting for ${Pricing.getApiServiceByName(service)?.label}`, e.message);
            }
        }
    };

    const handleSave = async (e: FormEvent) => {
        e.preventDefault();
        const items = Array.from(mapPartnerPricings.entries());
        for (const [service, partnerPricing] of items) {
            await doSavePartnerPricing(service, partnerPricing);
        }
        onSubmit();
    };

    return (
        <ModalNew onClose={onClose} active={!!partnerId} loading={isLoading}>
            <ModalHeader>
                <ModalHeaderTitle title={textPartners("modal.pricing_settings.title")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSave} validationType={FormValidationType.CUSTOM}>
                    <ContentBlock>
                        <Paragraph>
                            {textPartners("modal.pricing_settings.description")}
                        </Paragraph>
                        {Pricing.apiServices()
                            .map((service) => ({service: service, partnerPricing: mapPartnerPricings.get(service.name) || new PartnerPricing()}))
                            .map(({service, partnerPricing}) =>
                                <Box key={service.name} radius={BoxRadius.MD}>
                                    <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_RIGHT}>
                                        <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                            <FormLayoutTitle>{service.label}</FormLayoutTitle>
                                            <ToggleSwitch
                                                checked={isServiceActivated(service.name)}
                                                onChange={(value) => handleChangeActivation(service, value)}
                                            />
                                        </FormLayoutRows>
                                        {isServiceActivated(service.name) &&
                                            <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                                <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                                                    <FieldBlock
                                                        label={textPricings(`field.partner.${PartnerPricingField.ID_PRICING}`)}
                                                        required={!!mapServices.get(service.name)?.length}
                                                    >
                                                        <Select
                                                            value={partnerPricing.id_pricing}
                                                            options={(mapServices.get(service.name) || []).map((it) => ({value: it.id, label: it.name}))}
                                                            onChange={(option) => handleChange(service, PartnerPricingField.ID_PRICING, option ? +option.value : 0)}
                                                            disabled={!isServiceActivated(service.name)}
                                                            clearable
                                                            small
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textPricings(`field.partner.${PartnerPricingField.DISCOUNT}`)}>
                                                        <InputWithLabel label="%">
                                                            <InputTextNumber
                                                                value={partnerPricing.discount}
                                                                min={0}
                                                                max={100}
                                                                onChange={(value) => handleChange(service, PartnerPricingField.DISCOUNT, value)}
                                                                disabled={!isServiceActivated(service.name)}
                                                                small
                                                            />
                                                        </InputWithLabel>
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FormLayoutColumns>
                                                    <FieldBlock label={textPricings(`field.partner.${PartnerPricingField.START_DATE}`)} required>
                                                        <InputDate
                                                            type={InputDateType.DATE}
                                                            value={partnerPricing.start_date}
                                                            onChange={(value) => handleChange(service, PartnerPricingField.START_DATE, value)}
                                                            disabled={!isServiceActivated(service.name)}
                                                            small
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textPricings(`field.partner.${PartnerPricingField.END_DATE}`)}>
                                                        <InputDate
                                                            type={InputDateType.DATE}
                                                            value={partnerPricing.end_date || ""}
                                                            onChange={(value) => handleChange(service, PartnerPricingField.END_DATE, value)}
                                                            disabled={!isServiceActivated(service.name)}
                                                            small
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                            </FormLayoutRows>
                                        }
                                    </FormLayoutColumns>
                                </Box>
                            )}
                    </ContentBlock>
                </Form>
            </ModalContent>
            <RestrictedContent allowedTo={Authorization.PRICINGS.update}>
                <ModalActions>
                    <ButtonLinkCancel onClick={onClose}/>
                    <ButtonSave form={FORM_ID} disabled={!isUnsavedChanges}/>
                </ModalActions>
            </RestrictedContent>
        </ModalNew>
    );
};

export default ModalEditPartnerPricingLinks;
