import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {CmpConfig} from "../model/cmp/config/CmpConfig";
import {CmpConfigSearchQuery} from "../model/cmp/config/CmpConfigSearchQuery";
import {CmpConfigSearchResult} from "../model/cmp/config/CmpConfigSearchResult";
import {CmpConfigUploadImageResponse} from "../model/cmp/config/CmpConfigUploadImageResponse";
import {CmpConfigImageSelection} from "../model/cmp/config/CmpConfigImageSelection";

export class RestCmpConfiguration extends Rest implements RestService<CmpConfig> {
    rootPath = "/console-api/cmp/config";

    search(searchQuery: CmpConfigSearchQuery): Promise<CmpConfigSearchResult> {
        return this._client.get(`${this.rootPath}/search`, CmpConfigSearchResult, {queryParams: searchQuery.getJsonParameters()}) as Promise<CmpConfigSearchResult>;
    }

    list(partnerId?: number): Promise<CmpConfig[]> {
        return this._client.get(`${this.rootPath}?partner_id=${partnerId}`, CmpConfig) as Promise<CmpConfig[]>;
    }

    create(cmpConfig: CmpConfig): Promise<CmpConfig> {
        return this._client.post(`${this.rootPath}?partner_id=${cmpConfig.id_partner}`, cmpConfig, CmpConfig) as Promise<CmpConfig>;
    }

    get(configId: string): Promise<CmpConfig> {
        return this._client.get(`${this.rootPath}/${configId}`, CmpConfig) as Promise<CmpConfig>;
    }

    update(cmpConfig: CmpConfig): Promise<CmpConfig> {
        return this._client.put(`${this.rootPath}/${cmpConfig.id}`, cmpConfig, CmpConfig) as Promise<CmpConfig>;
    }

    delete(configId: string): Promise<CmpConfig> {
        return this._client.delete(`${this.rootPath}/${configId}`) as Promise<CmpConfig>;
    }

    uploadImage(partnerId: number, file: File): Promise<CmpConfigUploadImageResponse> {
        const formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("partner_id", partnerId.toString());
        return this._client.post(`${this.rootPath}/image`, formData, CmpConfigUploadImageResponse) as Promise<CmpConfigUploadImageResponse>;
    }

    flush(configId: string): Promise<CmpConfig> {
        return this._client.post(`${this.rootPath}/${configId}/flush`, CmpConfig) as Promise<CmpConfig>;
    }

    getImageSelection(): Promise<CmpConfigImageSelection> {
        return this._client.get(`${this.rootPath}/theme`, CmpConfigImageSelection) as Promise<CmpConfigImageSelection>;
    }
}
