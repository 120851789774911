import {
    Alert,
    AlertSeverity,
    ButtonAdd,
    ButtonLinkCancel,
    ButtonSize,
    ButtonStyle,
    ButtonUpload,
    ButtonValidate,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContentDirection,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    FormValidationType,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    SelectMultiple,
    Slider,
    Textarea
} from "@sirdata/ui-lib";
import moment from "moment";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as XLSX from "xlsx";
import {session} from "../../../api/ApiSession";
import {CategoryGroup} from "../../../api/model/audience/category/CategoryGroup";
import {CategoryGroupLinkKeyword} from "../../../api/model/audience/category/CategoryGroupLinkKeyword";
import {CategoryGroupLinkKeywordField} from "../../../api/model/audience/category/CategoryGroupLinkKeywordField";
import {CategoryTaxonomy} from "../../../api/model/audience/category/CategoryTaxonomy";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {LoggedAccount} from "../../../common/api/interface/LoggedAccount";
import {Formatter} from "../../../common/utils/Formatter";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {TagWithAction} from "../../snippet";
import useFormValidator from "../../../utils/hooks/useFormValidator";
import {FormLayoutMessage} from "../../../common/component/snippet";

type ModalAddCategoryGroupsLinksKeywordsProps = {
    active: boolean;
    onClose: (refresh?: boolean) => void;
};

const ModalAddCategoryGroupsLinksKeywords: FunctionComponent<ModalAddCategoryGroupsLinksKeywordsProps> = ({active, onClose}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textKeywords} = useTranslation(TranslationPortalFile.CATEGORY_KEYWORDS);
    const alert = useAlert();
    const [isLoadingImport, setLoadingImport] = useState<boolean>(false);
    const [isLoadingCategoryGroups, setLoadingCategoryGroups] = useState<boolean>(false);
    const [user, setUser] = useState<LoggedAccount>();
    const [categoryGroups, setCategoryGroups] = useState<CategoryGroup[]>([]);
    const [keywordsAsString, setKeywordsAsString] = useState<string>("");
    const [currentCategoryGroupsIds, setCurrentCategoryGroupsIds] = useState<number[]>([]);
    const [currentKeywords, setCurrentKeywords] = useState<string[]>([]);
    const [threshold, setThreshold] = useState<number>(CategoryGroupLinkKeyword.DEFAULT_THRESHOLD);
    const [importResultCount, setImportResultCount] = useState({added: 0, duplicated: 0});
    const FORM_ID = "form-add-category-groups-links-keywords";
    const {setErrors, setShowErrors, ...formValidator} = useFormValidator<string>();

    useEffect(() => {
        (async () => {
            try {
                const result = await session.getCategoryGroups();
                setCategoryGroups(result.filter((it) => it.taxonomy === CategoryTaxonomy.SIRDATA.name));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failTo("category groups", e.message);
                }
            } finally {
                setLoadingCategoryGroups(false);
            }
            try {
                setUser(await session.getAccount());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("user", e.message);
                }
            }
        })();
    }, [alert]);

    useEffect(() => {
        if (!active) {
            setKeywordsAsString("");
            setCurrentKeywords([]);
            setCurrentCategoryGroupsIds([]);
            setShowErrors(false);
        }
    }, [active, setShowErrors]);

    useEffect(() => {
        setErrors((prevState) => ({
            ...prevState,
            categoryGroups: !currentCategoryGroupsIds.length,
            keywords: !currentKeywords.length
        }));
    }, [setErrors, currentCategoryGroupsIds, currentKeywords]);

    const handleImportFile = (file?: File) => {
        setLoadingImport(true);
        try {
            if (file) {
                const reader = new FileReader();
                reader.onload = (evt) => {
                    const fileResult = evt.target?.result;
                    const workBook = XLSX.read(fileResult, {type: "binary"});
                    const workSheetName = workBook.SheetNames[0];
                    const workSheet = workBook.Sheets[workSheetName];
                    const dataString = XLSX.utils.sheet_to_csv(workSheet);

                    const newKeywords = dataString.split(/\r\n|\n|,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/g).filter((it) => it.trim());
                    const newKeywordsFiltered = newKeywords.filter((keyword, index) => !currentKeywords.includes(keyword) && newKeywords.indexOf(keyword) === index);
                    setCurrentKeywords((prevState) => [...prevState, ...newKeywordsFiltered]);
                    if (newKeywordsFiltered.length) {
                        setImportResultCount({added: newKeywordsFiltered.length, duplicated: newKeywords.length - newKeywordsFiltered.length});
                        setTimeout(() => setImportResultCount({added: 0, duplicated: 0}), 5000);
                    }
                };
                reader.readAsBinaryString(file);
            }
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("import keywords", e.message);
            }
        } finally {
            setLoadingImport(false);
        }
    };

    const handleAddKeywords = () => {
        const newKeywords = keywordsAsString.split(",").map((it) => it.trim());
        const newKeywordsFiltered = newKeywords.filter((keyword, index) => !currentKeywords.includes(keyword) && newKeywords.indexOf(keyword) === index);
        setCurrentKeywords((prevState) => [...prevState, ...newKeywordsFiltered]);
        if (newKeywordsFiltered.length) {
            setImportResultCount({added: newKeywordsFiltered.length, duplicated: newKeywords.length - newKeywordsFiltered.length});
            setTimeout(() => setImportResultCount({added: 0, duplicated: 0}), 5000);
        }
        setKeywordsAsString("");
    };

    const handleSubmitCapture = () => {
        setShowErrors(true);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (formValidator.hasErrors()) {
            return;
        }
        for (let categoryGroupId of currentCategoryGroupsIds) {
            const newLinks = currentKeywords.map((keyword) => new CategoryGroupLinkKeyword({
                id_group_category: categoryGroupId,
                keyword: keyword,
                id_owner: user?.id,
                threshold: +threshold,
                last_update: moment().format(Formatter.API_DATETIME_FORMAT)
            }));
            try {
                const result = await session.restCategoryGroupLinkKeyword.saveKeywords(newLinks);
                alert.actionWithSuccess(`${result.length} keywords added to category '${categoryGroups.find((it) => it.id === categoryGroupId)?.name}'`);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToCreate(`keywords for category #${categoryGroupId}`, e.message);
                }
            }
        }
        onClose(true);
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textKeywords("modal.add_new_keywords")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmitCapture={handleSubmitCapture} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FieldBlock
                            label={textKeywords("field.select_categories")}
                            content={{direction: FlexContentDirection.COLUMN}}
                        >
                            <SelectMultiple
                                values={currentCategoryGroupsIds}
                                options={categoryGroups.map((categoryGroup) => ({label: `${categoryGroup.id} - ${categoryGroup.fullName}`, value: categoryGroup.id}))}
                                onChange={(options) => setCurrentCategoryGroupsIds(options.map((option) => +option.value))}
                                isLoading={isLoadingCategoryGroups}
                                isExpandable
                            />
                            {formValidator.isError("categoryGroups") &&
                                <FormLayoutMessage message={t("message.error.element_required", {element: "category"})} severity={AlertSeverity.DANGER} small/>
                            }
                        </FieldBlock>
                        <FieldBlock
                            label={textKeywords("field.enter_new_keywords")}
                            actions={
                                <ButtonAdd onClick={handleAddKeywords} disabled={!keywordsAsString.trim().length}/>
                            }
                        >
                            <Textarea
                                value={keywordsAsString}
                                rows={5}
                                onChange={(value) => setKeywordsAsString(value.replaceAll("\n", ","))}
                                placeholder={textKeywords("modal.placeholder_add_keywords")}
                            />
                        </FieldBlock>
                        <FormLayoutColumns>
                            <FieldBlock label={textKeywords("field.or_import_keywords")} content={{noFullWidth: true}}>
                                <ButtonUpload
                                    label={textKeywords(isLoadingImport ? "import.button_loading" : "import.button")}
                                    style={ButtonStyle.PRIMARY_MIDNIGHT}
                                    size={ButtonSize.SMALL}
                                    onChange={handleImportFile}
                                    disabled={isLoadingImport}
                                />
                            </FieldBlock>
                            <FieldBlock label={textKeywords(`field.${CategoryGroupLinkKeywordField.THRESHOLD}`)}>
                                <Slider
                                    value={threshold}
                                    onChange={(value) => setThreshold(value)}
                                    min={CategoryGroupLinkKeyword.MIN_THRESHOLD}
                                    max={CategoryGroupLinkKeyword.MAX_THRESHOLD}
                                    step={CategoryGroupLinkKeyword.THRESHOLD_STEP}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FormLayoutSeparator/>
                        {!!importResultCount.added &&
                            <Alert text={textKeywords("message.count_keywords_added", {count: importResultCount.added})} severity={AlertSeverity.SUCCESS} fullWidth/>
                        }
                        {!!importResultCount.duplicated &&
                            <Alert text={textKeywords("message.count_keywords_duplicated", {count: importResultCount.duplicated})} severity={AlertSeverity.WARNING} fullWidth/>
                        }
                        <FieldBlock
                            label={textKeywords("field.keywords_to_add")}
                            content={{direction: FlexContentDirection.COLUMN}}
                            required
                        >
                            <ElementList size={ElementListSize.MEDIUM} inline>
                                {currentKeywords.map((keyword) =>
                                    <TagWithAction
                                        active
                                        key={keyword}
                                        value={keyword}
                                        onDelete={() => setCurrentKeywords((prevState) => prevState.filter((it) => it !== keyword))}
                                    />
                                )}
                            </ElementList>
                            {formValidator.isError("keywords") &&
                                <FormLayoutMessage message={t("message.error.element_required", {element: "keyword"})} severity={AlertSeverity.DANGER} small/>
                            }
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate form={FORM_ID} loading={isLoadingImport}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalAddCategoryGroupsLinksKeywords;
