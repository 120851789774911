import {PAGE_SIZE} from "../../../../utils/constants";
import {SearchParams} from "../../../../utils/SearchParams";
import {SearchParamsField} from "../../../../utils/SearchParamsField";
import {SearchQuery} from "../../../interface/SearchQuery";

export enum CmpConfigSearchParamsField {
    CREATION_TYPE = "creation_type"
}

export class CmpConfigSearchQuery extends SearchQuery {
    size: number = PAGE_SIZE;
    partner_id: number | undefined;
    status: string | undefined;
    creation_type: string | undefined;

    withSearchParams(params: SearchParams): CmpConfigSearchQuery {
        let newQuery = super.withSearchParams(params).withDefaultSorting("creation_time", true) as CmpConfigSearchQuery;
        newQuery.partner_id = params.getNumber(SearchParamsField.PARTNER);
        newQuery.status = params.getString(SearchParamsField.STATUS);
        newQuery.creation_type = params.getString(CmpConfigSearchParamsField.CREATION_TYPE);
        return newQuery;
    }

    getJsonParameters(): {} {
        return {
            query: this.query,
            page: this.page,
            size: this.size,
            sort_by: this.sortBy,
            reverse_order: this.reverseOrder,
            partner_id: this.partner_id,
            status: this.status,
            creation_type: this.creation_type
        };
    }
}
