import {
    Action,
    ButtonLink,
    ButtonLinkCancel,
    ButtonValidate,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContentSpacing,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormValidationType,
    InputText,
    InputTextNumber,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    RadioButtons,
    Select,
    SelectAutocomplete,
    Slider,
    ToggleSwitch,
    TranslationLibFile
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, ReactElement, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Distribution} from "../../../api/model/audience/distribution/Distribution";
import {DistributionLicense} from "../../../api/model/audience/distribution/license/DistributionLicense";
import {DistributionLicenseCustomField} from "../../../api/model/audience/distribution/license/DistributionLicenseCustomField";
import {DistributionLicenseCustomFieldType} from "../../../api/model/audience/distribution/license/DistributionLicenseCustomFieldType";
import {DistributionLicenseField} from "../../../api/model/audience/distribution/license/DistributionLicenseField";
import {DistributionLicenseSegmentLinkType} from "../../../api/model/audience/distribution/license/DistributionLicenseSegmentLinkType";
import {Segment} from "../../../api/model/audience/segment/Segment";
import {SegmentDataType} from "../../../api/model/audience/segment/SegmentDataType";
import {SegmentField} from "../../../api/model/audience/segment/SegmentField";
import {Currency} from "../../../api/model/currency/Currency";
import {RevenueSeat} from "../../../api/model/revenue/seat/RevenueSeat";
import {RevenueSeatField} from "../../../api/model/revenue/seat/RevenueSeatField";
import {RevenueSeatSearchQuery} from "../../../api/model/revenue/seat/RevenueSeatSearchQuery";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {sortItems} from "../../../common/utils/helper";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {SearchItems, TagSegmentRow} from "../../snippet";

type ModalCreateDistributionLicenseProps = {
    active: boolean;
    distributions: Distribution[];
    onClose: (refresh: boolean) => void;
};

const ModalCreateDistributionLicense: FunctionComponent<ModalCreateDistributionLicenseProps> = ({active, distributions, onClose}) => {
    const alert = useAlert();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textDistributionsLicenses} = useTranslation(TranslationPortalFile.DISTRIBUTIONS_LICENSES);
    const {t: textDistributions} = useTranslation(TranslationPortalFile.DISTRIBUTIONS);
    const [isLoadingSeats, setLoadingSeats] = useState(false);
    const [isLoadingSegments, setLoadingSegments] = useState(false);
    const [license, setLicense] = useState<DistributionLicense>(new DistributionLicense());
    const [customFields, setCustomFields] = useState<DistributionLicenseCustomField[]>([]);
    const [seats, setSeats] = useState<RevenueSeat[]>([]);
    const [segments, setSegments] = useState<Segment[]>([]);
    const [selectedSegments, setSelectedSegments] = useState<Segment[]>([]);
    const [highlightedSegments, setHighlightedSegments] = useState<Segment[]>([]);
    const [selectedDistribution, setSelectedDistribution] = useState<Distribution>(new Distribution());
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-create-distribution-license";

    useEffect(() => {
        if (active) {
            setCustomFields([]);
            setLicense(new DistributionLicense());
            const distribution = distributions.find((it) => !!it.destination_name);
            if (distribution) {
                setSelectedDistribution(distribution);
            }

            (async () => {
                try {
                    setLoadingSegments(true);
                    let segments = await session.getSegments();
                    segments = sortItems(segments, SegmentField.NAME);
                    setSegments(segments);
                    setSelectedSegments([]);
                } catch (e) {
                    if (e instanceof ErrorResponse) {
                        alert.failToLoad("segments", e.message);
                    }
                } finally {
                    setLoadingSegments(false);
                }
            })();
        }
    }, [active, alert, distributions]);

    useEffect(() => {
        (async () => {
            if (selectedDistribution.platform_name) {
                try {
                    setLoadingSeats(true);
                    const params = new RevenueSeatSearchQuery();
                    params.size = 1000;
                    params.platform = selectedDistribution.platform_name;
                    const searchResult = await session.restSeat.search(params);
                    const seats = sortItems(searchResult.seats, RevenueSeatField.BUYER);
                    setSeats(seats);
                    handleChange(DistributionLicenseField.ID_SEAT, (!!seats.length ? seats[0].id : 0));
                } catch (e) {
                    if (e instanceof ErrorResponse) {
                        alert.failToLoad("seats", e.message);
                    }
                } finally {
                    setLoadingSeats(false);
                }
            }

            if (selectedDistribution.destination_name) {
                try {
                    const customFields = await session.restDistributionLicense.getCustomFields(selectedDistribution.destination_name);
                    setCustomFields([...customFields]);
                } catch (e) {
                    if (e instanceof ErrorResponse) {
                        alert.failToLoad("custom fields", e.message);
                    }
                }
            } else {
                setCustomFields([]);
            }
        })();
    }, [active, selectedDistribution, alert]);

    const handleChange = (field: DistributionLicenseField, value: any) => {
        setLicense((prevState) => new DistributionLicense({...prevState, [field]: value}));
    };

    const handleChangeCustomField = (field: string, value: any) => {
        handleChange(DistributionLicenseField.CUSTOM_FIELDS, {...license.custom_fields, [field]: value});
    };

    const handleAddSegments = (segments: Segment[]) => {
        setSelectedSegments((prevState) => [...prevState, ...segments]);
        setHighlightedSegments(segments);
        setTimeout(() => setHighlightedSegments([]), 1000);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            license.external_map_name = selectedDistribution.name;
            license.segments = selectedSegments.map((it) => it.id);
            await session.restDistributionLicense.create(selectedDistribution.destination_name!, license);
            alert.createWithSuccess("distribution license");
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("distribution license", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    const getComponentFromField = (field: DistributionLicenseCustomField): ReactElement => {
        let value = license[DistributionLicenseField.CUSTOM_FIELDS][field.name];
        if (field.from_seat && !value) {
            const seat = seats.find((seat) => seat.id === license.id_seat);
            if (seat) {
                value = seat[RevenueSeatField.CUSTOM_FIELDS][field.name];
            }
        }
        switch (field.type) {
            case DistributionLicenseCustomFieldType.BOOLEAN:
                return (
                    <FieldBlock name={`custom_field-${field.label}`} label={field.label} required>
                        <ToggleSwitch
                            checked={value}
                            onChange={(value) => handleChangeCustomField(field.name, value)}
                        />
                    </FieldBlock>
                );
            case DistributionLicenseCustomFieldType.NUMBER:
                return (
                    <FieldBlock label={field.label} required>
                        <InputTextNumber
                            value={value}
                            onChange={(value) => handleChangeCustomField(field.name, value)}
                        />
                    </FieldBlock>
                );
            case DistributionLicenseCustomFieldType.SELECT:
                if (!field.values) return <></>;
                return (
                    <FieldBlock label={field.label} required>
                        <Select
                            value={value}
                            onChange={(option) => handleChangeCustomField(field.name, option?.value)}
                            options={field.values.map((o) => ({label: o.replaceAll("_", " "), value: o}))}
                        />
                    </FieldBlock>
                );
            case DistributionLicenseCustomFieldType.TEXT:
            default:
                return (
                    <FieldBlock label={field.label} required>
                        <InputText
                            value={value}
                            onChange={(value) => handleChangeCustomField(field.name, value)}
                        />
                    </FieldBlock>
                );
        }
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textDistributionsLicenses("modal.create_license")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutColumns>
                            <FieldBlock label={textDistributions("field.distribution")} required>
                                <SelectAutocomplete
                                    value={selectedDistribution.name}
                                    options={distributions.filter((it) => !!it.destination_name).map((it) => ({value: it.name, label: it.label, distribution: it}))}
                                    onChange={(option) => setSelectedDistribution(option?.distribution)}
                                    disabled={!!license.id}
                                />
                            </FieldBlock>
                            <FieldBlock label={textDistributionsLicenses(`field.${DistributionLicenseField.SEAT}.label`)} required>
                                <SelectAutocomplete
                                    value={license.id_seat}
                                    options={seats.map((it) => ({value: it.id, label: `${it.seat_id} (${it.organization?.name || "unaffected"})`}))}
                                    onChange={(option) => handleChange(DistributionLicenseField.ID_SEAT, option?.value)}
                                    disabled={!!license.id}
                                    isLoading={isLoadingSeats}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FormLayoutColumns>
                            <FieldBlock label={textDistributionsLicenses(`field.${DistributionLicenseField.CURRENCY}.label`)}>
                                <Select
                                    value={license.currency}
                                    options={Currency.values().map((it) => ({value: it.currency, label: textDistributionsLicenses(`field.currency.${it.currency}`)}))}
                                    onChange={(option) => handleChange(DistributionLicenseField.CURRENCY, option?.value)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textDistributionsLicenses(`field.${DistributionLicenseField.PRICE_MEDIA_COST}`)}>
                                <InputTextNumber
                                    value={license.price_media_cost}
                                    onChange={(value) => handleChange(DistributionLicenseField.PRICE_MEDIA_COST, value)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FormLayoutColumns>
                            <FieldBlock label={textDistributionsLicenses(`field.${DistributionLicenseField.PRICE_CPM}`)}>
                                <InputTextNumber
                                    value={license.price_cpm}
                                    onChange={(value) => handleChange(DistributionLicenseField.PRICE_CPM, value)}
                                />
                            </FieldBlock>
                            <FieldBlock
                                label={textDistributionsLicenses(`field.${DistributionLicenseField.PRICE_CPM_MODIFIER}`)}
                                tooltip={textDistributionsLicenses(`modal.tooltip.${DistributionLicenseField.PRICE_CPM_MODIFIER}`)}
                            >
                                <Slider
                                    value={license.price_cpm_modifier ? license.price_cpm_modifier * 100 : 0}
                                    onChange={(value) => handleChange(DistributionLicenseField.PRICE_CPM_MODIFIER, value / 100)}
                                    min={0}
                                    max={100}
                                    unit="%"
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        {customFields.length > 0 &&
                            Array.from({length: Math.ceil(customFields.length / 2)}, (_, i) => i * 2).map((i) =>
                                <FormLayoutColumns key={i}>
                                    {getComponentFromField(customFields[i])}
                                    {customFields[i + 1] && getComponentFromField(customFields[i + 1])}
                                </FormLayoutColumns>
                            )
                        }
                        <FieldBlock label={textDistributionsLicenses(`field.${DistributionLicenseField.SEGMENT_LINK_TYPE}`)}>
                            <RadioButtons
                                id={DistributionLicenseField.SEGMENT_LINK_TYPE}
                                value={license.segment_link_type}
                                options={DistributionLicenseSegmentLinkType.values().map((it) => ({value: it.name, label: it.name}))}
                                onChange={(value) => {
                                    handleChange(DistributionLicenseField.SEGMENT_LINK_TYPE, value);
                                    if (value === DistributionLicenseSegmentLinkType.DATATYPE.name) {
                                        handleChange(DistributionLicenseField.SEGMENT_LINK_DATATYPE, SegmentDataType.INTENT.name);
                                    } else {
                                        handleChange(DistributionLicenseField.SEGMENT_LINK_DATATYPE, undefined);
                                    }
                                }}
                            />
                        </FieldBlock>
                        {license.segment_link_type === DistributionLicenseSegmentLinkType.DATATYPE.name &&
                            <FieldBlock
                                label={textDistributionsLicenses(`field.${DistributionLicenseField.SEGMENT_LINK_DATATYPE}`)}
                                required={license.segment_link_type === DistributionLicenseSegmentLinkType.DATATYPE.name}
                            >
                                <Select
                                    value={license.segment_link_datatype}
                                    options={SegmentDataType.values().map((it) => ({value: it.name, label: it.name}))}
                                    onChange={(option) => handleChange(DistributionLicenseField.SEGMENT_LINK_DATATYPE, option?.value)}
                                    disabled={license.segment_link_type !== DistributionLicenseSegmentLinkType.DATATYPE.name}
                                    menuPlacement={"top"}
                                />
                            </FieldBlock>
                        }
                        {license.segment_link_type === DistributionLicenseSegmentLinkType.LIST.name &&
                            <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                                <SearchItems
                                    items={segments}
                                    selectedItems={selectedSegments}
                                    searchField={SegmentField.NAME}
                                    onSubmit={handleAddSegments}
                                    loading={isLoadingSegments}
                                />
                                <FieldBlock
                                    label={textDistributionsLicenses("modal.selected", {count: selectedSegments.length})}
                                    actions={<ButtonLink onClick={() => setSelectedSegments([])}>{textCommon(Action.REMOVE_ALL.labelKey)}</ButtonLink>}
                                >
                                    <ElementList placeholder={textDistributionsLicenses("modal.search_result")} size={ElementListSize.BIG}>
                                        {selectedSegments.map((segment) =>
                                            <TagSegmentRow
                                                key={segment.id}
                                                segment={segment}
                                                isHighlighted={highlightedSegments.some(({id}) => segment.id === id)}
                                                onRemove={() => setSelectedSegments((prevState) => prevState.filter((it) => it.id !== segment.id))}
                                            />
                                        )}
                                    </ElementList>
                                </FieldBlock>
                            </FormLayoutRows>
                        }
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreateDistributionLicense;
