import {Action, ButtonLink, ButtonLinkCancel, ButtonValidate, ElementList, ElementListSize, FieldBlock, FlexContentSpacing, Form, FormLayoutRows, FormValidationType, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, TranslationLibFile} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Category} from "../../../api/model/audience/category/Category";
import {CategoryType} from "../../../api/model/audience/category/CategoryType";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {SearchItems, TagCategoryRow} from "../../snippet";

type ModalSelectCategoriesProps = {
    active: boolean;
    preSelectedCategories: Category[];
    type?: CategoryType;
    title?: string;
    onSubmit: (values: Category[]) => void;
    onClose: () => void;
};

const ModalSelectCategories: FunctionComponent<ModalSelectCategoriesProps> = ({active, preSelectedCategories, type, title, onSubmit, onClose}) => {
    const alert = useAlert();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCategories} = useTranslation(TranslationPortalFile.CATEGORIES);
    const [isLoading, setLoading] = useState(false);
    const [categories, setCategories] = useState<Category[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
    const [highlightedIds, setHighlightedIds] = useState<number[]>([]);
    const FORM_ID = "form-select-categories";

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                setCategories(await session.getCategoriesByType(type));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("categories", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [type, alert]);

    useEffect(() => {
        if (!active) return;
        setSelectedCategories([...preSelectedCategories]);
    }, [active, preSelectedCategories]);

    const handleAddCategories = (items: Category[]) => {
        setSelectedCategories((prevSelected) => ([...items, ...prevSelected]));
        setHighlightedIds(items.map((it) => it.id));
        setTimeout(() => {
            setHighlightedIds([]);
        }, 1000);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        onSubmit(selectedCategories);
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={title || textCategories("modal.select.title")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                        <SearchItems
                            items={categories}
                            selectedItems={selectedCategories}
                            searchField="fullName"
                            onSubmit={handleAddCategories}
                            loading={isLoading}
                        />
                        <FieldBlock
                            label={textCategories("modal.select.selected", {count: selectedCategories.length})}
                            actions={
                                <ButtonLink onClick={() => setSelectedCategories([])}>{textCommon(Action.REMOVE_ALL.labelKey)}</ButtonLink>
                            }
                        >
                            <ElementList placeholder={textCategories("modal.select.search_result")} size={ElementListSize.BIG}>
                                {selectedCategories.map((item) =>
                                    <TagCategoryRow
                                        key={item.id}
                                        category={item}
                                        isHighlighted={highlightedIds.some((id) => item.id === id)}
                                        onRemove={() => setSelectedCategories((prevState) => prevState.filter((it) => it.id !== item.id))}
                                    />
                                )}
                            </ElementList>
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalSelectCategories;
