import {FC, useCallback, useEffect, useState} from "react";
import {Action, ActionsMenu, ContentBlock, ContentBlockAction, Loadable, Table, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TagStyle, TranslationLibFile} from "@sirdata/ui-lib";
import {RestrictedContent} from "../../../common/component/widget";
import {Authorization} from "../../../api/model/account/Authorization";
import {PartnerAccountField} from "../../../api/model/partner/PartnerAccountField";
import {PartnerAccountPropertiesField} from "../../../api/model/partner/PartnerAccountPropertiesField";
import {LabelStatus, Tag, ValueCopier} from "../index";
import {PartnerAccount} from "../../../api/model/partner/PartnerAccount";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import ModalCreatePartnerAccount from "../../modal/partners/ModalCreatePartnerAccount";
import ModalEditPartnerAccount from "../../modal/partners/ModalEditPartnerAccount";

type PartnerLinkedAccountsProps = {
    partnerId: number;
}

const PartnerLinkedAccounts: FC<PartnerLinkedAccountsProps> = ({partnerId}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textPartners} = useTranslation(TranslationPortalFile.PARTNERS);
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [isShowModalCreatePartnerAccount, setShowModalCreatePartnerAccount] = useState(false);
    const [accounts, setAccounts] = useState<PartnerAccount[]>([]);
    const [activeEditPartnerAccount, setActiveEditPartnerAccount] = useState<PartnerAccount>();

    const loadPartnerAccounts = useCallback(async () => {
        if (!partnerId) return;
        try {
            setLoading(true);
            const partnerAccounts = await session.restPartnerAccount.list(partnerId);
            setAccounts(partnerAccounts);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("partner accounts", e.message);
            }
        } finally {
            setLoading(false);
        }
    }, [alert, partnerId]);

    useEffect(() => {
        (async () => {
            await loadPartnerAccounts();
        })();
    }, [loadPartnerAccounts]);

    const handleActivateAccount = async (account: PartnerAccount) => {
        try {
            await session.restPartnerAccount.activate(account.id);
            await loadPartnerAccounts();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("activate partner account", e.message);
            }
        }
    };

    return (
        <Loadable loading={isLoading}>
            <ContentBlock
                header={{
                    title: {label: textPartners("section.linked_accounts")},
                    actions: (
                        <RestrictedContent allowedTo={Authorization.PARTNERS.update}>
                            <ContentBlockAction action={Action.ADD} onClick={() => setShowModalCreatePartnerAccount(true)}/>
                        </RestrictedContent>
                    )
                }}
            >
                <Table
                    columns={[
                        {width: 5, label: textPartners("field.partner_account.status"), styles: TableColumnStyle.ALIGN_CENTER},
                        {width: 5, label: textPartners(`field.partner_account.${PartnerAccountField.ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                        {width: 10, label: textPartners(`field.partner_account.${PartnerAccountField.TYPE}`), styles: TableColumnStyle.ALIGN_CENTER},
                        {width: 15, label: textPartners(`field.partner_account.${PartnerAccountField.EMAIL}`)},
                        {width: 20, label: textPartners("field.partner_account.name")},
                        {width: 15, label: textPartners(`field.partner_account.${PartnerAccountField.PHONE}`)},
                        {width: 15, label: textPartners(`field.partner_account.${PartnerAccountField.POSTIION}`)},
                        {width: 10, label: textPartners(`field.partner_account.${PartnerAccountField.PROPERTIES}.${PartnerAccountPropertiesField.FRESHSALES_ID}`)},
                        {width: 5}
                    ]}
                >
                    {accounts.map((account) =>
                        <TableRow key={account.id}>
                            <TableColumn>
                                <LabelStatus status={account.getStatus()}/>
                            </TableColumn>
                            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{account.id}</TableColumn>
                            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                                <Tag label={account.type} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
                            </TableColumn>
                            <TableColumn>{account.email}</TableColumn>
                            <TableColumn>{account.fullName}</TableColumn>
                            <TableColumn>{account.phone}</TableColumn>
                            <TableColumn>{account.position}</TableColumn>
                            <TableActionColumn styles={TableColumnStyle.ALIGN_CENTER}>
                                {account.properties.freshsales_id &&
                                <ValueCopier value={account.properties.freshsales_id} tooltip={textPartners("actions.copy_id")}/>
                                }
                            </TableActionColumn>
                            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                                <RestrictedContent allowedTo={Authorization.PARTNERS.update}>
                                    <ActionsMenu
                                        iconTooltip={{icon: Action.EDIT.icon, text: textCommon(Action.EDIT.labelKey)}}
                                        items={account.active ?
                                            ([{label: textCommon(Action.EDIT.labelKey), onClick: () => setActiveEditPartnerAccount(account)}]) :
                                            ([
                                                {label: textCommon(Action.ACTIVATE.labelKey), onClick: () => handleActivateAccount(account)},
                                                {label: textCommon(Action.EDIT.labelKey), onClick: () => setActiveEditPartnerAccount(account)}
                                            ])
                                        }
                                    />
                                </RestrictedContent>
                            </TableActionColumn>
                        </TableRow>
                    )}
                </Table>
                <ModalCreatePartnerAccount
                    partnerId={partnerId}
                    active={isShowModalCreatePartnerAccount}
                    onClose={(refresh) => {
                        setShowModalCreatePartnerAccount(false);
                        refresh && loadPartnerAccounts();
                    }}
                />
                <ModalEditPartnerAccount
                    initAccount={activeEditPartnerAccount}
                    onClose={(refresh) => {
                        setActiveEditPartnerAccount(undefined);
                        refresh && loadPartnerAccounts();
                    }}
                />
            </ContentBlock>
        </Loadable>
    );
};

export default PartnerLinkedAccounts;
