import {Action, ContentBlock, LayoutRows, Loadable, SearchError, SearchField, SearchToolbar, Select, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {ApiService} from "../../api/model/ApiService";
import {DataSelection} from "../../api/model/audience/data-selection/DataSelection";
import {DataSelectionContentField} from "../../api/model/audience/data-selection/DataSelectionContentField";
import {DataSelectionSearchParamsField, DataSelectionSearchQuery} from "../../api/model/audience/data-selection/DataSelectionSearchQuery";
import {DATA_SELECTION_THEMES} from "../../api/model/audience/data-selection/DataSelectionTheme";
import {PortalContentField} from "../../api/model/portal-content/PortalContentField";
import {PORTAL_CONTENT_STATUSES} from "../../api/model/portal-content/PortalContentStatus";
import {PortalSetting} from "../../api/model/portal-setting/PortalSetting";
import {PortalSettingSearchQuery} from "../../api/model/portal-setting/PortalSettingSearchQuery";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import ModalCreateDataSelection from "../../component/modal/audience-data-selections/ModalCreateDataSelection";
import ModalPinDataSelection from "../../component/modal/audience-data-selections/ModalPinDataSelection";
import {DataSelectionRow, MainContentHeader, MainContentHeaderAction, SelectStatus} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import useSearch from "../../utils/hooks/useSearch";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";
import {Status} from "../../utils/Status";

function AudienceDataSelections() {
    const {t: textPortalContent} = useTranslation(TranslationPortalFile.PORTAL_CONTENT);
    const {t: textAudienceDataSelections} = useTranslation(TranslationPortalFile.AUDIENCE_DATA_SELECTIONS);
    const [isLoading, setLoading] = useState(true);
    const [isShowModalCreateDataSelection, setShowModalCreateDataSelection] = useState(false);
    const [isShowModalPinDataSelection, setShowModalPinDataSelection] = useState(false);
    const {setSearchResult, ...search} = useSearch(DataSelection, DataSelectionSearchQuery);
    const [audiencePortalSetting, setAudiencePortalSetting] = useState<PortalSetting>(new PortalSetting());
    const alert = useAlert();

    useEffect(() => {
        (async function () {
            try {
                const newSearchResult = await session.restPortalContent.search(DataSelection, search.searchQuery);
                setSearchResult(newSearchResult);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("data selections", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [search.searchQuery, setSearchResult, alert]);

    useEffect(() => {
        (async () => {
            try {
                const searchResult = await session.restPortalContent.search(PortalSetting, new PortalSettingSearchQuery().forApiService(ApiService.AUDIENCE));
                if (searchResult.elements.length) {
                    setAudiencePortalSetting(searchResult.elements[0]);
                }
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("portal setting for Sirdata Audience", e.message);
                }
            }
        })();
    }, [alert]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.AUDIENCE_DATA_SELECTIONS}>
                <RestrictedContent allowedTo={Authorization.AUDIENCE_DATA_SELECTIONS.update}>
                    <MainContentHeaderAction action={new Action(textAudienceDataSelections("pin_data_selection"), {name: "keep"})} onClick={() => setShowModalPinDataSelection(true)}/>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreateDataSelection(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textAudienceDataSelections("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}
                            canHideFilters={true}
                        >
                            <SearchField label={textPortalContent(`field.${PortalContentField.STATUS}`)}>
                                <SelectStatus
                                    value={search.searchQuery.status}
                                    statuses={PORTAL_CONTENT_STATUSES.filter((it) => it !== Status.DELETED)}
                                    onChange={(status) => search.changeParam(SearchParamsField.STATUS, status?.name)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textAudienceDataSelections(`field.${DataSelectionContentField.THEME}`)}>
                                <Select
                                    value={search.searchQuery.getSearchIndex(DataSelectionContentField.THEME)}
                                    options={DATA_SELECTION_THEMES.map((it) => ({label: textAudienceDataSelections(`theme.${it}`), value: it}))}
                                    onChange={(option) => search.changeParam(DataSelectionSearchParamsField.THEME, option?.value)}
                                    clearable
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 5, label: textPortalContent(`field.${PortalContentField.STATUS}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textPortalContent(`field.${PortalContentField.ID}`), styles: TableColumnStyle.ALIGN_CENTER, sort: {field: PortalContentField.ID}},
                                        {width: 15, label: textAudienceDataSelections(`field.${DataSelectionContentField.THEME}`), sort: {field: PortalContentField.SEARCH_INDEX}},
                                        {width: 30, label: textPortalContent(`field.${PortalContentField.TITLE}`), sort: {field: PortalContentField.TITLE}},
                                        {width: 40, label: textAudienceDataSelections(`field.${DataSelectionContentField.TAGS}`)}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((item) => (
                                        <DataSelectionRow
                                            key={item.id}
                                            item={item}
                                            pinned={item.id === audiencePortalSetting.content.properties.data_selection_id}
                                        />
                                    ))}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreateDataSelection
                    active={isShowModalCreateDataSelection}
                    onClose={() => setShowModalCreateDataSelection(false)}
                />
                <ModalPinDataSelection
                    active={isShowModalPinDataSelection}
                    onClose={() => setShowModalPinDataSelection(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default AudienceDataSelections;
