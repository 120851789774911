import {
    Action,
    ButtonLink,
    ButtonLinkCancel,
    ButtonValidate,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContentSpacing,
    Form,
    FormLayoutRows,
    FormValidationType,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    TranslationLibFile
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {CategoryGroup} from "../../../api/model/audience/category/CategoryGroup";
import {CategoryTaxonomy} from "../../../api/model/audience/category/CategoryTaxonomy";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {SearchItems, TagCategoryGroupRow} from "../../snippet";

type ModalEditCategoryGroupLinkedCategoryGroupsProps = {
    active: boolean;
    taxonomy: CategoryTaxonomy;
    currentLinks: CategoryGroup[];
    onSubmit: (categoryGroups: CategoryGroup[]) => void;
    onClose: () => void;
};

const ModalEditCategoryGroupLinkedCategoryGroups: FunctionComponent<ModalEditCategoryGroupLinkedCategoryGroupsProps> = ({active, taxonomy, currentLinks, onSubmit, onClose}) => {
    const alert = useAlert();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCategories} = useTranslation(TranslationPortalFile.CATEGORIES);
    const [isLoading, setLoading] = useState(true);
    const [categoryGroups, setCategoryGroups] = useState<CategoryGroup[]>([]);
    const [selectedCategoryGroups, setSelectedCategoryGroups] = useState<CategoryGroup[]>([]);
    const [highlightedCategoryGroups, setHighlightedCategoryGroups] = useState<CategoryGroup[]>([]);
    const FORM_ID = "form-edit-category-group-linked-category-groups";

    useEffect(() => {
        if (active) {
            setSelectedCategoryGroups(currentLinks);

            (async () => {
                try {
                    setLoading(true);
                    let newCategoryGroups = await session.getCategoryGroups();
                    newCategoryGroups = newCategoryGroups.filter((it) => !taxonomy || it.taxonomy === taxonomy.name);
                    setCategoryGroups(newCategoryGroups);
                } catch (e) {
                    if (e instanceof ErrorResponse) {
                        alert.failToLoad("category groups", e.message);
                    }
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [active, alert, currentLinks, taxonomy]);

    const handleAddCategoryGroups = (categoryGroups: CategoryGroup[]) => {
        setSelectedCategoryGroups((prevState) => [...prevState, ...categoryGroups]);
        setHighlightedCategoryGroups(categoryGroups);
        setTimeout(() => setHighlightedCategoryGroups([]), 1000);
    };

    const handleRemoveCategoryGroup = (group: CategoryGroup) => {
        setSelectedCategoryGroups((prevState) => prevState.filter((it) => it.id !== group.id));
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit(selectedCategoryGroups);
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textCategories("modal.links.title")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                        <SearchItems
                            items={categoryGroups}
                            selectedItems={selectedCategoryGroups}
                            searchField="fullName"
                            onSubmit={handleAddCategoryGroups}
                            loading={isLoading}
                        />
                        <FieldBlock
                            label={textCategories("modal.links.selected", {count: selectedCategoryGroups.length})}
                            actions={
                                <ButtonLink onClick={() => setSelectedCategoryGroups([])}>{textCommon(Action.REMOVE_ALL.labelKey)}</ButtonLink>
                            }
                        >
                            <ElementList placeholder={textCategories("modal.links.search_result")} size={ElementListSize.BIG}>
                                {selectedCategoryGroups.map((categoryGroup) =>
                                    <TagCategoryGroupRow
                                        key={categoryGroup.id}
                                        categoryGroup={categoryGroup}
                                        isHighlighted={highlightedCategoryGroups.some(({id}) => categoryGroup.id === id)}
                                        onRemove={() => handleRemoveCategoryGroup(categoryGroup)}
                                    />
                                )}
                            </ElementList>
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalEditCategoryGroupLinkedCategoryGroups;
