import {Action, AlertSeverity, Button, ButtonSize, ButtonStyle, FieldBlock, FlexContentDirection, Form, FormLayoutButtons, FormLayoutColumns, FormLayoutRows, FormValidationType, InputTextNumber, Loader, ModalActions, ModalContent, ModalDescription, ModalDescriptionAlignment, ModalHeader, ModalHeaderTitle, ModalNew, Select, SelectAutocomplete, TranslationLibFile, UploadPanel} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Authorization} from "../../../api/model/account/Authorization";
import {Category} from "../../../api/model/audience/category/Category";
import {CategoryField} from "../../../api/model/audience/category/CategoryField";
import {CategoryType} from "../../../api/model/audience/category/CategoryType";
import {CategoryPanel} from "../../../api/model/audience/category/panel/CategoryPanel";
import {CategoryPanelField} from "../../../api/model/audience/category/panel/CategoryPanelField";
import {CategoryPanelType} from "../../../api/model/audience/category/panel/CategoryPanelType";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {FormLayoutMessage} from "../../../common/component/snippet";
import {sortItems} from "../../../common/utils/helper";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import useFormValidator from "../../../utils/hooks/useFormValidator";

type ModalImportCategoryPanelProps = {
    active: boolean;
    onClose: () => void;
    category?: Category;
};

enum CategoryPanelImportStep {
    CONFIGURE_IMPORT,
    IMPORT_DONE,
}

const ModalImportCategoryPanel: FunctionComponent<ModalImportCategoryPanelProps> = ({active, onClose, category}) => {
    const alert = useAlert();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCategoryPanels} = useTranslation(TranslationPortalFile.CATEGORY_PANELS);
    const [isLoading, setLoading] = useState(true);
    const [isLoadingImport, setLoadingImport] = useState(false);
    const [isLoadingPopulate, setLoadingPopulate] = useState(false);
    const [isShowModalPopulate, setShowModalPopulate] = useState(false);

    const [activeStep, setActiveStep] = useState<CategoryPanelImportStep>(CategoryPanelImportStep.CONFIGURE_IMPORT);
    const [categories, setCategories] = useState<Category[]>([]);
    const [categoryPanel, setCategoryPanel] = useState<CategoryPanel>(new CategoryPanel());
    const [fileName, setFileName] = useState<string>("");
    const FORM_ID = "form-import-category-panel";
    const {setErrors, setShowErrors, ...formValidator} = useFormValidator<CategoryPanelField>();

    const PARSING_HEADER_ERROR = "parse error on line 1";

    useEffect(() => {
        (async () => {
            try {
                if (!category) {
                    let categories = await session.getCategoriesByType(CategoryType.MODELING);
                    categories = sortItems(categories, CategoryField.NAME);
                    setCategories(categories);
                }
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("modeling categories", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [category, alert]);

    useEffect(() => {
        const newCategoryPanel = new CategoryPanel();
        if (category) {
            newCategoryPanel.category_id = category.id;
            newCategoryPanel.population = category.population || 0;
        }
        setCategoryPanel(newCategoryPanel);
        if (!active) {
            setFileName("");
            setActiveStep(CategoryPanelImportStep.CONFIGURE_IMPORT);
            setShowErrors(false);
        }
    }, [active, category, setShowErrors]);

    useEffect(() => {
        (async () => {
            try {
                if (activeStep !== CategoryPanelImportStep.IMPORT_DONE) return;
                const account = await session.getAccount();
                setShowModalPopulate(account.hasAuthorization(Authorization.MODELING_ROLLBACK.name));
            } catch (e) {
            }
        })();
    }, [activeStep]);

    useEffect(() => {
        setErrors((prevState) => ({
            ...prevState,
            [CategoryPanelField.FILE]: !categoryPanel.file
        }));
    }, [setErrors, categoryPanel.file]);

    const handleImport = async (e: FormEvent) => {
        e.preventDefault();
        if (formValidator.hasErrors()) {
            return;
        }
        setLoadingImport(true);

        try {
            await session.restCategory.updatePopulation(categoryPanel.category_id, categoryPanel.population!);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("category's population", e.message);
                setLoadingImport(false);
                return;
            }
        }

        try {
            await session.restModeling.importPanel(categoryPanel);
            alert.actionWithSuccess("panel imported");
            setActiveStep(CategoryPanelImportStep.IMPORT_DONE);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                if (e.message.includes(PARSING_HEADER_ERROR)) {
                    alert.failTo("import panel", textCategoryPanels("message.malformed_header"));
                } else {
                    alert.failTo("import panel", e.message);
                }
                onClose();
            }
        } finally {
            setLoadingImport(false);
        }
    };

    const handlePopulate = async () => {
        setLoadingPopulate(true);

        try {
            await session.restModeling.populateCategories(categoryPanel.category_id);
            alert.actionWithSuccess("category populated");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("populate category", e.message);
            }
        } finally {
            setLoadingPopulate(false);
            setShowModalPopulate(false);
        }
    };

    const handleRemoveFile = () => {
        setCategoryPanel((prevState) => new CategoryPanel({...prevState, file: undefined, contentType: ""}));
        setFileName("");
    };

    const handleImportFile = (file?: File) => {
        if (!file) return;
        setFileName(file.name);

        const newCategoryPanel = new CategoryPanel({...categoryPanel, contentType: file.type});
        const reader = new FileReader();
        reader.onload = ({target}) => {
            if (!!target?.result && target?.result instanceof ArrayBuffer) {
                newCategoryPanel.file = target.result;
                setCategoryPanel(newCategoryPanel);
            }
        };
        reader.readAsArrayBuffer(file);
    };

    const handleSubmitCapture = () => {
        setShowErrors(true);
    };

    const handleChange = (field: CategoryPanelField, value: any) => {
        setCategoryPanel((prevState) => new CategoryPanel({...prevState, [field]: value}));
    };

    return (
        <>
            {activeStep === CategoryPanelImportStep.CONFIGURE_IMPORT &&
                <ModalNew onClose={onClose} active={active} loading={isLoading}>
                    <ModalHeader>
                        <ModalHeaderTitle title={textCategoryPanels("title")}/>
                    </ModalHeader>
                    <ModalContent>
                        <Form id={FORM_ID} onSubmitCapture={handleSubmitCapture} onSubmit={handleImport} validationType={FormValidationType.CUSTOM}>
                            <FormLayoutRows>
                                {!category &&
                                    <FieldBlock label={textCategoryPanels(`field.${CategoryPanelField.CATEGORY_ID}`)} required>
                                        <SelectAutocomplete
                                            value={categoryPanel.category_id}
                                            options={categories.map((category) => ({value: category.id, label: `${category.id} - ${category.name}`}))}
                                            onChange={(option) => handleChange(CategoryPanelField.CATEGORY_ID, option?.value)}
                                        />
                                    </FieldBlock>
                                }
                                <FormLayoutColumns>
                                    <FieldBlock label={textCategoryPanels(`field.${CategoryPanelField.TYPE}`)} required>
                                        <Select
                                            value={categoryPanel.type}
                                            options={CategoryPanelType.values().map((it) => ({label: textCategoryPanels(`panel_name.${it.name}`), value: it.name}))}
                                            onChange={(option) => handleChange(CategoryPanelField.TYPE, option?.value)}
                                        />
                                    </FieldBlock>
                                    <FieldBlock label={textCategoryPanels(`field.${CategoryPanelField.POPULATION}`)} required>
                                        <InputTextNumber
                                            value={categoryPanel.population}
                                            min={0}
                                            onChange={(value) => handleChange(CategoryPanelField.POPULATION, value)}
                                        />
                                    </FieldBlock>
                                </FormLayoutColumns>
                                <FieldBlock
                                    label={textCategoryPanels(`field.${CategoryPanelField.FILE}`)}
                                    content={{direction: FlexContentDirection.COLUMN}}
                                    required
                                >
                                    <UploadPanel
                                        fileName={fileName}
                                        placeholder={textCategoryPanels(`placeholder.${CategoryPanelField.FILE}`)}
                                        onChange={handleImportFile}
                                        onRemove={handleRemoveFile}
                                        acceptType={[".csv", ".xlsx", ".xls"]}
                                    />
                                    {formValidator.isError(CategoryPanelField.FILE) &&
                                        <FormLayoutMessage message={t("message.error.file_required")} severity={AlertSeverity.DANGER} small/>
                                    }
                                </FieldBlock>
                            </FormLayoutRows>
                        </Form>
                    </ModalContent>
                    <ModalActions>
                        <Button
                            form={FORM_ID}
                            size={ButtonSize.BIG}
                            style={ButtonStyle.PRIMARY_GREEN}
                            icon={Action.IMPORT.icon}
                            loading={isLoadingImport}
                        >
                            {textCommon(Action.IMPORT.labelKey)}
                        </Button>
                    </ModalActions>
                </ModalNew>
            }
            {activeStep === CategoryPanelImportStep.IMPORT_DONE &&
                <ModalNew active={isShowModalPopulate}>
                    <ModalContent>
                        {isLoadingPopulate ?
                            <FormLayoutRows>
                                <Loader/>
                                <ModalDescription alignment={ModalDescriptionAlignment.CENTER}>
                                    <span dangerouslySetInnerHTML={{__html: textCategoryPanels("message.populate_in_progress")}}/>
                                </ModalDescription>
                            </FormLayoutRows> :
                            <FormLayoutRows>
                                <ModalDescription alignment={ModalDescriptionAlignment.CENTER}>
                                    <span dangerouslySetInnerHTML={{__html: textCategoryPanels("message.populate_category")}}/>
                                </ModalDescription>
                                <FormLayoutButtons>
                                    <Button onClick={() => setShowModalPopulate(false)} size={ButtonSize.BIG} style={ButtonStyle.DEFAULT_MIDNIGHT}>
                                        {textCategoryPanels("action.do_not_populate")}
                                    </Button>
                                    <Button onClick={handlePopulate} size={ButtonSize.BIG} style={ButtonStyle.PRIMARY_MIDNIGHT}>{textCategoryPanels("action.populate_category")}</Button>
                                </FormLayoutButtons>
                            </FormLayoutRows>
                        }
                    </ModalContent>
                </ModalNew>
            }
        </>
    );
};

export default ModalImportCategoryPanel;
