import {Action, AlertSeverity, ButtonLink, ButtonLinkCancel, ButtonValidate, ElementList, ElementListSize, FieldBlock, FlexContentDirection, FlexContentSpacing, Form, FormLayoutRows, FormValidationType, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, TranslationLibFile} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Category} from "../../../api/model/audience/category/Category";
import {CategoryField} from "../../../api/model/audience/category/CategoryField";
import {CategoryType} from "../../../api/model/audience/category/CategoryType";
import {SegmentDataType} from "../../../api/model/audience/segment/SegmentDataType";
import {SegmentLinkCategory} from "../../../api/model/audience/segment/SegmentLinkCategory";
import {SegmentLinkCategoryField} from "../../../api/model/audience/segment/SegmentLinkCategoryField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {FormLayoutMessage} from "../../../common/component/snippet";
import {sortItems} from "../../../common/utils/helper";
import {SegmentTypeDefaultValue} from "../../../utils/audience/SegmentTypeDefaultValue";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import useFormValidator from "../../../utils/hooks/useFormValidator";
import {SearchItems, TagCategoryRow} from "../../snippet";

export type ModalEditSegmentLinkCategoryProps = {
    active: boolean;
    currentLinks: SegmentLinkCategory[];
    segmentId: number;
    segmentType?: SegmentDataType;
    percentile?: string;
    onClose: (refresh: boolean) => void;
};

const ModalAddSegmentLinksCategory: FunctionComponent<ModalEditSegmentLinkCategoryProps> = ({active, currentLinks, segmentId, segmentType, percentile, onClose}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textSegments} = useTranslation(TranslationPortalFile.SEGMENTS);
    const alert = useAlert();
    const [isLoading, setLoading] = useState(false);
    const [categories, setCategories] = useState<Category[]>([]);
    const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-add-segment-links-category";
    const {setErrors, setShowErrors, ...formValidator} = useFormValidator<string>();

    useEffect(() => {
        if (active) {
            (async () => {
                try {
                    setLoading(true);
                    const categories = await session.getCategories();
                    setCategories(categories);
                } catch (e) {
                    if (e instanceof ErrorResponse) {
                        alert.failToLoad("categories", e.message);
                    }
                } finally {
                    setLoading(false);
                }
            })();
            setSelectedCategories([]);
            setShowErrors(false);
        }
    }, [active, alert, setShowErrors]);

    useEffect(() => {
        setErrors((prevState) => ({
            ...prevState,
            categories: !selectedCategories.length
        }));
    }, [setErrors, selectedCategories]);

    useEffect(() => {
        if (!segmentType) return;
        const linkedCategoryIds = currentLinks.map((it) => it.category_id);

        let filteredCategories = categories.filter((category) => {
            if (linkedCategoryIds.includes(category.id)) return false;

            if (segmentType.name === SegmentDataType.INFERRED.name) {
                return [CategoryType.INTENT, CategoryType.INTEREST, CategoryType.DECLARATIVE].map((type) => type.name).includes(category.type);
            }
            return category.type === segmentType.name.toUpperCase();
        });
        filteredCategories = sortItems(filteredCategories, CategoryField.CODE_NAME);
        setFilteredCategories(filteredCategories);
    }, [segmentType, currentLinks, categories]);

    const handleAddCategories = (categories: Category[]) => {
        let categoriesToAdd: Category[] = [];
        categories.forEach((category) => {
            categoriesToAdd.push(...[category, ...getChildrenForCategory(category)]);
        });
        categoriesToAdd.filter((category) => !selectedCategories.some((it) => it.id === category.id));
        setSelectedCategories((prevState) => Array.from(new Set([...prevState, ...categoriesToAdd]).values()));
    };

    const getChildrenForCategory = (category: Category) => {
        let children = filteredCategories.filter((it) => it.id_parent === category.id_group && it.type === category.type);
        children.forEach((child) => {
            children.push(...[child, ...getChildrenForCategory(child)]);
        });
        return children;
    };

    const handleSubmitCapture = () => {
        setShowErrors(true);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (formValidator.hasErrors()) {
            return;
        }
        setSubmitting(true);
        let volumes = new Map<string, number>();
        try {
            const result = await session.restSegmentLinkCategory.getCategoryVolumes(segmentId);
            volumes = new Map(Object.entries(result));
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("categories' volumes", e.message);
            }
        }

        const defaultValues = SegmentTypeDefaultValue.getByDataType(segmentType);
        const links = selectedCategories.map((item) => {
            return new SegmentLinkCategory({
                [SegmentLinkCategoryField.SEGMENT_ID]: segmentId,
                [SegmentLinkCategoryField.CATEGORY_ID]: item.id,
                [SegmentLinkCategoryField.CATEGORY_NAME]: item.name,
                [SegmentLinkCategoryField.CATEGORY_CODE_NAME]: item.code_name,
                [SegmentLinkCategoryField.CATEGORY_TYPE]: item.type,
                [SegmentLinkCategoryField.VOLUME]: volumes.get(item.id.toString()) || 0,
                [SegmentLinkCategoryField.MIN_SCORE]: defaultValues?.minScore,
                [SegmentLinkCategoryField.EXPIRATION_DAY]: defaultValues?.expirationTime,
                [SegmentLinkCategoryField.PERCENTILE]: !!percentile ? percentile : undefined
            });
        });
        try {
            await session.restSegmentLinkCategory.update(segmentId, [...links, ...currentLinks]);
            alert.updateWithSuccess("categories");
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("categories", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textSegments("modal.add_categories")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmitCapture={handleSubmitCapture} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                        <FieldBlock content={{direction: FlexContentDirection.COLUMN}}>
                            <SearchItems
                                items={filteredCategories}
                                selectedItems={selectedCategories}
                                searchField="fullName"
                                onSubmit={handleAddCategories}
                                loading={isLoading}
                            />
                            {formValidator.isError("categories") &&
                                <FormLayoutMessage message={t("message.error.element_required", {element: "category"})} severity={AlertSeverity.DANGER} small/>
                            }
                        </FieldBlock>
                        <FieldBlock
                            label={textSegments("modal.selected", {count: selectedCategories.length})}
                            actions={
                                <ButtonLink
                                    onClick={() => {
                                        setSelectedCategories([]);
                                        setShowErrors(false);
                                    }}
                                >
                                    {textCommon(Action.REMOVE_ALL.labelKey)}
                                </ButtonLink>
                            }
                        >
                            <ElementList placeholder={textSegments("modal.search_result")} size={ElementListSize.BIG}>
                                {selectedCategories.map((item) =>
                                    <TagCategoryRow
                                        key={item.id}
                                        category={item}
                                        onRemove={() => setSelectedCategories((prevState) => prevState.filter((it) => it.id !== item.id))}
                                    />
                                )}
                            </ElementList>
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalAddSegmentLinksCategory;
