import {ApiModel} from "../../../common/api/model/ApiModel";

export class PortalSettingContentProperties extends ApiModel {
    data_selection_id?: number;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            data_selection_id: this.data_selection_id
        };
    }
}
