import {
    Accordion,
    AccordionItem,
    AccordionItemContent,
    ButtonLink,
    ButtonLinkStyle,
    FieldBlock,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentJustify,
    FlexContentLayout,
    FlexContentMobileDirection,
    FlexContentSpacing,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    FormLayoutTitle,
    InputNumber,
    InputUrl,
    Select,
    TagStyle,
    ToggleSwitch
} from "@sirdata/ui-lib";
import React, {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {CmpConfigCapping} from "../../../api/model/cmp/config/CmpConfigCapping";
import {CmpConfigCCPAField} from "../../../api/model/cmp/config/CmpConfigCCPAField";
import {CmpConfigField} from "../../../api/model/cmp/config/CmpConfigField";
import {CmpConfigLanguage} from "../../../api/model/cmp/config/CmpConfigLanguage";
import {CmpConfigScope} from "../../../api/model/cmp/config/CmpConfigScope";
import {CmpConfigSettings, MAX_CAPPING_IN_DAYS, MAX_COOKIE_MAX_AGE_IN_DAYS, MIN_CAPPING_IN_DAYS, MIN_COOKIE_MAX_AGE_IN_DAYS} from "../../../api/model/cmp/config/CmpConfigSettings";
import {CmpConfigSettingsField} from "../../../api/model/cmp/config/CmpConfigSettingsField";
import {CmpConfigText} from "../../../api/model/cmp/config/CmpConfigText";
import {CmpConfigThemeField} from "../../../api/model/cmp/config/theme/CmpConfigThemeField";
import {CMP_CONFIG_THEME_NO_CONSENT_BUTTONS, CmpConfigThemeNoConsentButton} from "../../../api/model/cmp/config/theme/CmpConfigThemeNoConsentButton";
import {CMP_CONFIG_THEME_NO_CONSENT_BUTTON_STYLES, CmpConfigThemeNoConsentButtonStyle} from "../../../api/model/cmp/config/theme/CmpConfigThemeNoConsentButtonStyle";
import {TranslationPortalFile} from "../../../utils/constants";
import {CmpConfigurationPreview, Tag} from "../index";
import {CmpConfig} from "../../../api/model/cmp/config/CmpConfig";
import {CmpConfigTheme} from "../../../api/model/cmp/config/theme/CmpConfigTheme";
import {CmpConfigCCPA} from "../../../api/model/cmp/config/CmpConfigCCPA";
import ModalEditPersonalizedTexts from "../../modal/cmp-configurations/ModalEditPersonalizedTexts";
import {CMP_CONFIG_THEME_SET_CHOICES_STYLES, CmpConfigThemeSetChoicesStyle} from "../../../api/model/cmp/config/theme/CmpConfigThemeSetChoicesStyle";

type CmpConfigurationSettingsAdvancedSettingsProps = {
    cmpConfig: CmpConfig;
    settings: CmpConfigSettings;
    onChange: (settings: CmpConfigSettings) => void;
};

const CmpConfigurationSettingsAdvancedSettings: FunctionComponent<CmpConfigurationSettingsAdvancedSettingsProps> = ({cmpConfig, settings, onChange}) => {
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);
    const [isShowModalEditPersonalizedTexts, setShowModalEditPersonalizedTexts] = useState(false);

    const handleChangeSettings = (field: CmpConfigSettingsField, value: any) => {
        onChange(new CmpConfigSettings({...settings, [field]: value}));
    };

    const handleChangeSettingsTheme = (field: CmpConfigThemeField, value: any) => {
        handleChangeSettings(CmpConfigSettingsField.THEME, new CmpConfigTheme({...settings.theme, [field]: value}));
    };

    const handleChangeSettingsThemeNoConsentButton = (type: CmpConfigThemeNoConsentButton) => {
        const newTheme = new CmpConfigTheme({...settings.theme, [CmpConfigThemeField.NO_CONSENT_BUTTON]: type});
        if (type === CmpConfigThemeNoConsentButton.NONE) {
            newTheme.closeButton = true;
            newTheme.noConsentButtonStyle = CmpConfigThemeNoConsentButtonStyle.BUTTON_BIG_DEFAULT;
        } else if (settings.theme.noConsentButton === CmpConfigThemeNoConsentButton.NONE) {
            newTheme.closeButton = false;
        }
        handleChangeSettings(CmpConfigSettingsField.THEME, newTheme);
    };

    const handleChangeSettingsThemeNoConsentButtonStyle = (style: CmpConfigThemeNoConsentButtonStyle) => {
        const newTheme = new CmpConfigTheme({...settings.theme, [CmpConfigThemeField.NO_CONSENT_BUTTON_STYLE]: style});
        if (![CmpConfigThemeNoConsentButtonStyle.BUTTON_BIG_DEFAULT, CmpConfigThemeNoConsentButtonStyle.BUTTON_BIG_PRIMARY].includes(style)) {
            newTheme.setChoicesStyle = CmpConfigThemeSetChoicesStyle.BUTTON;
        }
        handleChangeSettings(CmpConfigSettingsField.THEME, newTheme);
    };

    const handleChangeSettingsCCPA = (field: CmpConfigCCPAField, value: any) => {
        handleChangeSettings(CmpConfigSettingsField.CCPA, new CmpConfigCCPA({...settings.ccpa, [field]: value}));
    };

    const handleChangeSettingsTexts = (texts: Map<string, CmpConfigText>) => {
        handleChangeSettings(CmpConfigSettingsField.TEXTS, texts);
        setShowModalEditPersonalizedTexts(false);
    };

    return (
        <FlexContent
            direction={FlexContentDirection.ROW}
            alignment={FlexContentAlignment.START}
            spacing={FlexContentSpacing.LARGE_PLUS}
            layout={FlexContentLayout.TWO_COLUMNS_WIDE_RIGHT}
            mobileDirection={FlexContentMobileDirection.COLUMN}
        >
            <Accordion>
                <AccordionItem heading={<Tag label={textCmpConfigurations("section.language")} icon={{name: "translate"}} style={TagStyle.DEFAULT_OCEAN}/>} startOpen>
                    <AccordionItemContent>
                        <FormLayoutRows>
                            <FormLayoutColumns>
                                <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.LANGUAGE}`)}>
                                    <Select
                                        value={settings.language}
                                        options={[
                                            {
                                                value: "",
                                                label: textCmpConfigurations("language.default")
                                            },
                                            ...CmpConfigLanguage.values().map((it) => ({
                                                value: it.name,
                                                label: textCmpConfigurations(`language.${it.name}`)
                                            }))
                                        ]}
                                        onChange={(option) => handleChangeSettings(CmpConfigSettingsField.LANGUAGE, `${option?.value || ""}`)}
                                    />
                                </FieldBlock>
                                <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.DEFAULT_LANGUAGE}`)}>
                                    <Select
                                        value={settings.defaultLanguage}
                                        options={CmpConfigLanguage.values().map((it) => ({value: it.name, label: textCmpConfigurations(`language.${it.name}`)}))}
                                        clearable
                                        disabled={!!settings.language}
                                        onChange={(option) => handleChangeSettings(CmpConfigSettingsField.DEFAULT_LANGUAGE, `${option?.value || ""}`)}
                                    />
                                </FieldBlock>
                            </FormLayoutColumns>
                            <FlexContent direction={FlexContentDirection.ROW} justify={FlexContentJustify.END}>
                                <ButtonLink onClick={() => setShowModalEditPersonalizedTexts(true)} icon={{name: "east"}} style={ButtonLinkStyle.MIDNIGHT}>
                                    {textCmpConfigurations("modal.edit_personalized_texts")}
                                </ButtonLink>
                                <ModalEditPersonalizedTexts
                                    active={isShowModalEditPersonalizedTexts}
                                    initValue={settings.texts || new Map<string, CmpConfigText>()}
                                    onSubmit={handleChangeSettingsTexts}
                                    onClose={() => setShowModalEditPersonalizedTexts(false)}
                                />
                            </FlexContent>
                        </FormLayoutRows>
                    </AccordionItemContent>
                </AccordionItem>
                <AccordionItem heading={<Tag label={textCmpConfigurations("section.refusal_options")} icon={{name: "block"}} style={TagStyle.DEFAULT_OCEAN}/>}>
                    <AccordionItemContent>
                        <FormLayoutRows>
                            <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.FLEXIBLE_REFUSAL}.label`)}>
                                <Select
                                    value={settings.flexibleRefusal}
                                    options={[
                                        {value: true, label: textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.FLEXIBLE_REFUSAL}.true`)},
                                        {value: false, label: textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.FLEXIBLE_REFUSAL}.false`)}
                                    ]}
                                    onChange={(option) => handleChangeSettings(CmpConfigSettingsField.FLEXIBLE_REFUSAL, option?.value)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.NO_CONSENT_BUTTON}.type`)}>
                                <Select
                                    value={settings.theme.noConsentButton}
                                    options={CMP_CONFIG_THEME_NO_CONSENT_BUTTONS.map((it) => ({
                                        value: it,
                                        label: textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.NO_CONSENT_BUTTON}.type_${it}`)
                                    }))}
                                    onChange={(option) => handleChangeSettingsThemeNoConsentButton(option?.value as CmpConfigThemeNoConsentButton)}
                                />
                            </FieldBlock>
                            {settings.theme.noConsentButton !== CmpConfigThemeNoConsentButton.NONE &&
                                <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.NO_CONSENT_BUTTON_STYLE}.label`)}>
                                    <Select
                                        value={settings.theme.noConsentButtonStyle}
                                        options={CMP_CONFIG_THEME_NO_CONSENT_BUTTON_STYLES.map((it) => ({
                                            value: it,
                                            label: textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.NO_CONSENT_BUTTON_STYLE}.${it}`)
                                        }))}
                                        onChange={(option) => handleChangeSettingsThemeNoConsentButtonStyle(option?.value as CmpConfigThemeNoConsentButtonStyle)}
                                    />
                                </FieldBlock>
                            }
                            <FormLayoutSeparator/>
                            <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.SET_CHOICES_STYLE}.label`)}>
                                <Select
                                    value={settings.theme.setChoicesStyle}
                                    options={CMP_CONFIG_THEME_SET_CHOICES_STYLES.map((it) => ({
                                        value: it,
                                        label: textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.SET_CHOICES_STYLE}.${it}`)
                                    }))}
                                    onChange={(option) => handleChangeSettingsTheme(CmpConfigThemeField.SET_CHOICES_STYLE, option?.value as CmpConfigThemeSetChoicesStyle)}
                                    disabled={![CmpConfigThemeNoConsentButtonStyle.BUTTON_BIG_DEFAULT, CmpConfigThemeNoConsentButtonStyle.BUTTON_BIG_PRIMARY].includes(settings.theme.noConsentButtonStyle)}
                                />
                            </FieldBlock>
                            {(settings.theme.noConsentButton !== CmpConfigThemeNoConsentButton.NONE && settings.theme.noConsentButtonStyle !== CmpConfigThemeNoConsentButtonStyle.CLOSE) &&
                                <>
                                    <FormLayoutSeparator/>
                                    <FieldBlock
                                        name={`${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.CLOSE_BUTTON}`}
                                        label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.CLOSE_BUTTON}`)}
                                    >
                                        <ToggleSwitch
                                            label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.CLOSE_BUTTON}_label`)}
                                            checked={settings.theme.closeButton}
                                            onChange={(value) => handleChangeSettingsTheme(CmpConfigThemeField.CLOSE_BUTTON, value)}
                                        />
                                    </FieldBlock>
                                </>
                            }
                        </FormLayoutRows>
                    </AccordionItemContent>
                </AccordionItem>
                <AccordionItem heading={<Tag label={textCmpConfigurations("section.technical")} icon={{name: "build", outlined: true}} style={TagStyle.DEFAULT_OCEAN}/>}>
                    <AccordionItemContent>
                        <FormLayoutRows>
                            <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.SCOPE}.label`)}>
                                <Select
                                    value={settings.scope}
                                    options={CmpConfigScope.values().map((it) => ({
                                        value: it.name,
                                        label: textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.SCOPE}.${it.name}`)
                                    }))}
                                    onChange={(option) => handleChangeSettings(CmpConfigSettingsField.SCOPE, `${option?.value || ""}`)}
                                />
                            </FieldBlock>
                            <FormLayoutColumns columns={2}>
                                <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.COOKIE_MAX_AGE_IN_DAYS}`)}>
                                    <InputNumber
                                        min={MIN_COOKIE_MAX_AGE_IN_DAYS}
                                        max={MAX_COOKIE_MAX_AGE_IN_DAYS}
                                        value={settings.cookieMaxAgeInDays}
                                        onChange={(value) => handleChangeSettings(CmpConfigSettingsField.COOKIE_MAX_AGE_IN_DAYS, value)}
                                    />
                                    <span>{textCmpConfigurations("days")}</span>
                                </FieldBlock>
                            </FormLayoutColumns>
                            <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                                <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.CAPPING}.label`)}>
                                    <Select
                                        value={settings.capping}
                                        options={CmpConfigCapping.values().map((it) => ({
                                            value: it.name,
                                            label: textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.CAPPING}.${it.name}`)
                                        }))}
                                        onChange={(option) => handleChangeSettings(CmpConfigSettingsField.CAPPING, `${option?.value || ""}`)}
                                    />
                                </FieldBlock>
                                <FormLayoutColumns columns={3}>
                                    <FieldBlock>
                                        <InputNumber
                                            min={MIN_CAPPING_IN_DAYS}
                                            max={MAX_CAPPING_IN_DAYS}
                                            value={settings.cappingInDays}
                                            onChange={(value) => handleChangeSettings(CmpConfigSettingsField.CAPPING_IN_DAYS, value)}
                                        />
                                        <span>{textCmpConfigurations("days")}</span>
                                    </FieldBlock>
                                </FormLayoutColumns>
                            </FormLayoutRows>
                        </FormLayoutRows>
                    </AccordionItemContent>
                </AccordionItem>
                <AccordionItem heading={<Tag label={textCmpConfigurations("section.regulations")} icon={{name: "public"}} style={TagStyle.DEFAULT_OCEAN}/>}>
                    <AccordionItemContent>
                        <FormLayoutRows>
                            <ToggleSwitch
                                name={CmpConfigSettingsField.FORCE_GDPR}
                                label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.FORCE_GDPR}`)}
                                checked={settings.forceGdpr}
                                onChange={(value) => handleChangeSettings(CmpConfigSettingsField.FORCE_GDPR, value)}
                            />
                            <FormLayoutSeparator/>
                            <FormLayoutTitle>{textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.CCPA}.label`)}</FormLayoutTitle>
                            <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                                <ToggleSwitch
                                    name={`${CmpConfigSettingsField.CCPA}.${CmpConfigCCPAField.DISABLED}`}
                                    label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.CCPA}.${CmpConfigCCPAField.DISABLED}`)}
                                    checked={!settings.ccpa.disabled}
                                    onChange={(value) => handleChangeSettingsCCPA(CmpConfigCCPAField.DISABLED, !value)}
                                />
                                <ToggleSwitch
                                    name={`${CmpConfigSettingsField.CCPA}.${CmpConfigCCPAField.LSPA}`}
                                    label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.CCPA}.${CmpConfigCCPAField.LSPA}`)}
                                    checked={settings.ccpa.lspa}
                                    onChange={(value) => handleChangeSettingsCCPA(CmpConfigCCPAField.LSPA, value)}
                                />
                            </FormLayoutRows>
                        </FormLayoutRows>
                    </AccordionItemContent>
                </AccordionItem>
                <AccordionItem heading={<Tag label={textCmpConfigurations("section.privacy_policy")} icon={{name: "security"}} style={TagStyle.DEFAULT_OCEAN}/>}>
                    <AccordionItemContent>
                        <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PRIVACY_POLICY}`)}>
                            <InputUrl
                                value={settings.privacyPolicy}
                                onChange={(value) => handleChangeSettings(CmpConfigSettingsField.PRIVACY_POLICY, value)}
                            />
                        </FieldBlock>
                    </AccordionItemContent>
                </AccordionItem>
            </Accordion>
            <CmpConfigurationPreview cmpConfig={cmpConfig}/>
        </FlexContent>
    );
};

export default CmpConfigurationSettingsAdvancedSettings;
