import {
    Action,
    ButtonLink,
    ButtonLinkCancel,
    ButtonValidate,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContentSpacing,
    Form,
    FormLayoutRows,
    FormValidationType,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    TranslationLibFile
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {CategoryLinkSegment} from "../../../api/model/audience/category/CategoryLinkSegment";
import {CategoryType} from "../../../api/model/audience/category/CategoryType";
import {Segment} from "../../../api/model/audience/segment/Segment";
import {SegmentDataType} from "../../../api/model/audience/segment/SegmentDataType";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {SearchItems, TagCategoryLinkSegmentRow} from "../../snippet";
import {SegmentField} from "../../../api/model/audience/segment/SegmentField";

type ModalEditCategoryLinkedSegmentsProps = {
    active: boolean;
    categoryId: number;
    categoryType?: CategoryType;
    currentLinks: CategoryLinkSegment[];
    onSubmit: (linkSegments: CategoryLinkSegment[]) => void;
    onClose: () => void;
};

const ModalEditCategoryLinkedSegments: FunctionComponent<ModalEditCategoryLinkedSegmentsProps> = ({active, categoryId, categoryType, currentLinks, onSubmit, onClose}) => {
    const alert = useAlert();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textSegments} = useTranslation(TranslationPortalFile.SEGMENTS);
    const [isLoading, setLoading] = useState(true);
    const [filteredSegments, setFilteredSegments] = useState<Segment[]>([]);
    const [highlightedSegments, setHighlightedSegments] = useState<Segment[]>([]);
    const [selectedSegmentLinks, setSelectedSegmentLinks] = useState<CategoryLinkSegment[]>([]);
    const FORM_ID = "form-edit-category-linked-segments";

    useEffect(() => {
        if (active) {
            setSelectedSegmentLinks(currentLinks);

            if (!categoryType) return;

            (async () => {
                try {
                    setLoading(true);
                    const segments = await session.getSegments();
                    const filteredSegments = segments.filter((segment) => {
                        if (segment.data_type.toUpperCase() === categoryType.name) {
                            return true;
                        }
                        if (segment.data_type === SegmentDataType.INFERRED.name) {
                            if ([CategoryType.INTENT.name, CategoryType.INTEREST.name].includes(categoryType.name)) {
                                return true;
                            }
                        }
                        return false;
                    });
                    setFilteredSegments(filteredSegments);
                } catch (e) {
                    if (e instanceof ErrorResponse) {
                        alert.failToLoad("segments", e.message);
                    }
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [active, alert, categoryType, currentLinks]);

    const handleAddSegments = (segments: Segment[]) => {
        segments.forEach((segment) => {
            const dataType = SegmentDataType.getByName(segment.data_type);
            if (dataType) {
                setSelectedSegmentLinks((prevState) => {
                    const newSegmentLink = new CategoryLinkSegment();
                    newSegmentLink.segment_id = segment.id;
                    newSegmentLink.segment_name = segment.name;
                    newSegmentLink.data_type = segment.data_type;
                    newSegmentLink.category_id = categoryId;
                    newSegmentLink.min_score = dataType.minScore;
                    newSegmentLink.expiration_time = dataType.expirationDay;
                    return [...prevState, newSegmentLink];
                });
            }
        });
        setHighlightedSegments(segments);
        setTimeout(() => setHighlightedSegments([]), 1000);
    };

    const handleRemoveSegmentLink = (link: CategoryLinkSegment) => {
        setSelectedSegmentLinks((prevState) => prevState.filter((it) => it.segment_id !== link.segment_id));
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit(selectedSegmentLinks);
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textSegments("modal.linked_segments")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                        <SearchItems
                            items={filteredSegments}
                            selectedItems={selectedSegmentLinks.map((it) => new Segment({[SegmentField.ID]: it.segment_id}))}
                            searchField={SegmentField.NAME}
                            onSubmit={handleAddSegments}
                            loading={isLoading}
                        />
                        <FieldBlock
                            label={textSegments("modal.select.selected", {count: selectedSegmentLinks.length})}
                            actions={
                                <ButtonLink onClick={() => setSelectedSegmentLinks([])}>{textCommon(Action.REMOVE_ALL.labelKey)}</ButtonLink>
                            }
                        >
                            <ElementList placeholder={textSegments("modal.select.search_result")} size={ElementListSize.BIG}>
                                {selectedSegmentLinks.map((link) =>
                                    <TagCategoryLinkSegmentRow
                                        key={link.segment_id}
                                        link={link}
                                        isHighlighted={highlightedSegments.some(({id}) => link.segment_id === id)}
                                        onRemove={() => handleRemoveSegmentLink(link)}
                                    />
                                )}
                            </ElementList>
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalEditCategoryLinkedSegments;
