import {PortalContent} from "../../portal-content/PortalContent";
import {DataSelectionContent} from "./DataSelectionContent";
import {DataSelectionContentField} from "./DataSelectionContentField";
import {Module} from "../../../../utils/Module";
import {ContentElement} from "../../../interface/ContentElement";
import {Status} from "../../../../utils/Status";
import {Origin} from "../../../../common/api/model/Origin";
import {usePreprod} from "../../../../common/utils/helper";

export class DataSelection extends PortalContent {
    content: DataSelectionContent = new DataSelectionContent();
    type = DataSelection.TYPE;

    static TYPE = "DATA_SELECTION";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === "content") {
                    this[key] = new DataSelectionContent(o[key]);
                } else {
                    this[key] = o[key];
                }
            }
        }
    }

    getRoute(): string {
        return Module.AUDIENCE_DATA_SELECTIONS.buildRoute(this.id);
    }

    getSearchIndex(): string {
        return super.buildSearchIndex([
            [DataSelectionContentField.THEME, this.content.theme]
        ]);
    }

    toContentElement(): ContentElement {
        const element = super.toContentElement();
        if (this.status === Status.ONLINE.name) {
            element.url = `${Origin.AUDIENCE.getUrl(usePreprod)}/data-selection/${this.string_identifier}`;
        }
        return element;
    }
}
