import {
    Action,
    Box,
    BoxProps,
    Button,
    ButtonLink,
    ButtonStyle,
    Color,
    ContentBlock,
    ContentBlockAction,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContentLayout,
    FlexContentSpacing,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    FormValidationType,
    IconTooltip,
    InputDomain,
    InputText,
    LayoutColumns,
    LayoutRows,
    Loadable,
    Select,
    SelectAutocomplete,
    Table,
    TableColumn,
    TableRow,
    TagStyle,
    TranslationLibFile
} from "@sirdata/ui-lib";
import {FormEvent, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import {IframeBlock, MainContentHeader, MainContentHeaderAction, OrganizationPartners, SelectUser, Tag, TagWithAction} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {PartnerOrganization} from "../../api/model/partner/organization/PartnerOrganization";
import {Authorization} from "../../api/model/account/Authorization";
import {session} from "../../api/ApiSession";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {PartnerOrganizationField} from "../../api/model/partner/organization/PartnerOrganizationField";
import {PartnerOrganizationGroup} from "../../api/model/partner/organization/PartnerOrganizationGroup";
import {detectChanges} from "../../common/utils/portal";
import {RevenueSeatSearchQuery} from "../../api/model/revenue/seat/RevenueSeatSearchQuery";
import useSearch from "../../utils/hooks/useSearch";
import {RevenueSeat} from "../../api/model/revenue/seat/RevenueSeat";
import {RevenueSeatSearchResult} from "../../api/model/revenue/seat/RevenueSeatSearchResult";
import {RevenueSeatField} from "../../api/model/revenue/seat/RevenueSeatField";
import ModalCreateSeat from "../../component/modal/seats/ModalCreateSeat";
import {SearchParamsField} from "../../utils/SearchParamsField";
import useAlert from "../../utils/hooks/useAlert";
import ModalAssignSeats from "../../component/modal/organizations/ModalAssignSeats";
import {PartnerOrganizationPropertiesField} from "../../api/model/partner/organization/PartnerOrganizationPropertiesField";
import {PartnerOrganizationType} from "../../api/model/partner/organization/PartnerOrganizationType";
import {Looker} from "../../utils/Looker";

function OrganizationsDetails() {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textOrganizations} = useTranslation(TranslationPortalFile.ORGANIZATIONS);
    const {t: textSeats} = useTranslation(TranslationPortalFile.SEATS);
    const {id} = useParams() as {id: string};
    const navigate = useNavigate();
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);
    const [isShowModalDelete, setShowModalDelete] = useState(false);
    const [isShowModalCreateSeat, setShowModalCreateSeat] = useState(false);
    const [isShowModalAssignSeats, setShowModalAssignSeats] = useState(false);

    const FORM_ID = "form-edit-organization";
    const [organization, setOrganization] = useState<PartnerOrganization>(new PartnerOrganization());
    const [initOrganization, setInitOrganization] = useState<PartnerOrganization>(new PartnerOrganization());
    const [organizationSeats, setOrganizationSeats] = useState<RevenueSeat[]>([]);
    const [initOwnerId, setInitOwnerId] = useState<number>();
    const [organizationGroups, setOrganizationGroups] = useState<PartnerOrganizationGroup[]>([]);
    const [newDomain, setNewDomain] = useState<string>("");
    const {setSearchResult, ...search} = useSearch(RevenueSeat, RevenueSeatSearchQuery, RevenueSeatSearchResult);

    const loadSeats = useCallback(async () => {
        try {
            const searchQuery = new RevenueSeatSearchQuery({...search.searchQuery, [RevenueSeatField.ORGANIZATION_ID]: id});
            const newSearchResult = await session.restSeat.search(searchQuery);
            setSearchResult(newSearchResult);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("seats", e.message);
            }
        }
    }, [id, search.searchQuery, setSearchResult, alert]);

    useEffect(() => {
        (async () => {
            try {
                const organization = await session.restPartnerOrganization.get(+id);
                setOrganization(organization);
                setInitOrganization(new PartnerOrganization(organization));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("organization", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        (async () => {
            await loadSeats();
        })();
    }, [loadSeats]);

    useEffect(() => {
        if (!organization.id) return;
        (async () => {
            try {
                const organizationGroups = await session.restPartnerOrganizationGroup.list();
                setOrganizationGroups(organizationGroups);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("organization groups", e.message);
                }
            }
        })();
    }, [organization, alert]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(organization, initOrganization));
    }, [organization, initOrganization]);

    const handleAddDomain = (domain: string) => {
        setNewDomain("");
        if (organization.domains.find((it) => it === domain)) return;
        let newDomains = [...organization.domains, domain];
        const newOrganization = new PartnerOrganization({...organization, domains: newDomains});
        setOrganization(newOrganization);
    };

    const handleRemoveDomain = (domain: string) => {
        let newDomains = [...organization.domains].filter((it) => it !== domain);
        const newOrganization = new PartnerOrganization({...organization, domains: newDomains});
        setOrganization(newOrganization);
    };

    const handleRemoveAllDomains = () => {
        const newOrganization = new PartnerOrganization({...organization, domains: []});
        setOrganization(newOrganization);
    };

    const handleSave = async (e: FormEvent) => {
        e.preventDefault();

        if (organization.owner_id !== initOrganization.owner_id) {
            const searchQuery = new RevenueSeatSearchQuery({...search.searchQuery, [RevenueSeatField.ORGANIZATION_ID]: id, [SearchParamsField.SIZE]: 1000});
            const organizationSeats = await session.restSeat.search(searchQuery);
            if (organizationSeats.elements.some((it) => it.owner_id !== organization.owner_id)) {
                setInitOwnerId(initOrganization.owner_id);
                setShowModalAssignSeats(true);
                setOrganizationSeats(organizationSeats.elements);
            }
        }
        try {
            await session.restPartnerOrganization.update(organization);
            const newOrganization = await session.restPartnerOrganization.updateProperties(organization.id, organization.properties);
            setOrganization(newOrganization);
            setInitOrganization(new PartnerOrganization(newOrganization));
            alert.updateWithSuccess("organization");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("organization", e.message);
            }
        }
    };

    const handleCloseAssignSeats = async (refresh?: boolean) => {
        if (refresh) {
            await loadSeats();
        }
        setShowModalAssignSeats(false);
    };

    const doDeleteOrganization = async () => {
        try {
            await session.restPartnerOrganization.delete(organization.id);
            navigate(Module.ORGANIZATIONS.path);
            alert.deleteWithSuccess("organization");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("organization", e.message);
            }
        }
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.ORGANIZATIONS} element={initOrganization.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <RestrictedContent allowedTo={Authorization.ORGANIZATIONS.update}>
                    <MainContentHeaderAction action={Action.SAVE} form={FORM_ID} disabled={!isUnsavedChanges}/>
                    <MainContentHeaderAction action={Action.DELETE} onClick={() => setShowModalDelete(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <Form id={FORM_ID} onSubmit={handleSave} validationType={FormValidationType.CUSTOM}>
                    <LayoutRows>
                        <LayoutColumns>
                            <LayoutRows>
                                <Loadable loading={isLoading}>
                                    <ContentBlock header={{title: {label: textOrganizations("section.information")}}}>
                                        <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                            <FormLayoutRows>
                                                <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                                                    <FieldBlock label={textOrganizations(`field.${PartnerOrganizationField.NAME}`)} required>
                                                        <InputText
                                                            value={organization.name}
                                                            onChange={(value) => setOrganization((prevState) => new PartnerOrganization({...prevState, [PartnerOrganizationField.NAME]: value}))}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textOrganizations(`field.${PartnerOrganizationField.TYPE}`)} required>
                                                        <Select
                                                            value={organization.type}
                                                            options={PartnerOrganizationType.values().map((it) => ({value: it.name, label: textOrganizations(`type.${it.name}`)}))}
                                                            onChange={(option) => setOrganization((prevState) => new PartnerOrganization({...prevState, [PartnerOrganizationField.TYPE]: `${option?.value || ""}`}))}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FieldBlock label={textOrganizations(`field.${PartnerOrganizationField.DESCRIPTION}`)}>
                                                    <InputText
                                                        value={organization.description}
                                                        onChange={(value) => setOrganization((prevState) => new PartnerOrganization({...prevState, [PartnerOrganizationField.DESCRIPTION]: value}))}
                                                    />
                                                </FieldBlock>
                                                <FormLayoutColumns columns={2}>
                                                    <FieldBlock label={textOrganizations("field.organization_group")}>
                                                        <SelectAutocomplete
                                                            value={organization.group_id}
                                                            options={organizationGroups.map((it) => ({value: it.id, label: it.name}))}
                                                            onChange={(option) => setOrganization((prevState) => new PartnerOrganization({...prevState, [PartnerOrganizationField.GROUP_ID]: option ? +option?.value : 0}))}
                                                            clearable
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                                    <FieldBlock
                                                        label={textOrganizations(`field.${PartnerOrganizationField.DOMAINS}`)}
                                                        actions={organization.domains.length ? <ButtonLink onClick={handleRemoveAllDomains}>{textCommon(Action.REMOVE_ALL.labelKey)}</ButtonLink> : undefined}
                                                    >
                                                        <InputDomain
                                                            value={newDomain}
                                                            onChange={setNewDomain}
                                                        />
                                                        <Button style={ButtonStyle.PRIMARY_MIDNIGHT} onClick={() => handleAddDomain(newDomain)} disabled={!newDomain.length}>
                                                            {textCommon(Action.ADD.labelKey)}
                                                        </Button>
                                                    </FieldBlock>
                                                    {!!organization.domains.length &&
                                                        <ElementList size={ElementListSize.SMALL} inline>
                                                            {organization.domains.map((domain) =>
                                                                <TagWithAction
                                                                    key={domain}
                                                                    value={domain}
                                                                    onDelete={() => handleRemoveDomain(domain)}
                                                                    active={true}
                                                                />
                                                            )}
                                                        </ElementList>
                                                    }
                                                </FormLayoutRows>
                                                <FormLayoutSeparator/>
                                                <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                                                    <FieldBlock label={textOrganizations(`field.${PartnerOrganizationField.OWNER_ID}`)} required>
                                                        <SelectUser
                                                            value={organization.owner_id}
                                                            onChange={(user) => setOrganization((prevState) => new PartnerOrganization({...prevState, [PartnerOrganizationField.OWNER_ID]: user?.id || 0}))}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textOrganizations(`field.${PartnerOrganizationField.PROPERTIES}.${PartnerOrganizationPropertiesField.FRESHSALES_ID}`)}>
                                                        <InputText
                                                            value={organization.properties.freshsales_id || ""}
                                                            onChange={(value) => setOrganization((prevState) => new PartnerOrganization({
                                                                ...prevState,
                                                                [PartnerOrganizationField.PROPERTIES]: {
                                                                    ...prevState?.properties,
                                                                    [PartnerOrganizationPropertiesField.FRESHSALES_ID]: value
                                                                }
                                                            }))}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                            </FormLayoutRows>
                                        </Box>
                                    </ContentBlock>
                                </Loadable>
                            </LayoutRows>
                            <LayoutRows>
                                <OrganizationPartners organization={organization}/>
                                <Loadable loading={isLoading}>
                                    <ContentBlock
                                        header={{
                                            title: {label: textOrganizations("section.seats")},
                                            actions: (
                                                <RestrictedContent allowedTo={Authorization.SEATS.update}>
                                                    <ContentBlockAction
                                                        action={Action.ADD}
                                                        onClick={() => setShowModalCreateSeat(true)}
                                                    />
                                                </RestrictedContent>
                                            )
                                        }}
                                    >
                                        <Table
                                            columns={[
                                                {width: 25, label: textSeats(`field.${RevenueSeatField.PLATFORM}`)},
                                                {width: 25, label: textSeats(`field.${RevenueSeatField.SEAT_ID}`)},
                                                {width: 50, label: textSeats(`field.${RevenueSeatField.OWNER}`)}
                                            ]}
                                            pagination={search.searchResult.getPagination(search.changePage)}
                                        >
                                            {search.searchResult.elements.map((item) =>
                                                <TableRow key={item.id} onClick={() => window.open(item.getRoute(), "_blank")}>
                                                    <TableColumn>
                                                        <Tag label={item.platform} style={TagStyle.PRIMARY_OCEAN}/>
                                                    </TableColumn>
                                                    <TableColumn>{item.seat_id}</TableColumn>
                                                    <TableColumn>
                                                        <span>{item.owner?.name}</span>
                                                        {item.owner_id !== initOrganization.owner_id &&
                                                            <IconTooltip
                                                                text={textOrganizations("tooltip.seat_with_different_owner")}
                                                                icon={{name: "warning", colorIcon: Color.ORANGE}}
                                                            />
                                                        }
                                                    </TableColumn>
                                                </TableRow>
                                            )}
                                        </Table>
                                    </ContentBlock>
                                </Loadable>
                            </LayoutRows>
                        </LayoutColumns>
                        <IframeBlock
                            src={Looker.getDashboardIframeUrl(254, `ID=${organization.id}&Date+Type=MONTH`)}
                            title="organization-iframe"
                            height={"95%"}
                        />
                    </LayoutRows>
                </Form>
                <ModalConfirmDelete
                    active={isShowModalDelete}
                    entity="organization"
                    confirm={doDeleteOrganization}
                    cancel={() => setShowModalDelete(false)}
                />
                <ModalCreateSeat
                    active={isShowModalCreateSeat}
                    organization={organization}
                    onClose={(refresh) => {
                        setShowModalCreateSeat(false);
                        refresh && loadSeats();
                    }}
                />
                <ModalAssignSeats
                    active={isShowModalAssignSeats}
                    initOwnerId={initOwnerId}
                    newOwnerId={organization.owner_id}
                    seats={organizationSeats.filter((it) => it.owner_id !== organization.owner_id)}
                    onUpdate={() => handleCloseAssignSeats(true)}
                    onClose={handleCloseAssignSeats}
                />
            </MainContent>
        </Wrapper>
    );
}

export default OrganizationsDetails;
