import {Color, IconTooltip, TableColumn, TableColumnStyle, TableRow, TagStyle} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {DataSelection} from "../../../../api/model/audience/data-selection/DataSelection";
import {TranslationPortalFile} from "../../../../utils/constants";
import {LabelStatus, Tag} from "../../index";

type DataSelectionRowProps = {
    item: DataSelection;
    pinned: boolean;
}

const DataSelectionRow: FunctionComponent<DataSelectionRowProps> = ({item, pinned}) => {
    const {t: textAudienceDataSelections} = useTranslation(TranslationPortalFile.AUDIENCE_DATA_SELECTIONS);

    return (
        <TableRow onClick={{link: item.getRoute()}}>
            <TableColumn>
                <LabelStatus status={item.getStatus()}/>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{item.id}</TableColumn>
            <TableColumn>
                <Tag label={textAudienceDataSelections(`theme.${item.content.theme}`)} style={TagStyle.PRIMARY_OCEAN}/>
            </TableColumn>
            <TableColumn>
                <span>{item.title}</span>
                {pinned &&
                    <IconTooltip
                        icon={{name: "keep", colorIcon: Color.YELLOW}}
                        text={textAudienceDataSelections("tooltip.pinned_data_selection")}
                    />
                }
            </TableColumn>
            <TableColumn styles={TableColumnStyle.FIXED_WIDTH}>
                {item.content.tags.map((tag) =>
                    <Tag key={tag} label={tag} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
                )}
            </TableColumn>
        </TableRow>
    );
};

export default DataSelectionRow;
