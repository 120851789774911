import {ButtonLinkCancel, ButtonValidate, FieldBlock, Form, FormLayoutColumns, FormLayoutRows, FormValidationType, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, Select, ToggleSwitch} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {ApiAuthorization} from "../../../api/model/auth/ApiAuthorization";
import {PartnerLicenseAuthorization} from "../../../api/model/partner/license/PartnerLicenseAuthorization";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import {PartnerLicenseAuthorizationField} from "../../../api/model/partner/license/PartnerLicenseAuthorizationField";
import useAlert from "../../../utils/hooks/useAlert";

type ModalAddLicenseAuthorizationProps = {
    onSubmit: (license: PartnerLicenseAuthorization) => void;
    onClose: () => void;
    active: boolean;
};

const ModalAddLicenseAuthorization: FunctionComponent<ModalAddLicenseAuthorizationProps> = ({onSubmit, onClose, active}) => {
    const alert = useAlert();
    const {t: textLicenses} = useTranslation(TranslationPortalFile.LICENSES);
    const [isLoading, setLoading] = useState<boolean>(true);

    const FORM_ID = "form-add-license-authorization";
    const [licenseAuth, setLicenseAuth] = useState<PartnerLicenseAuthorization>({authorization: "", remove_on_deactivation: true});
    const [authorizations, setAuthorizations] = useState<ApiAuthorization[]>([]);

    useEffect(() => {
        if (!active) {
            setLicenseAuth({authorization: "", remove_on_deactivation: true});
            return;
        }

        (async () => {
            try {
                const authorizations = await session.restPartnerAuthorization.list();
                setAuthorizations(authorizations);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("partner authorizations", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [active, alert]);

    const handleChange = (field: PartnerLicenseAuthorizationField, value: any) => {
        setLicenseAuth((prevState) => ({...prevState, [field]: value}));
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit(licenseAuth);
    };

    return (
        <ModalNew onClose={onClose} active={active} loading={isLoading}>
            <ModalHeader>
                <ModalHeaderTitle title={textLicenses("modal.authorization.title")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutColumns>
                            <FieldBlock label={textLicenses(`field.${PartnerLicenseAuthorizationField.AUTHORIZATION}`)} required>
                                <Select
                                    value={licenseAuth.authorization}
                                    options={authorizations.map((authz) => ({label: authz.name, value: authz.name}))}
                                    onChange={(option) => handleChange(PartnerLicenseAuthorizationField.AUTHORIZATION, option?.value)}
                                    clearable
                                />
                            </FieldBlock>
                            <FieldBlock
                                label={textLicenses(`field.${PartnerLicenseAuthorizationField.REMOVE_ON_DEACTIVATION}`)}
                                name={PartnerLicenseAuthorizationField.REMOVE_ON_DEACTIVATION}
                            >
                                <ToggleSwitch
                                    checked={licenseAuth.remove_on_deactivation}
                                    onChange={(value) => handleChange(PartnerLicenseAuthorizationField.REMOVE_ON_DEACTIVATION, value)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalAddLicenseAuthorization;
