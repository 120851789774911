import {Action, ButtonLink, ButtonLinkCancel, ButtonValidate, ElementList, ElementListSize, FieldBlock, FlexContentSpacing, FormLayoutRows, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {PartnerOrganization} from "../../../api/model/partner/organization/PartnerOrganization";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import {Partner} from "../../../api/model/partner/Partner";
import useAlert from "../../../utils/hooks/useAlert";
import {SearchItems, TagPartnerRow} from "../../snippet";
import {PartnerAccount} from "../../../api/model/partner/PartnerAccount";

type ModalEditOrganizationPartnersProps = {
    active: boolean;
    organization: PartnerOrganization;
    partnerAccounts: PartnerAccount[];
    onClose: (refresh: boolean) => void;
};

const ModalEditOrganizationPartners: FunctionComponent<ModalEditOrganizationPartnersProps> = ({active, organization, partnerAccounts, onClose}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textOrganizations} = useTranslation(TranslationPortalFile.ORGANIZATIONS);
    const alert = useAlert();
    const [isLoading, setLoading] = useState(false);
    const [partners, setPartners] = useState<Partner[]>([]);
    const [selectedPartners, setSelectedPartners] = useState<Partner[]>([]);
    const [highlightedPartners, setHighlightedPartners] = useState<Partner[]>([]);
    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (active) {
            const newSelectedPartners = partnerAccounts.map((it) => it.partner).filter((it) => !!it) as Partner[];
            setSelectedPartners([...newSelectedPartners]);
            (async () => {
                try {
                    setLoading(true);
                    const partners = await session.getPartners();
                    setPartners(partners);
                } catch (e) {
                    if (e instanceof ErrorResponse) {
                        alert.failToLoad("partners", e.message);
                    }
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [partnerAccounts, active, alert]);

    const handleAddPartners = (partners: Partner[]) => {
        setSelectedPartners((prevState) => [...prevState, ...partners]);
        setHighlightedPartners(partners);
        setTimeout(() => setHighlightedPartners([]), 1000);
    };

    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            const partnersToRemove = partnerAccounts.filter((account) => !selectedPartners.some((it) => it.id === account.partner_id)).map((it) => it.partner);
            for (let partner of partnersToRemove) {
                if (partner) {
                    await session.restPartnerOrganization.removePartner(organization, partner.id);
                }
            }

            const partnerToAdd = selectedPartners.filter((partner) => !partnerAccounts.some((it) => it.partner_id === partner.id));
            for (let partner of partnerToAdd) {
                await session.restPartnerOrganization.addPartner(organization.id, partner.id);
            }
            alert.updateWithSuccess("partners");
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("partners", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    const hasEmptyField = () => !selectedPartners.length;

    return (
        <ModalNew onClose={() => onClose(false)} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textOrganizations("modal.edit_partners.title")}/>
            </ModalHeader>
            <ModalContent>
                <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                    <SearchItems
                        items={partners}
                        selectedItems={selectedPartners}
                        searchField="nameWithCompany"
                        onSubmit={handleAddPartners}
                        loading={isLoading}
                    />
                    <FieldBlock
                        label={textOrganizations("partners.selected", {count: selectedPartners.length})}
                        actions={!!selectedPartners.length ? <ButtonLink onClick={() => setSelectedPartners([])}>{textCommon(Action.REMOVE_ALL.labelKey)}</ButtonLink> : []}
                    >
                        <ElementList placeholder={textOrganizations("partners.list_placeholder")} size={ElementListSize.BIG}>
                            {selectedPartners.map((partner) =>
                                <TagPartnerRow
                                    key={partner.id}
                                    partner={partner}
                                    isHighlighted={highlightedPartners.some(({id}) => partner.id === id)}
                                    onRemove={() => setSelectedPartners(selectedPartners.filter(({id}) => partner.id !== id))}
                                />
                            )}
                        </ElementList>
                    </FieldBlock>
                </FormLayoutRows>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate onClick={handleSubmit} disabled={hasEmptyField()} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalEditOrganizationPartners;
