import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {CategoryPanelType} from "./CategoryPanelType";

export class CategoryPanel extends ApiModel {
    type: string = CategoryPanelType.KANTAR_TGI.name;
    category_id: number = 0;
    population?: number;
    file?: ArrayBuffer | undefined;
    content_type: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
