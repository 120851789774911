import {FC, useCallback, useEffect, useState} from "react";
import {Action, ActionsMenu, ContentBlock, ContentBlockAction, Loadable, Table, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import {RestrictedContent} from "../../../common/component/widget";
import {Authorization} from "../../../api/model/account/Authorization";
import {PartnerSiteField} from "../../../api/model/partner/PartnerSiteField";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {session} from "../../../api/ApiSession";
import {PartnerSite} from "../../../api/model/partner/PartnerSite";
import ModalCreatePartnerSite from "../../modal/partners/ModalCreatePartnerSite";
import ModalEditPartnerSite from "../../modal/partners/ModalEditPartnerSite";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../../common/api/http/HttpStatusCode";
import useAlert from "../../../utils/hooks/useAlert";

type PartnerSitesProps = {
    partnerId: number;
};

const PartnerSites: FC<PartnerSitesProps> = ({partnerId}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textPartners} = useTranslation(TranslationPortalFile.PARTNERS);
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [isShowModalCreatePartnerSite, setShowModalCreatePartnerSite] = useState(false);
    const [sites, setSites] = useState<PartnerSite[]>([]);
    const [activeEditPartnerSite, setActiveEditPartnerSite] = useState<PartnerSite>();

    const loadPartnerSites = useCallback(async () => {
        if (!partnerId) return;
        try {
            setLoading(true);
            const sites = await session.restPartnerSite.list(partnerId);
            setSites(sites);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                if (e.statusCode !== HttpStatusCode.NOT_FOUND) {
                    alert.failToLoad("sites", e.message);
                    return;
                }
            }
        } finally {
            setLoading(false);
        }
    }, [alert, partnerId]);

    useEffect(() => {
        (async () => {
            await loadPartnerSites();
        })();
    }, [loadPartnerSites]);

    const handleDeletePartnerSite = async (site: PartnerSite) => {
        try {
            await session.restPartnerSite.delete(site);
            await loadPartnerSites();
            alert.deleteWithSuccess("sites");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("sites", e.message);
            }
        }
    };

    return (
        <Loadable loading={isLoading}>
            <ContentBlock
                header={{
                    title: {label: textPartners("section.sites")},
                    actions: (
                        <RestrictedContent allowedTo={Authorization.PARTNERS.update}>
                            <ContentBlockAction
                                action={Action.ADD}
                                onClick={() => setShowModalCreatePartnerSite(true)}
                            />
                        </RestrictedContent>
                    )
                }}
            >
                <Table
                    columns={[
                        {width: 20, label: textPartners(`field.site.${PartnerSiteField.SITE_ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                        {width: 75, label: textPartners(`field.site.${PartnerSiteField.NAME}`)},
                        {width: 5}
                    ]}
                >
                    {sites.map((site) =>
                        <TableRow key={site.name}>
                            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{site.site_id}</TableColumn>
                            <TableColumn>{site.name}</TableColumn>
                            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                                <RestrictedContent allowedTo={Authorization.PARTNERS.update}>
                                    <ActionsMenu
                                        iconTooltip={{icon: Action.EDIT.icon, text: textCommon(Action.EDIT.labelKey)}}
                                        items={[
                                            {label: textCommon(Action.EDIT.labelKey), onClick: () => setActiveEditPartnerSite(site)},
                                            {label: textCommon(Action.DELETE.labelKey), critical: true, separator: true, onClick: () => handleDeletePartnerSite(site)}
                                        ]}
                                    />
                                </RestrictedContent>
                            </TableActionColumn>
                        </TableRow>
                    )}
                </Table>
                <ModalCreatePartnerSite
                    active={isShowModalCreatePartnerSite}
                    partnerId={partnerId}
                    existingSites={sites}
                    onClose={(refresh) => {
                        setShowModalCreatePartnerSite(false);
                        refresh && loadPartnerSites();
                    }}
                />
                <ModalEditPartnerSite
                    initSite={activeEditPartnerSite}
                    existingSites={sites}
                    onClose={(refresh) => {
                        setActiveEditPartnerSite(undefined);
                        refresh && loadPartnerSites();
                    }}
                />
            </ContentBlock>
        </Loadable>
    );
};

export default PartnerSites;
