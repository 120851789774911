import {ApiModel} from "../../../../common/api/model/ApiModel";
import {ContentElement} from "../../../interface/ContentElement";
import {PricingStep} from "./PricingStep";
import {PricingBillingPeriod} from "./PricingBillingPeriod";
import {Status} from "../../../../utils/Status";
import {PricingField} from "./PricingField";
import {Module} from "../../../../utils/Module";
import {ApiService} from "../../ApiService";

export class Pricing extends ApiModel {
    id: number = 0;
    id_user: number = 0;
    api_service: string = "";
    default: boolean = false;
    name: string = "";
    invoice_label: string = "";
    description: string = "";
    billing_period: PricingBillingPeriod = PricingBillingPeriod.MONTH;
    billing_day: number = 1;
    fee: number = 0;
    volume_cost_per_unit: number = 0;
    divider_unit: number = 1;
    step_price_is_per_unit: boolean = false;
    axonaut_product_id: number = 0;
    steps_price: PricingStep[] = [];
    custom_map: {} = {};
    barter_agreement: boolean = false;
    status: string = Status.AVAILABLE.name;
    only_last_step_price_billable: boolean = false;
    readonly creation_ts: string = "";
    readonly last_update_ts: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    static apiServices(): ApiService[] {
        return [
            ApiService.CMP,
            ApiService.CMP_EXTRA_VENDOR_LIST,
            ApiService.VIDEO_WALL,
            ApiService.HELPER,
            ApiService.CUSTOMER,
            ApiService.GTM_SERVER_SIDE,
            ApiService.LIVE_TARGETING
        ];
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === PricingField.STEPS_PRICE) {
                    this.steps_price = this.steps_price || [];
                    (o[key] as PricingStep[])?.forEach((obj) => {
                        const it = new PricingStep(obj);
                        this.steps_price.push(it);
                    });
                } else {
                    this[key] = o[key];
                }
            }
        }
    }

    hasInvalidSteps(): boolean {
        if (!this.step_price_is_per_unit) return false;
        return !!this.steps_price?.find((it, index, arr) => it.limit && it.limit <= (arr[index - 1]?.limit || 0));
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            api_service: this.api_service,
            default: this.default,
            name: this.name,
            invoice_label: this.invoice_label,
            description: this.description,
            billing_period: this.billing_period,
            billing_day: this.billing_day,
            fee: this.fee,
            volume_cost_per_unit: this.volume_cost_per_unit,
            divider_unit: this.divider_unit,
            step_price_is_per_unit: this.step_price_is_per_unit,
            axonaut_product_id: this.axonaut_product_id,
            steps_price: this.steps_price,
            custom_map: this.custom_map,
            barter_agreement: this.barter_agreement,
            status: this.status,
            only_last_step_price_billable: this.only_last_step_price_billable
        };
    }

    getRoute(): string {
        return Module.PRICINGS.buildRoute(this.id);
    }

    getStatus(): Status {
        if (this.status === Status.AVAILABLE.name) {
            return Status.AVAILABLE;
        } else if (this.status === Status.DEPRECATED.name) {
            return Status.DEPRECATED;
        } else {
            return Status.DELETED;
        }
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, this.name);
        element.status = this.getStatus();
        element.createdAt = new Date(this.creation_ts);
        element.lastUpdated = new Date(this.last_update_ts);
        return element;
    }

    static getApiServiceByName(name: string): ApiService | undefined {
        return this.apiServices().find((it) => it.name === name);
    }
}
