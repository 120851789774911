import React, {FunctionComponent} from "react";
import {Icon, TagStyle} from "@sirdata/ui-lib";
import {Tag} from "../index";

type TaxonomyPathProps = {
    items: string[];
};

const TaxonomyPath: FunctionComponent<TaxonomyPathProps> = ({items}) => {
    return (
        <div className="taxonomy-path">
            {items.map((item, index) =>
                <span key={`taxonomy-path-${index.toString()}`}>
                    {index !== 0 && <Icon name="keyboard_arrow_right"/>}
                    <Tag label={item} style={TagStyle.PRIMARY_GREY_LIGHT}/>
                </span>
            )}
        </div>
    );
};

export default TaxonomyPath;
