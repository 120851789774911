import {PortalContentSearchQuery} from "../portal-content/PortalContentSearchQuery";
import {PortalSetting} from "./PortalSetting";
import {SearchParams} from "../../../utils/SearchParams";
import {SearchParamsField} from "../../../utils/SearchParamsField";
import {ApiService} from "../ApiService";

export class PortalSettingSearchQuery extends PortalContentSearchQuery {
    withSearchParams(params: SearchParams): PortalContentSearchQuery {
        const newQuery = super.withSearchParams(params);
        newQuery.service = params.getString(SearchParamsField.SERVICE);
        return newQuery;
    };

    forApiService(service: ApiService): PortalSettingSearchQuery {
        this.service = service.name;
        return this;
    }

    constructor(o?: {}) {
        super(o);
        this.load(o);
        this.type = PortalSetting.TYPE;
    }
}
