import React, {useCallback, useEffect, useState} from "react";
import {Action, ContentBlock, ContentBlockAction, Loadable, Table, TableColumn, TableRow} from "@sirdata/ui-lib";
import {RestrictedContent} from "../../../common/component/widget";
import {Authorization} from "../../../api/model/account/Authorization";
import {LabelPartner} from "../index";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {buildAndDownloadCSV} from "../../../common/utils/portal";
import {PartnerAccount} from "../../../api/model/partner/PartnerAccount";
import {PartnerOrganization} from "../../../api/model/partner/organization/PartnerOrganization";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";
import ModalEditOrganizationPartners from "../../modal/organizations/ModalEditOrganizationPartners";
import {PartnerField} from "../../../api/model/partner/PartnerField";

type OrganizationPartnersProps = {
    organization: PartnerOrganization;
}

const OrganizationPartners: React.FC<OrganizationPartnersProps> = ({organization}) => {
    const alert = useAlert();
    const {t: textOrganizations} = useTranslation(TranslationPortalFile.ORGANIZATIONS);
    const {t: textPartners} = useTranslation(TranslationPortalFile.PARTNERS);
    const [isLoading, setLoading] = useState(true);
    const [isShowModalEditOrganizationPartners, setShowModalEditOrganizationPartners] = useState(false);
    const [partnerAccounts, setPartnerAccounts] = useState<PartnerAccount[]>([]);

    const loadOrganizationPartners = useCallback(async () => {
        try {
            const newPartnerAccounts = await session.restPartnerOrganization.getPartnerAccounts(organization);
            setPartnerAccounts(newPartnerAccounts);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("partners", e.message);
            }
        }
    }, [organization, alert]);

    useEffect(() => {
        if (!organization.id) return;
        (async () => {
            await loadOrganizationPartners();
            setLoading(false);
        })();
    }, [organization, loadOrganizationPartners]);

    const handleExport = () => {
        const headers = [
            "Partner Account ID",
            "First Name",
            "Last Name",
            "Email",
            "Organization Group ID",
            "Organization Group Name",
            "Organization ID",
            "Organization Name"
        ];
        const items = partnerAccounts.map((it) => [
            it.id,
            it.first_name,
            it.last_name,
            it.email,
            organization.group_id,
            organization.group?.name,
            organization.id,
            organization.name
        ]);
        buildAndDownloadCSV(`organization-${organization.id}-partner-accounts`, headers, items);
    };

    return (
        <Loadable loading={isLoading}>
            <ContentBlock
                header={{
                    title: {label: textOrganizations("section.partners")}, actions: [
                        <ContentBlockAction
                            key="action-export"
                            action={Action.EXPORT}
                            onClick={handleExport}
                        />,
                        <RestrictedContent key="action-edit" allowedTo={Authorization.ORGANIZATIONS.update}>
                            <ContentBlockAction
                                action={Action.EDIT}
                                onClick={() => setShowModalEditOrganizationPartners(true)}
                            />
                        </RestrictedContent>
                    ]
                }}
            >
                <Table
                    columns={[
                        {width: 20, label: textPartners(`field.${PartnerField.ID}`)},
                        {width: 40, label: textPartners("field.name")},
                        {width: 40, label: textPartners(`field.${PartnerField.EMAIL}`)}
                    ]}
                >
                    {partnerAccounts.map((item) =>
                        <TableRow key={item.id} onClick={() => window.open(item.partner?.getRoute(), "_blank")}>
                            <TableColumn>
                                <LabelPartner partner={item.partner?.id}/>
                            </TableColumn>
                            <TableColumn>{item.fullName}</TableColumn>
                            <TableColumn>{item.email}</TableColumn>
                        </TableRow>
                    )}
                </Table>
            </ContentBlock>
            <ModalEditOrganizationPartners
                active={isShowModalEditOrganizationPartners}
                organization={organization}
                partnerAccounts={partnerAccounts}
                onClose={(refresh) => {
                    setShowModalEditOrganizationPartners(false);
                    refresh && loadOrganizationPartners();
                }}
            />
        </Loadable>
    );
};

export default OrganizationPartners;
