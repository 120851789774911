import {
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormValidationType,
    InputEmail,
    InputPhone,
    InputText,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    RadioButtons
} from "@sirdata/ui-lib";
import {FC, FormEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DistributionContact} from "../../../api/model/audience/distribution/DistributionContact";
import {DistributionContactField} from "../../../api/model/audience/distribution/DistributionContactField";
import {DISTRIBUTION_CONTACT_TYPES} from "../../../api/model/audience/distribution/DistributionContactType";
import {DistributionField} from "../../../api/model/audience/distribution/DistributionField";
import {DistributionPropertiesField} from "../../../api/model/audience/distribution/DistributionPropertiesField";
import {TranslationPortalFile} from "../../../utils/constants";

type ModalManageDistributionContactProps = {
    active: boolean;
    initContact?: DistributionContact;
    onSubmit: (initContact: DistributionContact) => void;
    onClose: () => void;
};

const ModalManageDistributionContact: FC<ModalManageDistributionContactProps> = ({active, initContact, onSubmit, onClose}) => {
    const {t: textDistribution} = useTranslation(TranslationPortalFile.DISTRIBUTIONS);
    const FORM_ID = "form-manage-distribution-contact";
    const [contact, setContact] = useState<DistributionContact>(new DistributionContact());

    useEffect(() => {
        if (initContact) {
            setContact(initContact);
        }
    }, [active, initContact]);

    const textContact = (field: string) => {
        return textDistribution(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.CONTACTS}.${field}`);
    };

    const handleChange = (field: DistributionContactField, value: any) => {
        setContact((prevState) => new DistributionContact({...prevState, [field]: value}));
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit(contact);
    };

    return (
        <ModalNew onClose={onClose} active={!!initContact}>
            <ModalHeader>
                <ModalHeaderTitle title={textDistribution("modal.edit_contact")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FieldBlock label={textContact(DistributionContactField.TYPE)}>
                            <RadioButtons
                                id={DistributionContactField.TYPE}
                                value={contact.type}
                                options={DISTRIBUTION_CONTACT_TYPES.map((it) => ({label: textContact(`type_options.${it}`), value: it}))}
                                onChange={(value) => handleChange(DistributionContactField.TYPE, value)}
                            />
                        </FieldBlock>
                        <FormLayoutColumns>
                            <FieldBlock label={textContact(DistributionContactField.FIRST_NAME)} required>
                                <InputText
                                    value={contact.first_name}
                                    onChange={(value) => handleChange(DistributionContactField.FIRST_NAME, value)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textContact(DistributionContactField.LAST_NAME)} required>
                                <InputText
                                    value={contact.last_name}
                                    onChange={(value) => handleChange(DistributionContactField.LAST_NAME, value)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FormLayoutColumns>
                            <FieldBlock label={textContact(DistributionContactField.EMAIL)}>
                                <InputEmail
                                    value={contact.email}
                                    onChange={(value) => handleChange(DistributionContactField.EMAIL, value)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textContact(DistributionContactField.PHONE)}>
                                <InputPhone
                                    value={contact.phone}
                                    onChange={(value) => handleChange(DistributionContactField.PHONE, value)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalManageDistributionContact;
