import {
    Action,
    AlertSeverity,
    Box,
    BoxProps,
    ButtonLink,
    ContentBlock,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContentDirection,
    FlexContentSpacing,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    FormValidationType,
    InputAutocomplete,
    InputDate,
    InputDateType,
    InputText,
    LayoutColumns,
    LayoutRows,
    Loadable,
    RadioButtons,
    Tabs,
    Textarea,
    ToggleSwitch,
    TranslationLibFile
} from "@sirdata/ui-lib";
import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {DataSelection} from "../../api/model/audience/data-selection/DataSelection";
import {DataSelectionContent} from "../../api/model/audience/data-selection/DataSelectionContent";
import {DataSelectionContentField} from "../../api/model/audience/data-selection/DataSelectionContentField";
import {DATA_SELECTION_THEMES} from "../../api/model/audience/data-selection/DataSelectionTheme";
import {PortalContentField} from "../../api/model/portal-content/PortalContentField";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {MainContentHeader, MainContentHeaderAction, MediaPicker, SelectStatus, TagWithAction} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {slugify} from "../../common/utils/string";
import {Module} from "../../utils/Module";
import {Status} from "../../utils/Status";
import {FormLayoutMessage, MainHeader} from "../../common/component/snippet";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import {Locale} from "../../common/utils/Locale";
import {detectChanges} from "../../common/utils/portal";
import {PORTAL_CONTENT_STATUSES} from "../../api/model/portal-content/PortalContentStatus";
import useAlert from "../../utils/hooks/useAlert";
import {Formatter} from "../../common/utils/Formatter";
import {MediaType} from "../../api/model/media/MediaType";
import {TranslationCommonFile} from "../../common/utils/constants";
import useFormValidator from "../../utils/hooks/useFormValidator";
import {PortalSetting} from "../../api/model/portal-setting/PortalSetting";
import {PortalSettingSearchQuery} from "../../api/model/portal-setting/PortalSettingSearchQuery";
import {ApiService} from "../../api/model/ApiService";
import {PortalSettingContentPropertiesField} from "../../api/model/portal-setting/PortalSettingContentPropertiesField";
import {PortalSettingContentField} from "../../api/model/portal-setting/PortalSettingContentField";
import {PortalSettingContentProperties} from "../../api/model/portal-setting/PortalSettingContentProperties";
import {PortalSettingContent} from "../../api/model/portal-setting/PortalSettingContent";

function AudienceDataSelectionsDetails() {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const {t: textPortalContent} = useTranslation(TranslationPortalFile.PORTAL_CONTENT);
    const {t: textAudienceDataSelections} = useTranslation(TranslationPortalFile.AUDIENCE_DATA_SELECTIONS);
    const {t: textTaxonomyTags} = useTranslation(TranslationPortalFile.TAXONOMY_TAGS);
    const {id} = useParams() as { id: string };
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);
    const [isActiveDelete, setActiveDelete] = useState(false);
    const alert = useAlert();

    const FORM_ID = "form-edit-data-selection";
    const [dataSelection, setDataSelection] = useState<DataSelection>(new DataSelection());
    const [initDataSelection, setInitDataSelection] = useState<DataSelection>(new DataSelection());
    const [tags, setTags] = useState<string[]>([]);
    const [portalSetting, setPortalSetting] = useState<PortalSetting>(new PortalSetting());
    const [initPortalSetting, setInitPortalSetting] = useState<PortalSetting>(new PortalSetting());
    const {setErrors, setShowErrors, ...formValidator} = useFormValidator<DataSelectionContentField>();

    const loadTags = useCallback(async () => {
        try {
            setTags(await session.restList.getTaxonomyTags());
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("tags", e.message);
            }
        }
    }, [alert]);

    useEffect(() => {
        (async () => {
            try {
                const dataSelection = await session.restPortalContent.get(DataSelection, +id);
                if (dataSelection.status === Status.DELETED.name) {
                    session.emit(SirdataApiEvent.eventNotFound);
                }

                const newDataSelection = new DataSelection({
                    ...dataSelection,
                    [PortalContentField.START_DATE]: dataSelection.start_date ? Formatter.convertUTCToDate(dataSelection.start_date).format(Formatter.API_DATETIME_FORMAT) : "",
                    [PortalContentField.END_DATE]: dataSelection.end_date ? Formatter.convertUTCToDate(dataSelection.end_date).format(Formatter.API_DATETIME_FORMAT) : ""
                });
                setDataSelection(newDataSelection);
                setInitDataSelection(new DataSelection(newDataSelection));

                const portalSettings = await session.restPortalContent.search(PortalSetting, new PortalSettingSearchQuery().forApiService(ApiService.AUDIENCE));
                if (portalSettings.elements.length) {
                    const portalSetting = portalSettings.elements[0];
                    setPortalSetting(portalSetting);
                    setInitPortalSetting(new PortalSetting(portalSetting));
                }
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("data selection", e.message);
                    }
                }
            } finally {
                setLoading(false);
                await loadTags();
            }
        })();
    }, [id, alert, loadTags]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(dataSelection, initDataSelection) || detectChanges(portalSetting, initPortalSetting));
    }, [dataSelection, initDataSelection, portalSetting, initPortalSetting]);

    useEffect(() => {
        setErrors((prevState) => ({
            ...prevState,
            [DataSelectionContentField.MAIN_IMAGE_ID]: !dataSelection.content.main_image_id,
            [DataSelectionContentField.PREVIEW_IMAGE_ID]: !dataSelection.content.preview_image_id
        }));
    }, [setErrors, dataSelection]);

    const handleChange = (field: PortalContentField, value: any) => {
        setDataSelection((prevState) => new DataSelection({...prevState, [field]: value}));
    };

    const handleChangeContent = (field: DataSelectionContentField, value: any) => {
        handleChange(PortalContentField.CONTENT, new DataSelectionContent({...dataSelection.content, [field]: value}));
    };

    const handlePinDataSelection = (pinned: boolean) => {
        setPortalSetting((prevState) => new PortalSetting({
            ...prevState,
            [PortalContentField.CONTENT]: new PortalSettingContent({
                ...portalSetting.content,
                [PortalSettingContentField.PROPERTIES]: new PortalSettingContentProperties({
                    ...portalSetting.content.properties,
                    [PortalSettingContentPropertiesField.DATA_SELECTION_ID]: pinned ? dataSelection.id : undefined
                })
            })
        }));
    };

    const handleSaveCapture = () => {
        setShowErrors(true);
    };

    const handleSave = async (e: FormEvent) => {
        e.preventDefault();
        if (formValidator.hasErrors()) {
            return;
        }
        try {
            const newDataSelection = new DataSelection({
                ...dataSelection,
                [PortalContentField.START_DATE]: Formatter.convertDateToUTC(dataSelection.start_date),
                [PortalContentField.END_DATE]: dataSelection.end_date ? Formatter.convertDateToUTC(dataSelection.end_date) : undefined
            });
            const updatedDataSelection = await session.restPortalContent.update(DataSelection, newDataSelection);
            updatedDataSelection.start_date = updatedDataSelection.start_date ? Formatter.convertUTCToDate(updatedDataSelection.start_date).format(Formatter.API_DATETIME_FORMAT) : "";
            updatedDataSelection.end_date = updatedDataSelection.end_date ? Formatter.convertUTCToDate(updatedDataSelection.end_date).format(Formatter.API_DATETIME_FORMAT) : "";
            setDataSelection(updatedDataSelection);
            setInitDataSelection(new DataSelection(updatedDataSelection));

            const updatedPortalSetting = await session.restPortalContent.update(PortalSetting, portalSetting);
            setPortalSetting(updatedPortalSetting);
            setInitPortalSetting(new PortalSetting(updatedPortalSetting));
            alert.updateWithSuccess("data selection");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("data selection", e.message);
            }
        } finally {
            setShowErrors(false);
        }
    };

    const handleDelete = async () => {
        if (!isActiveDelete) return;
        try {
            await session.restPortalContent.delete(dataSelection.id);
            navigate(Module.AUDIENCE_DATA_SELECTIONS.path);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("data selection", e.message);
            }
        } finally {
            setActiveDelete(false);
        }
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.AUDIENCE_DATA_SELECTIONS} element={initDataSelection.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <RestrictedContent allowedTo={Authorization.AUDIENCE_DATA_SELECTIONS.update}>
                    <MainContentHeaderAction action={Action.SAVE} form={FORM_ID} disabled={!isUnsavedChanges}/>
                    <MainContentHeaderAction action={Action.DELETE} onClick={() => setActiveDelete(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <Form id={FORM_ID} onSubmitCapture={handleSaveCapture} onSubmit={handleSave} validationType={FormValidationType.CUSTOM}>
                    <LayoutRows>
                        <LayoutColumns>
                            <Loadable loading={isLoading}>
                                <ContentBlock header={{title: {label: textAudienceDataSelections("section.information")}}}>
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                        <FormLayoutRows>
                                            <FormLayoutColumns columns={4}>
                                                <FieldBlock label={textPortalContent(`field.${PortalContentField.STATUS}`)}>
                                                    <SelectStatus
                                                        value={dataSelection.status}
                                                        statuses={PORTAL_CONTENT_STATUSES.filter((it) => it.name !== Status.DELETED.name)}
                                                        onChange={(status) => handleChange(PortalContentField.STATUS, status!.name)}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FormLayoutColumns>
                                                <FieldBlock label={textPortalContent(`field.${PortalContentField.TITLE}`)} required>
                                                    <InputText
                                                        value={dataSelection.title}
                                                        onChange={(value) => handleChange(PortalContentField.TITLE, value)}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textPortalContent(`field.${PortalContentField.STRING_IDENTIFIER}`)} required>
                                                    <InputText
                                                        value={dataSelection.string_identifier}
                                                        onChange={(value) => handleChange(PortalContentField.STRING_IDENTIFIER, slugify(value))}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FormLayoutColumns>
                                                <FieldBlock label={textPortalContent(`field.${PortalContentField.START_DATE}`)} required>
                                                    <InputDate
                                                        type={InputDateType.DATETIME_LOCAL}
                                                        value={dataSelection.start_date}
                                                        onChange={(value) => handleChange(PortalContentField.START_DATE, value)}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textPortalContent(`field.${PortalContentField.END_DATE}`)}>
                                                    <InputDate
                                                        type={InputDateType.DATETIME_LOCAL}
                                                        value={dataSelection.end_date}
                                                        onChange={(value) => handleChange(PortalContentField.END_DATE, value)}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FieldBlock label={textAudienceDataSelections(`field.${DataSelectionContentField.THEME}`)} required>
                                                <RadioButtons
                                                    id={DataSelectionContentField.THEME}
                                                    value={dataSelection.content.theme}
                                                    options={DATA_SELECTION_THEMES.map((it) => ({value: it, label: textAudienceDataSelections(`theme.${it}`)}))}
                                                    onChange={(value) => handleChangeContent(DataSelectionContentField.THEME, value)}
                                                />
                                            </FieldBlock>
                                            <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                                <FieldBlock
                                                    label={textAudienceDataSelections(`field.${DataSelectionContentField.TAGS}`)}
                                                    actions={(
                                                        dataSelection.content.tags.length &&
                                                        <ButtonLink onClick={() => handleChangeContent(DataSelectionContentField.TAGS, [])}>
                                                            {textCommon(Action.REMOVE_ALL.labelKey)}
                                                        </ButtonLink>
                                                    )}
                                                >
                                                    <InputAutocomplete
                                                        placeholder={textTaxonomyTags("search.placeholder")}
                                                        options={tags.filter((tag) => !dataSelection.content.tags.includes(tag)).map((tag) => ({label: tag, value: tag}))}
                                                        onChange={(option) => handleChangeContent(DataSelectionContentField.TAGS, [...dataSelection.content.tags, option?.value as string])}
                                                    />
                                                </FieldBlock>
                                                {!!dataSelection.content.tags.length &&
                                                    <ElementList size={ElementListSize.SMALL} inline>
                                                        {dataSelection.content.tags.map((tag) =>
                                                            <TagWithAction
                                                                key={tag}
                                                                value={tag}
                                                                onDelete={() => handleChangeContent(DataSelectionContentField.TAGS, dataSelection.content.tags.filter((it) => it !== tag))}
                                                                active={true}
                                                            />
                                                        )}
                                                    </ElementList>
                                                }
                                            </FormLayoutRows>
                                            <ToggleSwitch
                                                label={textAudienceDataSelections("field.pin_data_selection")}
                                                name={PortalSettingContentPropertiesField.DATA_SELECTION_ID}
                                                checked={portalSetting.content.properties.data_selection_id === dataSelection.id}
                                                onChange={(value) => handlePinDataSelection(value)}
                                            />
                                        </FormLayoutRows>
                                    </Box>
                                </ContentBlock>
                            </Loadable>
                            <Loadable loading={isLoading}>
                                <ContentBlock header={{title: {label: textAudienceDataSelections("section.content")}}}>
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                        <FormLayoutRows>
                                            <FormLayoutColumns>
                                                <FieldBlock
                                                    label={textAudienceDataSelections(`field.${DataSelectionContentField.MAIN_IMAGE_ID}`)}
                                                    tooltip={textAudienceDataSelections(`tooltip.${DataSelectionContentField.MAIN_IMAGE_ID}`)}
                                                    content={{direction: FlexContentDirection.COLUMN}}
                                                    required
                                                >
                                                    <MediaPicker
                                                        allowedType={MediaType.IMAGE}
                                                        mediaId={dataSelection.content.main_image_id}
                                                        onSelect={(media) => handleChangeContent(DataSelectionContentField.MAIN_IMAGE_ID, media.id)}
                                                        onRemove={() => handleChangeContent(DataSelectionContentField.MAIN_IMAGE_ID, undefined)}
                                                    />
                                                    {formValidator.isError(DataSelectionContentField.MAIN_IMAGE_ID) &&
                                                        <FormLayoutMessage message={t("message.error.field_required")} severity={AlertSeverity.DANGER} small/>
                                                    }
                                                </FieldBlock>
                                                <FieldBlock
                                                    label={textAudienceDataSelections(`field.${DataSelectionContentField.PREVIEW_IMAGE_ID}`)}
                                                    tooltip={textAudienceDataSelections(`tooltip.${DataSelectionContentField.PREVIEW_IMAGE_ID}`)}
                                                    content={{direction: FlexContentDirection.COLUMN}}
                                                    required
                                                >
                                                    <MediaPicker
                                                        allowedType={MediaType.IMAGE}
                                                        mediaId={dataSelection.content.preview_image_id}
                                                        onSelect={(media) => handleChangeContent(DataSelectionContentField.PREVIEW_IMAGE_ID, media.id)}
                                                        onRemove={() => handleChangeContent(DataSelectionContentField.PREVIEW_IMAGE_ID, undefined)}
                                                    />
                                                    {formValidator.isError(DataSelectionContentField.PREVIEW_IMAGE_ID) &&
                                                        <FormLayoutMessage message={t("message.error.field_required")} severity={AlertSeverity.DANGER} small/>
                                                    }
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FormLayoutSeparator/>
                                            <Tabs headers={Locale.values().map((locale) => ({label: textMain(`language.${locale}`)}))}>
                                                <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                                                    <FieldBlock label={textAudienceDataSelections("field.name")} required>
                                                        <InputText
                                                            value={dataSelection.content.name_fr}
                                                            onChange={(value) => handleChangeContent(DataSelectionContentField.NAME_FRENCH, value)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textAudienceDataSelections("field.description")}>
                                                        <Textarea
                                                            value={dataSelection.content.description_fr}
                                                            rows={5}
                                                            onChange={(value) => handleChangeContent(DataSelectionContentField.DESCRIPTION_FRENCH, value)}
                                                            isExpandable
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutRows>
                                                <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                                                    <FieldBlock label={textAudienceDataSelections("field.name")} required>
                                                        <InputText
                                                            value={dataSelection.content.name_en}
                                                            onChange={(value) => handleChangeContent(DataSelectionContentField.NAME_ENGLISH, value)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textAudienceDataSelections("field.description")}>
                                                        <Textarea
                                                            value={dataSelection.content.description_en}
                                                            rows={5}
                                                            onChange={(value) => handleChangeContent(DataSelectionContentField.DESCRIPTION_ENGLISH, value)}
                                                            isExpandable
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutRows>
                                            </Tabs>
                                        </FormLayoutRows>
                                    </Box>
                                </ContentBlock>
                            </Loadable>
                        </LayoutColumns>
                    </LayoutRows>
                </Form>
                <ModalConfirmDelete
                    active={isActiveDelete}
                    entity="data selection"
                    confirm={handleDelete}
                    cancel={() => setActiveDelete(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default AudienceDataSelectionsDetails;
