import {Box, BoxProps, ContentBlock, FieldBlock, FlexContentLayout, FormLayoutColumns, FormLayoutRows, InputNumber, InputText, Select} from "@sirdata/ui-lib";
import {FC} from "react";
import {useTranslation} from "react-i18next";
import {SgtmContainer} from "../../../api/model/sgtm/container/SgtmContainer";
import {SgtmContainerField} from "../../../api/model/sgtm/container/SgtmContainerField";
import {TranslationPortalFile} from "../../../utils/constants";
import {Status} from "../../../utils/Status";
import {SelectPartner, SelectStatus} from "../index";
import {SgtmContainerDeploymentDestination} from "../../../api/model/sgtm/container/SgtmContainerDeploymentDestination";

type SgtmContainerInformationProps = {
    container: SgtmContainer;
    onChange: (field: SgtmContainerField, value: any) => void;
};

const SgtmContainerInformation: FC<SgtmContainerInformationProps> = ({container, onChange}) => {
    const {t: textSgtmContainers} = useTranslation(TranslationPortalFile.SGTM_CONTAINERS);

    return (
        <ContentBlock header={{title: {label: textSgtmContainers("section.information")}}}>
            <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                <FormLayoutRows>
                    <FormLayoutColumns columns={4}>
                        <FieldBlock label={textSgtmContainers(`field.${SgtmContainerField.STATUS}`)}>
                            <SelectStatus
                                value={container.status}
                                statuses={SgtmContainer.statuses().filter((it) => it !== Status.DELETED)}
                                onChange={(status) => onChange(SgtmContainerField.STATUS, status?.name)}
                            />
                        </FieldBlock>
                    </FormLayoutColumns>
                    <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_RIGHT}>
                        <FieldBlock label={textSgtmContainers(`field.${SgtmContainerField.ID}`)} required>
                            <InputNumber
                                value={container.id}
                                onChange={() => {}}
                                disabled
                            />
                        </FieldBlock>
                        <FieldBlock label={textSgtmContainers(`field.${SgtmContainerField.NAME}`)} required>
                            <InputText
                                value={container.name}
                                onChange={(value) => onChange(SgtmContainerField.NAME, value)}
                            />
                        </FieldBlock>
                    </FormLayoutColumns>
                    <FieldBlock label={textSgtmContainers(`field.${SgtmContainerField.CONTAINER_CONFIG_ID}`)} required>
                        <InputText
                            value={container.container_config_id}
                            onChange={(value) => onChange(SgtmContainerField.CONTAINER_CONFIG_ID, value)}
                            placeholder={textSgtmContainers(`placeholder.${SgtmContainerField.CONTAINER_CONFIG_ID}`)}
                        />
                    </FieldBlock>
                    <FieldBlock label={textSgtmContainers(`field.${SgtmContainerField.DEPLOYMENT_DESTINATION}`)}>
                        <Select
                            value={container.deployment_destination}
                            options={Object.values(SgtmContainerDeploymentDestination).map((it) => ({value: it, label: it}))}
                            onChange={(option) => onChange(SgtmContainerField.DEPLOYMENT_DESTINATION, option?.value)}
                            disabled
                        />
                    </FieldBlock>
                    <FieldBlock label={textSgtmContainers(`field.${SgtmContainerField.PARTNER_ID}`)} required>
                        <SelectPartner
                            value={container.partner_id}
                            onChange={undefined}
                            disabled
                        />
                    </FieldBlock>
                </FormLayoutRows>
            </Box>
        </ContentBlock>
    );
};

export default SgtmContainerInformation;
