import {ButtonSave, FieldBlock, Form, FormLayoutColumns, FormLayoutRows, FormLayoutSeparator, FormLayoutTitle, FormValidationType, InputText, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, Select, SelectAutocomplete} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {PartnerOrganization} from "../../../api/model/partner/organization/PartnerOrganization";
import {PartnerOrganizationField} from "../../../api/model/partner/organization/PartnerOrganizationField";
import {RevenuePlatform} from "../../../api/model/revenue/RevenuePlatform";
import {RevenueSeat} from "../../../api/model/revenue/seat/RevenueSeat";
import {RevenueSeatField} from "../../../api/model/revenue/seat/RevenueSeatField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {sortItems} from "../../../common/utils/helper";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {SelectUser} from "../../snippet";
import {useNavigate} from "react-router-dom";
import {Module} from "../../../utils/Module";
import {RevenueSeatSearchQuery} from "../../../api/model/revenue/seat/RevenueSeatSearchQuery";

type ModalCreateSeatProps = {
    active: boolean;
    organization?: PartnerOrganization;
    onClose: (refresh: boolean) => void;
};

const ModalCreateSeat: FunctionComponent<ModalCreateSeatProps> = ({active, organization, onClose}) => {
    const alert = useAlert();
    const navigate = useNavigate();
    const {t: textOrganizations} = useTranslation(TranslationPortalFile.ORGANIZATIONS);
    const {t: textSeats} = useTranslation(TranslationPortalFile.SEATS);
    const [seat, setSeat] = useState<RevenueSeat>(new RevenueSeat());
    const [isLoading, setLoading] = useState(true);
    const [advertisers, setAdvertisers] = useState<string[]>([]);
    const [brands, setBrands] = useState<string[]>([]);
    const [clientSides, setClientSides] = useState<string[]>([]);
    const [countries, setCountries] = useState<string[]>([]);
    const [platforms, setPlatforms] = useState<RevenuePlatform[]>([]);
    const [organizations, setOrganizations] = useState<PartnerOrganization[]>([]);
    const [currentOrganizations, setCurrentOrganizations] = useState<PartnerOrganization[]>([]);
    const FORM_ID = "form-create-seat";

    useEffect(() => {
        if (active) {
            const newSeat = new RevenueSeat();
            if (organization) {
                newSeat.organization_id = organization.id;
                newSeat.organization = organization;
                newSeat.owner_id = organization.owner_id;
            } else {
                (async () => {
                    try {
                        newSeat.owner_id = session.userId;
                    } catch (e) {
                        if (e instanceof ErrorResponse) {
                            alert.failToLoad("user", e.message);
                        }
                    }
                })();
            }
            setSeat(newSeat);
        }
    }, [active, organization, platforms, clientSides, alert]);

    useEffect(() => {
        (async () => {
            try {
                let organizations = await session.restPartnerOrganization.list();
                organizations = sortItems(organizations, PartnerOrganizationField.NAME);
                setOrganizations(organizations);
                setCurrentOrganizations([...organizations]);

                let platforms = await session.restRevenuesImport.getPlatforms();
                platforms = platforms.filter((platform) => !platform.isCurator()); // remove curator platforms
                setPlatforms(platforms);

                const clientSides = await session.restSeat.getClientSides();
                setClientSides(clientSides);

                setCountries(await session.restSeat.getCountries());
                setAdvertisers(await session.restSeat.getAdvertisers());
                setBrands(await session.restSeat.getBrands());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("seat", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert]);

    useEffect(() => {
        const organization = organizations.find((it) => it.id === seat.organization_id);
        handleChange(RevenueSeatField.ORGANIZATION, organization);
        handleChange(RevenueSeatField.OWNER_ID, organization?.owner_id);
    }, [seat.organization_id, organizations]);

    const handleChange = (field: RevenueSeatField, value: any) => {
        setSeat((prevState) => new RevenueSeat({...prevState, [field]: value}));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            const newSeat = await session.restSeat.create(seat);
            if (organization) {
                alert.createWithSuccess("seat");
                onClose(true);
            } else {
                navigate(newSeat.getRoute());
            }
        } catch (e) {
            if (e instanceof ErrorResponse) {
                const newSearchQuery = new RevenueSeatSearchQuery();
                newSearchQuery.query = seat.seat_id;
                newSearchQuery.platform = seat.platform;
                const newSearchResult = await session.restSeat.search(newSearchQuery);
                const existingSeat = newSearchResult.seats.find((it) => it.seat_id === seat.seat_id);
                if (existingSeat) {
                    alert.failToCreate("seat", textSeats("seat_already_exists", {
                        link: Module.SEATS.buildRoute(existingSeat.id),
                        seatId: existingSeat.id
                    }));
                } else {
                    alert.failToCreate("seat", e.message);
                }
            }
        }
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={active} loading={isLoading}>
            <ModalHeader>
                <ModalHeaderTitle title={textSeats("modal.title")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutTitle>{textSeats("modal.subtitle.organization")}</FormLayoutTitle>
                        <FormLayoutColumns columns={2}>
                            <FieldBlock
                                label={textSeats(`field.${RevenueSeatField.ORGANIZATION}`)}
                                required
                            >
                                <SelectAutocomplete
                                    value={seat.organization_id}
                                    options={currentOrganizations.map((it) => ({value: it.id, label: it.name}))}
                                    onChange={(option) => handleChange(RevenueSeatField.ORGANIZATION_ID, option ? +option?.value : 0)}
                                    disabled={!!organization}
                                    clearable
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        {seat.organization &&
                            <FormLayoutColumns>
                                {seat.organization.group ?
                                    <FieldBlock label={textSeats(`field.${RevenueSeatField.ORGANIZATION_GROUP}`)}>
                                        <InputText
                                            value={seat.organization.group.name}
                                            onChange={() => {}}
                                            disabled
                                        />
                                    </FieldBlock> : undefined
                                }
                                {seat.organization.type &&
                                    <FieldBlock label={textSeats(`field.${RevenueSeatField.ORGANIZATION_TYPE}`)}>
                                        <InputText
                                            value={textOrganizations(`type.${seat.organization.type}`)}
                                            onChange={() => {}}
                                            disabled
                                        />
                                    </FieldBlock>
                                }
                                {seat.organization.owner &&
                                    <FieldBlock label={textSeats(`field.${RevenueSeatField.ORGANIZATION_OWNER}`)}>
                                        <InputText
                                            value={seat.organization.owner.name}
                                            onChange={() => {}}
                                            disabled
                                        />
                                    </FieldBlock>
                                }
                            </FormLayoutColumns>
                        }
                        <FormLayoutSeparator/>
                        <FormLayoutTitle>{textSeats("modal.subtitle.platform")}</FormLayoutTitle>
                        <FormLayoutColumns columns={2}>
                            <FieldBlock
                                label={textSeats(`field.${RevenueSeatField.PLATFORM}`)}
                                required
                            >
                                <SelectAutocomplete
                                    value={seat.platform}
                                    options={platforms.map((it) => ({value: it.name, label: it.name}))}
                                    onChange={(option) => handleChange(RevenueSeatField.PLATFORM, `${option?.value || ""}`)}
                                    clearable
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FormLayoutColumns>
                            <FieldBlock
                                label={textSeats(`field.${RevenueSeatField.CLIENT_SIDE}`)}
                                required
                            >
                                <Select
                                    value={seat.client_side}
                                    options={clientSides.map((it) => ({value: it, label: it}))}
                                    onChange={(option) => handleChange(RevenueSeatField.CLIENT_SIDE, `${option?.value || ""}`)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textSeats(`field.${RevenueSeatField.SEAT_ID}`)}>
                                <InputText
                                    value={seat.seat_id}
                                    onChange={(value) => handleChange(RevenueSeatField.SEAT_ID, value.trim())}
                                />
                            </FieldBlock>
                            <FieldBlock label={textSeats(`field.${RevenueSeatField.COUNTRY}`)}>
                                <SelectAutocomplete
                                    value={seat.country}
                                    options={countries.map((it) => ({value: it, label: it}))}
                                    onChange={(option) => handleChange(RevenueSeatField.COUNTRY, `${option?.value || ""}`)}
                                    clearable
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FormLayoutSeparator/>
                        <FormLayoutTitle>{textSeats("modal.subtitle.advertiser")}</FormLayoutTitle>
                        <FormLayoutColumns columns={2}>
                            <FieldBlock label={textSeats(`field.${RevenueSeatField.ADVERTISER}`)}>
                                <SelectAutocomplete
                                    value={seat.advertiser}
                                    options={advertisers.map((it) => ({label: it, value: it}))}
                                    onChange={(option) => handleChange(RevenueSeatField.ADVERTISER, option?.value)}
                                    clearable
                                    creatable
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FormLayoutColumns>
                            <FieldBlock label={textSeats(`field.${RevenueSeatField.ADVERTISER_ID}`)}>
                                <InputText
                                    value={seat.advertiser_id}
                                    onChange={(value) => handleChange(RevenueSeatField.ADVERTISER_ID, value)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textSeats(`field.${RevenueSeatField.BRAND}`)}>
                                <SelectAutocomplete
                                    value={seat.brand}
                                    options={brands.map((it) => ({label: it, value: it}))}
                                    onChange={(option) => handleChange(RevenueSeatField.BRAND, option?.value)}
                                    clearable
                                    creatable
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FormLayoutSeparator/>
                        <FormLayoutTitle>{textSeats("modal.subtitle.sales")}</FormLayoutTitle>
                        <FieldBlock
                            label={textSeats(`field.${RevenueSeatField.OWNER_ID}`)}
                            required
                        >
                            <SelectUser
                                value={seat.owner_id}
                                onChange={(user) => handleChange(RevenueSeatField.OWNER_ID, user?.id || 0)}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonSave form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreateSeat;
