import {Action, Box, BoxProps, Checkbox, ContentBlock, FieldBlock, FlexContentDirection, FlexContentLayout, FlexContentSpacing, Form, FormLayoutColumns, FormLayoutRows, FormLayoutSeparator, FormValidationType, IconTooltip, InputNumber, InputText, InputUrl, LayoutColumns, LayoutRows, Loadable, RadioButtons, Select, ToggleSwitch} from "@sirdata/ui-lib";
import {FormEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import "react-phone-number-input/style.css";
import {useNavigate, useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {Currency} from "../../api/model/currency/Currency";
import {BanProperty} from "../../api/model/partner/BanProperty";
import {BanPropertyField} from "../../api/model/partner/BanPropertyField";
import {PartnerOrganization} from "../../api/model/partner/organization/PartnerOrganization";
import {Partner} from "../../api/model/partner/Partner";
import {PartnerBillingInfo} from "../../api/model/partner/PartnerBillingInfo";
import {PartnerBillingInfoField} from "../../api/model/partner/PartnerBillingInfoField";
import {PartnerBillingPaymentMethod} from "../../api/model/partner/PartnerBillingPaymentMethod";
import {PartnerBillingPaymentType} from "../../api/model/partner/PartnerBillingPaymentType";
import {PartnerBillingVatType} from "../../api/model/partner/PartnerBillingVatType";
import {PARTNER_CONSENT_STATUSES} from "../../api/model/partner/PartnerConsentStatus";
import {PartnerField} from "../../api/model/partner/PartnerField";
import {PartnerProperties} from "../../api/model/partner/PartnerProperties";
import {PartnerPropertiesField} from "../../api/model/partner/PartnerPropertiesField";
import {PARTNER_TYPES, PartnerType} from "../../api/model/partner/PartnerType";
import {UserField} from "../../api/model/user/UserField";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {COUNTRY_ISO_CODES_EU, CountryIsoCode} from "../../common/api/model/CountryIsoCode";
import {Country} from "../../common/api/model/Country";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {TranslationCommonFile} from "../../common/utils/constants";
import {detectChanges} from "../../common/utils/portal";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import ModalEditPartnerPricingLinks from "../../component/modal/partners/ModalEditPartnerPricingLinks";
import {MainContentHeader, MainContentHeaderAction, MainContentHeaderActionsMenu, PartnerAuthorizations, PartnerLicensesLinks, PartnerLinkedAccounts, PartnerSites, SelectStatus, SelectUser} from "../../component/snippet";
import LabelService from "../../component/snippet/label/LabelService";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import {Module} from "../../utils/Module";
import {Status} from "../../utils/Status";
import {PARTNER_CONNECT_ORIGINS} from "./PartnersConnect";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";

function PartnersDetails() {
    const {t: textPartners} = useTranslation(TranslationPortalFile.PARTNERS);
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const alert = useAlert();
    const navigate = useNavigate();
    const {id} = useParams() as {id: string};
    const [isLoading, setLoading] = useState(true);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);
    const [isShowModalPartnerPricingLinks, setShowModalPartnerPricingLinks] = useState(false);
    const [isShowModalConfirmDelete, setShowModalConfirmDelete] = useState(false);

    const FORM_ID = "form-edit-partner";
    const [partner, setPartner] = useState<Partner>(new Partner());
    const [initPartner, setInitPartner] = useState<Partner>(new Partner());
    const [billingInfo, setBillingInfo] = useState<PartnerBillingInfo>(new PartnerBillingInfo({partner_id: +id}));
    const [initBillingInfo, setInitBillingInfo] = useState<PartnerBillingInfo>(new PartnerBillingInfo({partner_id: +id}));
    const [countries, setCountries] = useState<Country[]>([]);

    useEffect(() => {
        (async () => {
            try {
                const countries = await session.restPartner.getCountries();
                setCountries(countries);

                const partner = await session.restPartner.get(+id);
                setPartner(partner);
                setInitPartner(new Partner(partner));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("partner", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }

            const account = await session.getAccount();
            if (account.hasAuthorization(Authorization.BILLINGS.name)) {
                try {
                    const billing = await session.restPartnerBillingInfo.get(+id);
                    setBillingInfo(billing);
                    setInitBillingInfo(new PartnerBillingInfo(billing));
                } catch (e) {
                    if (e instanceof ErrorResponse) {
                        if (e.statusCode !== HttpStatusCode.NOT_FOUND) {
                            alert.failToLoad("billing info", e.message);
                            return;
                        }
                    }
                }
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(partner, initPartner) || detectChanges(billingInfo, initBillingInfo));
    }, [partner, initPartner, billingInfo, initBillingInfo]);

    useEffect(() => {
        let vatType: PartnerBillingVatType;
        switch (true) {
            case billingInfo.country_iso === CountryIsoCode.FRANCE:
                vatType = PartnerBillingVatType.FR;
                break;
            case COUNTRY_ISO_CODES_EU.includes(billingInfo.country_iso):
                vatType = PartnerBillingVatType.EU;
                break;
            default:
                vatType = PartnerBillingVatType.ROW;
        }
        handleChangeBillingInfo(PartnerBillingInfoField.VAT_TYPE, vatType);
    }, [billingInfo.country_iso]);

    const handleChange = (field: PartnerField, value: any) => {
        setPartner((prevState) => new Partner({...prevState, [field]: value}));
    };

    const handleChangeType = (type: PartnerType) => {
        setPartner((prevState) => {
            const newPartner = new Partner({...prevState, [PartnerField.TYPE]: type});
            if (newPartner.type === PartnerType.PUBLISHER) {
                newPartner.data = true;
                newPartner.categorize_request = true;
            }
            return newPartner;
        });
    };

    const handleChangeProperty = (field: PartnerPropertiesField, value: any) => {
        handleChange(PartnerField.PROPERTIES, new PartnerProperties({...partner.properties, [field]: value}));
    };

    const handleChangeBanProperty = (field: BanPropertyField, value: any) => {
        handleChange(PartnerField.BAN_PROPERTY, new BanProperty({...partner.ban_property, [field]: value}));
    };

    const handleChangeBillingInfo = (field: PartnerBillingInfoField, value: any) => {
        setBillingInfo((prevState) => new PartnerBillingInfo({...prevState, [field]: value}));
    };

    const handleSave = async (e: FormEvent) => {
        e.preventDefault();
        try {
            await session.restPartner.updateProperties(partner.id, partner.properties);
            const updatedPartner = await session.restPartner.update(partner);
            setPartner(updatedPartner);
            setInitPartner(new Partner(updatedPartner));
            alert.updateWithSuccess("partner");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("partner", e.message);
            }
        }
        try {
            const account = await session.getAccount();
            if (Authorization.BILLINGS.update && account.hasAuthorization(Authorization.BILLINGS.update)) {
                const updatedBillingInfo = await session.restPartnerBillingInfo.update(billingInfo);
                setBillingInfo(updatedBillingInfo);
                setInitBillingInfo(new PartnerBillingInfo(updatedBillingInfo));
                alert.updateWithSuccess("billing info");
            }
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("billing info", e.message);
            }
        }
    };

    const handleDelete = async () => {
        if (!isShowModalConfirmDelete) return;
        try {
            await session.restPartner.delete(partner.id);
            navigate(Module.PARTNERS.path);
            alert.deleteWithSuccess("partner");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("partner", e.message);
            }
        } finally {
            setShowModalConfirmDelete(false);
        }
    };

    const goToOrganization = () => {
        window.open(new PartnerOrganization({id: partner.organization_id}).getRoute());
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.PARTNERS} element={initPartner.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <RestrictedContent allowedTo={Authorization.PRICINGS.name}>
                    <MainContentHeaderAction action={new Action(textPartners("section.pricing_settings"), {name: "euro"})} onClick={() => setShowModalPartnerPricingLinks(true)}/>
                </RestrictedContent>
                <RestrictedContent allowedTo={Authorization.LOG_AS_PARTNER.name}>
                    <MainContentHeaderActionsMenu
                        action={Action.OPEN}
                        label={"Partner portal"}
                        items={
                            PARTNER_CONNECT_ORIGINS.map((origin) => (
                                {label: textMain(`menu.origins.${origin.name}`), onClick: () => window.open(partner.getPartnerAreaRoute(origin), "_blank")}
                            ))}
                    />
                </RestrictedContent>
                <RestrictedContent allowedTo={Authorization.PARTNERS.update}>
                    <MainContentHeaderAction action={Action.SAVE} form={FORM_ID} disabled={!isUnsavedChanges}/>
                </RestrictedContent>
                <RestrictedContent allowedTo={Authorization.PARTNERS_DELETE.name}>
                    <MainContentHeaderAction action={Action.DELETE} onClick={() => setShowModalConfirmDelete(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <Form id={FORM_ID} onSubmit={handleSave} validationType={FormValidationType.CUSTOM}>
                        <LayoutColumns>
                            <LayoutRows>
                                <Loadable loading={isLoading}>
                                    <ContentBlock header={{title: {label: textPartners("section.information")}}}>
                                        <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                            <FormLayoutRows>
                                                <FormLayoutColumns columns={4}>
                                                    <FieldBlock label={textPartners("field.status")}>
                                                        <SelectStatus
                                                            value={partner.active ? Status.ACTIVE.name : Status.INACTIVE.name}
                                                            statuses={Status.getActiveStatuses()}
                                                            onChange={(status) => handleChange(PartnerField.ACTIVE, status === Status.ACTIVE)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FormLayoutColumns>
                                                    <FieldBlock
                                                        label={textPartners(`field.${PartnerField.COMPANY}`)}
                                                        actions={
                                                            partner.organization_id ? <IconTooltip icon={{...Action.OPEN.icon, onClick: goToOrganization}} text={textPartners("actions.go_to_organization_page")}/> : undefined
                                                        }
                                                    >
                                                        <InputText
                                                            value={partner.company}
                                                            onChange={(value) => handleChange(PartnerField.COMPANY, value)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textPartners(`field.${PartnerField.TYPE}`)}>
                                                        <RadioButtons
                                                            id={PartnerField.TYPE}
                                                            value={partner.type}
                                                            options={PARTNER_TYPES.map((it) => ({value: it, label: it}))}
                                                            onChange={(value) => handleChangeType(value as PartnerType)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FormLayoutColumns>
                                                    <FieldBlock label={textPartners(`field.${PartnerField.COUNTRY_ID}`)}>
                                                        <Select
                                                            value={partner.country_id}
                                                            options={countries.map((it) => ({value: it.id, label: it.name}))}
                                                            onChange={(option) => handleChange(PartnerField.COUNTRY_ID, option ? +option?.value : 0)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textPartners(`field.${PartnerField.CURRENCY_ID}`)}>
                                                        <Select
                                                            value={+partner.currency_id}
                                                            options={Currency.values().map((it) => ({value: it.id, label: `${it.currency} (${it.symbol})`}))}
                                                            onChange={(option) => handleChange(PartnerField.CURRENCY_ID, option?.value)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FieldBlock label={textPartners(`field.${PartnerField.PRIVACY_POLICY_URL}`)}>
                                                    <InputUrl
                                                        value={partner.privacy_policy_url || ""}
                                                        onChange={(value) => handleChange(PartnerField.PRIVACY_POLICY_URL, value)}
                                                    />
                                                </FieldBlock>
                                                <FormLayoutSeparator/>
                                                <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                                                    <FieldBlock label={textPartners(`field.${PartnerField.USER}`)}>
                                                        <SelectUser
                                                            value={partner.user}
                                                            field={UserField.EMAIL}
                                                            onChange={(user) => handleChange(PartnerField.USER, user?.email)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textPartners(`field.${PartnerField.CREATION_SERVICE}`)} content={{noFullWidth: true}}>
                                                        <LabelService service={partner.creation_service}/>
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                            </FormLayoutRows>
                                        </Box>
                                    </ContentBlock>
                                </Loadable>
                                <RestrictedContent allowedTo={Authorization.BILLINGS.name}>
                                    <Loadable loading={isLoading}>
                                        <ContentBlock header={{title: {label: textPartners("section.billing")}}}>
                                            <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                                <FormLayoutRows>
                                                    <FormLayoutColumns>
                                                        <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.COMPANY_NAME}`)}>
                                                            <InputText
                                                                value={billingInfo.company_name}
                                                                onChange={(value) => handleChangeBillingInfo(PartnerBillingInfoField.COMPANY_NAME, value)}
                                                            />
                                                        </FieldBlock>
                                                        <FieldBlock label={textPartners("field.billing_info.contact")}>
                                                            <InputText
                                                                value={billingInfo.contact_firstname}
                                                                placeholder={textPartners(`field.billing_info.${PartnerBillingInfoField.CONTACT_FIRSTNAME}`)}
                                                                onChange={(value) => handleChangeBillingInfo(PartnerBillingInfoField.CONTACT_FIRSTNAME, value)}
                                                            />
                                                            <InputText
                                                                value={billingInfo.contact_lastname}
                                                                placeholder={textPartners(`field.billing_info.${PartnerBillingInfoField.CONTACT_LASTNAME}`)}
                                                                onChange={(value) => handleChangeBillingInfo(PartnerBillingInfoField.CONTACT_LASTNAME, value)}
                                                            />
                                                        </FieldBlock>
                                                    </FormLayoutColumns>
                                                    <FormLayoutColumns>
                                                        <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                                                            <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.ADDRESS}`)}>
                                                                <InputText
                                                                    value={billingInfo.address}
                                                                    onChange={(value) => handleChangeBillingInfo(PartnerBillingInfoField.ADDRESS, value)}
                                                                />
                                                            </FieldBlock>
                                                            <FormLayoutColumns>
                                                                <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.POSTAL_CODE}`)}>
                                                                    <InputText
                                                                        value={billingInfo.postal_code}
                                                                        onChange={(value) => handleChangeBillingInfo(PartnerBillingInfoField.POSTAL_CODE, value)}
                                                                    />
                                                                </FieldBlock>
                                                                <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.CITY}`)}>
                                                                    <InputText
                                                                        value={billingInfo.city}
                                                                        onChange={(value) => handleChangeBillingInfo(PartnerBillingInfoField.CITY, value)}
                                                                    />
                                                                </FieldBlock>
                                                            </FormLayoutColumns>
                                                            <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.COUNTRY_ISO}`)}>
                                                                <Select
                                                                    value={billingInfo.country_iso}
                                                                    options={countries.map((it) => ({value: it.isocode, label: it.name}))}
                                                                    onChange={(option) => handleChangeBillingInfo(PartnerBillingInfoField.COUNTRY_ISO, option?.value)}
                                                                />
                                                            </FieldBlock>
                                                        </FormLayoutRows>
                                                        <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                                                            <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.BUSINESS_ID}`)}>
                                                                <InputText
                                                                    value={billingInfo.business_id}
                                                                    onChange={(value) => handleChangeBillingInfo(PartnerBillingInfoField.BUSINESS_ID, value)}
                                                                />
                                                            </FieldBlock>
                                                            <FormLayoutColumns>
                                                                <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.VAT}`)}>
                                                                    <InputText
                                                                        value={billingInfo.vat}
                                                                        onChange={(value) => handleChangeBillingInfo(PartnerBillingInfoField.VAT, value)}
                                                                    />
                                                                </FieldBlock>
                                                                <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.VAT_TYPE}`)}>
                                                                    <Select
                                                                        value={billingInfo.vat_type}
                                                                        options={Object.values(PartnerBillingVatType).map((type) => ({label: type, value: type}))}
                                                                        onChange={undefined}
                                                                        disabled
                                                                    />
                                                                </FieldBlock>
                                                            </FormLayoutColumns>
                                                            <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.AXONAUT_ID}`)}>
                                                                <InputNumber
                                                                    value={billingInfo.axonaut_id || undefined}
                                                                    onChange={(value) => handleChangeBillingInfo(PartnerBillingInfoField.AXONAUT_ID, value)}
                                                                />
                                                            </FieldBlock>
                                                        </FormLayoutRows>
                                                    </FormLayoutColumns>
                                                    <FormLayoutSeparator/>
                                                    <FormLayoutColumns>
                                                        <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.PAYMENT_METHOD}`)}>
                                                            <Select
                                                                value={billingInfo.payment_method}
                                                                options={PartnerBillingPaymentMethod.values().map((method) => ({label: textPartners(`payment_method.${method.name}`), value: method.name}))}
                                                                onChange={(option) => handleChangeBillingInfo(PartnerBillingInfoField.PAYMENT_METHOD, option?.value)}
                                                            />
                                                        </FieldBlock>
                                                        <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.PAYMENT_TYPE}`)}>
                                                            <Select
                                                                value={billingInfo.payment_type}
                                                                options={PartnerBillingPaymentType.values().map((type) => ({label: textPartners(`payment_type.${type.name}`), value: type.name}))}
                                                                onChange={(option) => handleChangeBillingInfo(PartnerBillingInfoField.PAYMENT_TYPE, option?.value)}
                                                            />
                                                        </FieldBlock>
                                                    </FormLayoutColumns>
                                                    <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                                                        <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.IBAN}`)}>
                                                            <InputText
                                                                value={billingInfo.iban}
                                                                onChange={(value) => handleChangeBillingInfo(PartnerBillingInfoField.IBAN, value)}
                                                            />
                                                        </FieldBlock>
                                                        <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.BIC}`)}>
                                                            <InputText
                                                                value={billingInfo.bic}
                                                                onChange={(value) => handleChangeBillingInfo(PartnerBillingInfoField.BIC, value)}
                                                            />
                                                        </FieldBlock>
                                                    </FormLayoutColumns>
                                                    <FieldBlock name={PartnerBillingInfoField.SMALL_BUSINESS} label={textPartners(`field.billing_info.${PartnerBillingInfoField.SMALL_BUSINESS}`)}>
                                                        <ToggleSwitch
                                                            checked={billingInfo.small_business}
                                                            onChange={(value) => handleChangeBillingInfo(PartnerBillingInfoField.SMALL_BUSINESS, value)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutRows>
                                            </Box>
                                        </ContentBlock>
                                    </Loadable>
                                </RestrictedContent>
                            </LayoutRows>
                            <Loadable loading={isLoading}>
                                <ContentBlock header={{title: {label: textPartners("section.settings")}}}>
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                        <FormLayoutRows>
                                            <FieldBlock name={PartnerField.DATA} label={textPartners(`field.${PartnerField.ACTIVE}`)}>
                                                <ToggleSwitch
                                                    checked={partner.data}
                                                    onChange={(value) => handleChange(PartnerField.DATA, value)}
                                                />
                                            </FieldBlock>
                                            <FormLayoutColumns>
                                                <FieldBlock name={PartnerField.CATEGORIZE_REQUEST} label={textPartners(`field.${PartnerField.CATEGORIZE_REQUEST}`)}>
                                                    <ToggleSwitch
                                                        checked={partner.categorize_request}
                                                        onChange={(value) => handleChange(PartnerField.CATEGORIZE_REQUEST, value)}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textPartners(`field.${PartnerField.MAX_SYNC_PER_REQUEST}`)}>
                                                    <InputNumber
                                                        value={partner.max_sync_per_request}
                                                        min={1}
                                                        onChange={(value) => handleChange(PartnerField.MAX_SYNC_PER_REQUEST, value)}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textPartners(`field.${PartnerField.AXCIOM_ID}`)}>
                                                    <InputText
                                                        value={partner.axciom_id || ""}
                                                        onChange={(value) => handleChange(PartnerField.AXCIOM_ID, value)}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FieldBlock label={textPartners("section.disable_sync")} content={{spacing: FlexContentSpacing.SMALL}}>
                                                <ToggleSwitch
                                                    name={BanPropertyField.BAN_HASHMAIL}
                                                    label={textPartners(`field.${PartnerField.BAN_PROPERTY}.${BanPropertyField.BAN_HASHMAIL}`)}
                                                    checked={partner.ban_property.ban_hashmail}
                                                    onChange={(value) => handleChangeBanProperty(BanPropertyField.BAN_HASHMAIL, value)}
                                                />
                                                <ToggleSwitch
                                                    name={BanPropertyField.BAN_AXCIOM}
                                                    label={textPartners(`field.${PartnerField.BAN_PROPERTY}.${BanPropertyField.BAN_AXCIOM}`)}
                                                    checked={partner.ban_property.ban_axciom}
                                                    onChange={(value) => handleChangeBanProperty(BanPropertyField.BAN_AXCIOM, value)}
                                                />
                                                <ToggleSwitch
                                                    name={BanPropertyField.BAN_SMART}
                                                    label={textPartners(`field.${PartnerField.BAN_PROPERTY}.${BanPropertyField.BAN_SMART}`)}
                                                    checked={partner.ban_property.ban_smart}
                                                    onChange={(value) => handleChangeBanProperty(BanPropertyField.BAN_SMART, value)}
                                                />
                                                <ToggleSwitch
                                                    name={BanPropertyField.BAN_MAPPING}
                                                    label={textPartners(`field.${PartnerField.BAN_PROPERTY}.${BanPropertyField.BAN_MAPPING}`)}
                                                    checked={partner.ban_property.ban_mapping}
                                                    onChange={(value) => handleChangeBanProperty(BanPropertyField.BAN_MAPPING, value)}
                                                />
                                            </FieldBlock>
                                            <FormLayoutSeparator/>
                                            <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                                                <FormLayoutRows inline spacing={FlexContentSpacing.SMALL}>
                                                    <FieldBlock name={PartnerField.IS_MERCHANT} label={textPartners(`field.${PartnerField.IS_MERCHANT}`)} content={{direction: FlexContentDirection.ROW}}>
                                                        <ToggleSwitch
                                                            name={PartnerField.IS_MERCHANT}
                                                            checked={partner.is_merchant}
                                                            onChange={(value) => handleChange(PartnerField.IS_MERCHANT, value)}
                                                        />
                                                        <>
                                                            <Checkbox
                                                                name={PartnerField.IS_BRAZIL_MERCHANT}
                                                                checked={partner.is_brazil_merchant}
                                                                label={textPartners(`field.${PartnerField.IS_BRAZIL_MERCHANT}`)}
                                                                onChange={(value) => handleChange(PartnerField.IS_BRAZIL_MERCHANT, value)}
                                                                disabled={!partner.is_merchant}
                                                            />
                                                        </>
                                                    </FieldBlock>
                                                    <FieldBlock name={PartnerField.BRANDED} label={textPartners(`field.${PartnerField.BRANDED}`)}>
                                                        <ToggleSwitch
                                                            checked={partner.branded}
                                                            onChange={(value) => handleChange(PartnerField.BRANDED, value)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutRows>
                                                <FieldBlock label={textPartners(`field.${PartnerField.CONSENT_STATUS}`)}>
                                                    <Select
                                                        value={partner.consent_status}
                                                        options={PARTNER_CONSENT_STATUSES.map((it) => ({value: it, label: it}))}
                                                        onChange={(option) => handleChange(PartnerField.CONSENT_STATUS, option?.value)}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FormLayoutSeparator/>
                                            <FieldBlock name={PartnerField.FORBID_USER_CREATION} label={textPartners(`field.${PartnerField.FORBID_USER_CREATION}`)}>
                                                <ToggleSwitch
                                                    checked={partner.forbid_user_creation}
                                                    onChange={(value) => handleChange(PartnerField.FORBID_USER_CREATION, value)}
                                                />
                                            </FieldBlock>
                                            <FieldBlock label={textPartners(`field.${PartnerField.PROPERTIES}.${PartnerPropertiesField.AWS_CUSTOMER_IDENTIFIER}`)}>
                                                <InputText
                                                    value={partner.properties.aws_customer_identifier}
                                                    onChange={(value) => handleChangeProperty(PartnerPropertiesField.AWS_CUSTOMER_IDENTIFIER, value)}
                                                />
                                            </FieldBlock>
                                        </FormLayoutRows>
                                    </Box>
                                </ContentBlock>
                            </Loadable>
                        </LayoutColumns>
                    </Form>
                    <LayoutColumns>
                        <LayoutRows>
                            <RestrictedContent allowedTo={Authorization.LICENSES.name}>
                                <PartnerLicensesLinks partnerId={partner.id}/>
                            </RestrictedContent>
                            <PartnerSites partnerId={partner.id}/>
                        </LayoutRows>
                        <Loadable loading={isLoading}>
                            <PartnerAuthorizations partnerId={partner.id}/>
                        </Loadable>
                    </LayoutColumns>
                    <PartnerLinkedAccounts partnerId={partner.id}/>
                </LayoutRows>
                <ModalEditPartnerPricingLinks
                    partnerId={isShowModalPartnerPricingLinks ? partner.id : undefined}
                    onSubmit={() => setShowModalPartnerPricingLinks(false)}
                    onClose={() => setShowModalPartnerPricingLinks(false)}
                />
                <ModalConfirmDelete
                    active={isShowModalConfirmDelete}
                    entity="partner"
                    confirm={handleDelete}
                    cancel={() => setShowModalConfirmDelete(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default PartnersDetails;
