import {AlertSeverity, ButtonLinkCancel, ButtonValidate, ContentBlock, FieldBlock, FlexContentDirection, Form, FormLayoutRows, FormValidationType, Loadable, ModalActions, ModalContent, ModalDescription, ModalHeader, ModalHeaderTitle, ModalNew, SvgUpload, Table, TableColumn, TableRow, TagStyle, UploadPanel} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {SegmentLinkAreaCodes} from "../../../api/model/audience/segment/SegmentLinkAreaCodes";
import {SegmentLinkAreaCodesField} from "../../../api/model/audience/segment/SegmentLinkAreaCodesField";
import {SegmentLinkAreaCodeType} from "../../../api/model/audience/segment/SegmentLinkAreaCodeType";
import {HttpContentType} from "../../../common/api/http/HttpContentType";
import {CountryIsoCode} from "../../../common/api/model/CountryIsoCode";
import {FormLayoutMessage} from "../../../common/component/snippet";
import {TranslationPortalFile} from "../../../utils/constants";
import useFormValidator from "../../../utils/hooks/useFormValidator";
import {CountryFlag, Tag} from "../../snippet";

export type ModalImportSegmentLinksAreaCodesProps = {
    active: boolean;
    onSubmit: (links: SegmentLinkAreaCodes[]) => void;
    onClose: () => void;
};

const ModalImportSegmentLinksAreaCodes: FunctionComponent<ModalImportSegmentLinksAreaCodesProps> = ({active, onSubmit, onClose}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textSegments} = useTranslation(TranslationPortalFile.SEGMENTS);
    const [isLoading, setLoading] = useState(false);
    const [fileName, setFileName] = useState<string>("");
    const [links, setLinks] = useState<SegmentLinkAreaCodes[]>([]);
    const FORM_ID = "form-import-segment-links-area-codes";
    const {setErrors, setShowErrors, ...formValidator} = useFormValidator<string>();

    useEffect(() => {
        if (!active) {
            setFileName("");
            setLinks([]);
            setShowErrors(false);
        }
    }, [active, setShowErrors]);

    useEffect(() => {
        setErrors((prevState) => ({
            ...prevState,
            file: !fileName,
            links: !!fileName.length && !links.length
        }));
    }, [setErrors, fileName, links]);

    const handleImportFile = (file?: File) => {
        if (!file) return;
        setLoading(true);
        setShowErrors(true);

        try {
            setFileName(file.name);

            const reader = new FileReader();
            reader.onloadend = (evt) => {
                let lines = evt.target?.result as string;
                if (!lines) {
                    return;
                }

                const importedLinks = [];
                for (const line of lines.split("\n")) {
                    if (!line) break;
                    const [type, country, codes] = line.split(";");
                    switch (true) {
                        case !(type && country && codes):
                        case !SegmentLinkAreaCodeType.isValid(type):
                        case ![CountryIsoCode.FRANCE, CountryIsoCode.BELGIUM].includes(country as CountryIsoCode):
                            break;
                        default:
                            importedLinks.push(new SegmentLinkAreaCodes({
                                [SegmentLinkAreaCodesField.ACTIVE]: true,
                                [SegmentLinkAreaCodesField.ISO_COUNTRY]: country,
                                [SegmentLinkAreaCodesField.TYPE]: type,
                                [SegmentLinkAreaCodesField.CODES]: codes
                            }));
                    }
                }
                setLinks(importedLinks);
            };
            reader.readAsBinaryString(file);
        } catch {

        } finally {
            setLoading(false);
        }
    };

    const handleRemoveFile = () => {
        setFileName("");
        setLinks([]);
        setShowErrors(false);
    };

    const handleSubmitCapture = () => {
        setShowErrors(true);
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (formValidator.hasErrors()) {
            return;
        }
        onSubmit(links);
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textSegments("modal.import_area_codes")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmitCapture={handleSubmitCapture} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <ModalDescription>
                            <span dangerouslySetInnerHTML={{__html: textSegments("message.import_area_codes")}}/>
                        </ModalDescription>
                        <FieldBlock content={{direction: FlexContentDirection.COLUMN}}>
                            <UploadPanel
                                fileName={fileName}
                                image={SvgUpload}
                                onChange={handleImportFile}
                                onRemove={handleRemoveFile}
                                acceptType={[HttpContentType.CSV]}
                            />
                            {formValidator.isError("file") &&
                                <FormLayoutMessage message={t("message.error.file_required")} severity={AlertSeverity.DANGER} small/>
                            }
                            {formValidator.isError("links") &&
                                <FormLayoutMessage message={textSegments("message.no_area_codes_found_in_file")} severity={AlertSeverity.DANGER} small/>
                            }
                        </FieldBlock>
                        <Loadable loading={isLoading}>
                            {!!links.length &&
                                <ContentBlock header={{title: {label: textSegments("field.result")}}}>
                                    <Table
                                        columns={[
                                            {label: textSegments(`field.area_codes.${SegmentLinkAreaCodesField.TYPE}`), width: 30},
                                            {label: textSegments(`field.area_codes.${SegmentLinkAreaCodesField.ISO_COUNTRY}`), width: 35},
                                            {label: textSegments(`field.area_codes.${SegmentLinkAreaCodesField.CODES}`), width: 35}
                                        ]}
                                    >
                                        {links.map((link, index) =>
                                            <TableRow key={`area-code-link-${index.toString()}`}>
                                                <TableColumn>
                                                    <Tag label={link.type} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
                                                </TableColumn>
                                                <TableColumn>
                                                    <CountryFlag code={link.iso_country}/>
                                                </TableColumn>
                                                <TableColumn>
                                                    {textSegments("count_codes_import", {count: link.getCodes().length})}
                                                </TableColumn>
                                            </TableRow>
                                        )}
                                    </Table>
                                </ContentBlock>
                            }
                        </Loadable>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalImportSegmentLinksAreaCodes;
