import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {ButtonLinkCancel, ButtonValidate, Form, FormLayoutRows, FormValidationType, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, SelectAutocomplete} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {ApiService} from "../../../api/model/ApiService";
import {PortalContentField} from "../../../api/model/portal-content/PortalContentField";
import useAlert from "../../../utils/hooks/useAlert";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {PortalSettingContentField} from "../../../api/model/portal-setting/PortalSettingContentField";
import {PortalSettingContentPropertiesField} from "../../../api/model/portal-setting/PortalSettingContentPropertiesField";
import {PortalSettingSearchQuery} from "../../../api/model/portal-setting/PortalSettingSearchQuery";
import {PortalSetting} from "../../../api/model/portal-setting/PortalSetting";
import {PortalSettingContentProperties} from "../../../api/model/portal-setting/PortalSettingContentProperties";
import {PortalSettingContent} from "../../../api/model/portal-setting/PortalSettingContent";
import {DataSelection} from "../../../api/model/audience/data-selection/DataSelection";
import {DataSelectionSearchQuery} from "../../../api/model/audience/data-selection/DataSelectionSearchQuery";
import {sortItems} from "../../../common/utils/helper";

export type ModalPinDataSelectionProps = {
    active: boolean;
    onClose: () => void;
};

const ModalPinDataSelection: FunctionComponent<ModalPinDataSelectionProps> = ({active, onClose}) => {
    const alert = useAlert();
    const {t: textAudienceDataSelections} = useTranslation(TranslationPortalFile.AUDIENCE_DATA_SELECTIONS);
    const [isSubmitting, setSubmitting] = useState(false);

    const FORM_ID = "form-pin-data-selection";
    const [portalSetting, setPortalSetting] = useState<PortalSetting>(new PortalSetting());
    const [dataSelections, setDataSelections] = useState<DataSelection[]>([]);

    useEffect(() => {
        if (active) {
            (async () => {
                try {
                    const searchResult = await session.restPortalContent.search(PortalSetting, new PortalSettingSearchQuery().forApiService(ApiService.AUDIENCE));
                    if (searchResult.elements.length) {
                        setPortalSetting(searchResult.elements[0]);
                    }
                } catch (e) {
                    if (e instanceof ErrorResponse) {
                        alert.failToLoad("portal setting for Sirdata Audience", e.message);
                    }
                }

                try {
                    const searchQuery = new DataSelectionSearchQuery();
                    searchQuery.size = 200;
                    const searchResult = await session.restPortalContent.search(DataSelection, searchQuery);
                    setDataSelections(sortItems(searchResult.elements, PortalContentField.TITLE));
                } catch (e) {
                    if (e instanceof ErrorResponse) {
                        alert.failToLoad("data selections", e.message);
                    }
                }
            })();
        }
    }, [active, alert]);

    const handleChange = (field: PortalContentField, value: any) => {
        setPortalSetting((prevState) => new PortalSetting({
            ...prevState,
            [field]: value
        }));
    };

    const handleChangeContent = (field: PortalSettingContentField, value: any) => {
        handleChange(PortalContentField.CONTENT, new PortalSettingContent({
            ...portalSetting.content,
            [field]: value
        }));
    };

    const handleChangeContentProperties = (field: PortalSettingContentPropertiesField, value: any) => {
        handleChangeContent(PortalSettingContentField.PROPERTIES, new PortalSettingContentProperties({
            ...portalSetting.content.properties,
            [field]: value
        }));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            await session.restPortalContent.update(PortalSetting, portalSetting);
            alert.updateWithSuccess("pinned data selection");
            onClose();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("pinned data selection", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textAudienceDataSelections("pin_data_selection")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <SelectAutocomplete
                            value={portalSetting.content.properties.data_selection_id}
                            options={dataSelections.map((it) => ({value: it.id, label: `${it.id}. ${it.title}`}))}
                            onChange={(option) => handleChangeContentProperties(PortalSettingContentPropertiesField.DATA_SELECTION_ID, option?.value)}
                            clearable
                        />
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalPinDataSelection;
